import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getSpacesAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/closeout/space/?${param_str}`);
  return res;
};

export const postSpaceAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/closeout/space/`, data);
  return res;
};

export const putSpaceAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.put(
    `${API_PREFIX_URL}/closeout/space/${pk}/`,
    payload,
  );
  return res;
};

export const putSpaceOrderAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.put(
    `${API_PREFIX_URL}/closeout/space/order/${pk}/`,
    payload,
  );
  return res;
};

export const deleteSpaceAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/closeout/space/${pk}/`);
  return res;
};
