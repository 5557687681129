import { Route, Routes, useLocation } from "react-router-dom";
import { URLS } from "constant";
import { DetailPage, Homepage, StatusError, UI } from "pages";
import { useContext, useEffect } from "react";
import { GlobalContext } from "context";
import { RequiredAuth } from "./RequiredAuth";
import RoleManagementDetail from "pages/DetailPage/RoleManagementDetail";

const RootRoutes = () => {
  const { state } = useContext(GlobalContext);
  const { hideTooltip } = state;

  const location = useLocation();

  useEffect(() => {
    if (hideTooltip) hideTooltip();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<RequiredAuth />}>
        <Route path="/" element={<Homepage />} />
        <Route path="/role-management" element={<RoleManagementDetail />} />
        <Route path="/detail/:closeoutId" element={<DetailPage />} />
      </Route>
      <Route path="/ui" element={<UI />} />

      <Route path={`/${URLS.ERROR}`} element={<StatusError />} />
      <Route path="*" element={<StatusError statusCode={404} />} />
    </Routes>
  );
};

export default RootRoutes;
