import type { FC } from "react";
import cn from "classnames";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "primary" | "secondary" | "neutral" | "danger";
  size?: "medium" | "default" | "small" | "large";
  kind?: "default" | "icon";
  children?: any;
  className?: string;
  leadingIcon?: any;
  trailingIcon?: any;
  disabled?: boolean;
  leadingIconClass?: string;
  trailingIconClass?: string;
}

const Button: FC<IButtonProps> = ({
  variant = "primary",
  size = "default",
  type = "button",
  kind = "default",
  children,
  className,
  leadingIcon,
  trailingIcon,
  leadingIconClass,
  trailingIconClass,
  disabled = false,
  ...props
}) => {
  let LeadingIcon;
  if (leadingIcon) {
    LeadingIcon = leadingIcon;
  }
  let TrailingIcon;
  if (trailingIcon) {
    TrailingIcon = trailingIcon;
  }
  let variantClassName =
    " transition-all inline-flex items-center border-transparent justify-center";
  if (variant === "primary") {
    variantClassName =
      variantClassName + " bg-jll-text-base-default text-white rounded";
    if (!disabled) {
      variantClassName += " active:bg-opacity-90 hover:bg-opacity-95";
    }
  } else if (variant === "secondary") {
    variantClassName =
      variantClassName +
      " bg-jll-surface-base-secondary text-jll-text-base-default rounded";
    if (!disabled) {
      variantClassName +=
        " active:bg-jll-color-coldGray-1 active:bg-opacity-90 hover:bg-jll-color-coldGray-1 hover:bg-opacity-80";
    }
  } else if (variant === "neutral") {
    variantClassName = variantClassName + " text-jll-text-base-default rounded";
    if (!disabled) {
      variantClassName +=
        " hover:bg-transparent hover:text-jll-text-base-default";
    }
  } else if (variant === "danger") {
    variantClassName =
      variantClassName +
      " bg-jll-text-rag-danger-accessible text-white rounded";
  }

  if (kind === "icon") {
    variantClassName += " rounded-full !p-2 !text-jll-text-base-subdued";
  }

  if (size === "medium") {
    variantClassName = variantClassName + " px-3 py-2 text-sm leading-5";
    if (!children) {
      variantClassName += " !px-2";
    }
  } else if (size === "default") {
    variantClassName = variantClassName + " py-3 px-4 leading-5";
    if (!children && kind !== "icon") {
      variantClassName += " !px-3";
    }
  } else if (size === "small") {
    variantClassName = variantClassName + " py-1.5 px-2 text-sm leading-5";
    if (!children) {
      variantClassName += " !px-2";
    }
  }

  if (disabled) {
    variantClassName +=
      " cursor-not-allowed !bg-jll-surface-utilities-disabled !text-jll-color-text-disabled";
  }

  return (
    <button
      type={type}
      className={cn(variantClassName, className)}
      disabled={disabled}
      {...props}
    >
      {leadingIcon && (
        <LeadingIcon
          className={cn(
            "material-icons font-light",
            { "!mr-0 !-ml-0": !children },
            {
              "mr-1": size === "small",
            },
            {
              "mr-1.5 !w-5 !h-5": size === "medium",
            },
            {
              "mr-2 !w-5 !h-5": size === "default",
            },
            {
              "mr-2 !w-6 !h-6": size === "large",
            },
            { "text-jll-color-textOndark-interaction": variant === "primary" },
            leadingIconClass,
          )}
          aria-hidden="true"
        />
      )}
      {children}
      {trailingIcon && (
        <TrailingIcon
          className={cn(
            "material-icons font-light",
            { "!ml-0 !-mr-0": !children },
            { "text-jll-color-textOndark-interaction": variant === "primary" },
            {
              "ml-1": size === "small",
            },
            {
              "ml-1.5": size === "medium",
            },
            {
              "ml-2": size === "default",
            },
            trailingIconClass,
          )}
          aria-hidden="true"
        />
      )}
    </button>
  );
};

export default Button;
