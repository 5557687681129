import { StyledListValueProps } from "ui-molecules";

interface ListTableColumnProps {
  id: StyledListValueProps["valueKey"];
  label: string;
}

export const LIST_TABLE_COLUMNS: ListTableColumnProps[] = [
  { id: "client", label: "client" },
  { id: "building_address", label: "address" },
  { id: "building_city", label: "city" },
  { id: "total_rsf", label: "size (rsf)" },
  { id: "commencement_date", label: "commencement" },
  { id: "expiration_date", label: "expiration" },
  { id: "status", label: "status" },
  { id: "update_timestamp", label: "last updated" },
];

export const LIST_TABLE_COLUMNS_FOR_ADMIN: ListTableColumnProps[] = [
  { id: "dealio_id", label: "Dealio" },
  { id: "client", label: "client" },
  { id: "building_address", label: "address" },
  { id: "building_city", label: "city" },
  { id: "total_rsf", label: "size (rsf)" },
  { id: "broker", label: "Broker" },
  { id: "assignee_name", label: "Reviewer" },
  { id: "commencement_date", label: "commencement" },
  { id: "expiration_date", label: "expiration" },
  { id: "status", label: "status" },
  { id: "update_timestamp", label: "last updated" },
];
