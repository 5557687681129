const styles: any = {
  view: {
    height: "100%",
    paddingLeft: 54,
    paddingRight: 54,
  },
  title: {
    color: "#01151C",
    fontSize: 24,
    marginBottom: 38,
  },

  content: {
    view: {
      width: 340,
    },
    group: {
      view: {
        width: "100%",
        marginBottom: 16,
      },
      title: {
        color: "#01151C",
        fontSize: 12,
        lineHeight: 1.6,
        marginBottom: 10,
      },

      item: {
        view: {
          width: "50%",
          paddingRight: 16,
          marginBottom: 16,
        },

        companyName: {
          fontSize: 10,
          color: "#01151C",
          marginBottom: 4,
        },

        label: {
          fontSize: 8,
          color: "#637177",
          lineHeight: 1.5,
        },
      },
    },
  },
};

export default styles;
