import { useEffect, useRef, useState } from "react";
import ArcGISMap from "@arcgis/core/Map";
import { default as ReactMapView } from "@arcgis/core/views/MapView";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import Graphic from "@arcgis/core/Graphic";
import "@arcgis/core/assets/esri/themes/light/main.css";
import MarkerImg from "assets/images/marker.svg";
import { Loading } from "ui-molecules";

interface IMapView {
  lat?: string | number;
  lng?: string | number;
}

const MapView: React.FC<IMapView> = ({ lat, lng }) => {
  const mapRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!lat || !lng) {
      const map = new ArcGISMap({
        basemap: "streets-navigation-vector",
      });

      const view = new ReactMapView({
        map,
        container: mapRef.current,
        zoom: 13,
      });
      return;
    }
    setIsLoading(true);
    const map = new ArcGISMap({
      basemap: "streets-navigation-vector",
    });

    const view = new ReactMapView({
      map,
      container: mapRef.current,
      center: [Number(lng), Number(lat)],
      zoom: 13,
    });

    const marker = new PictureMarkerSymbol({
      url: MarkerImg,
      width: "30px",
      height: "33px",
    });

    const point: any = {
      type: "point",
      longitude: Number(lng),
      latitude: Number(lat),
    };

    const graphic = new Graphic({
      geometry: point,
      symbol: marker,
    });

    view.graphics.add(graphic);

    view.when(() => {
      // Map is loaded, set loading to false
      setIsLoading(false);
    });

    return () => {
      view.destroy();
    };
  }, [lat, lng]);

  return (
    <>
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
        </div>
      )}
      <div className="w-full h-full" ref={mapRef}></div>
    </>
  );
};

export default MapView;
