import { View, Text } from "@react-pdf/renderer";
import PageLayoutV2 from "../PageLayoutV2";
import styles from "./styles";

interface IPageOverviewDetailV2 {
  pageNumber: number;
  detail?: any;
  overview?: any;
}

const PageOverviewDetailV2: React.FC<IPageOverviewDetailV2> = ({
  pageNumber,
  detail,
  overview,
}) => {
  return (
    <PageLayoutV2 pageNumber={pageNumber} detail={detail}>
      <View style={styles.view}>
        <Text style={styles.title}>Lease Overview</Text>

        {overview?.map((item: any, idx: number) => (
          <View
            key={idx}
            style={{
              ...styles.detail.item.view,
              backgroundColor: idx % 2 === 0 ? "#F6F9FA" : "white",
            }}
          >
            <Text style={{ ...styles.detail.item.value, width: 175 }}>
              {item?.label || ""}
            </Text>
            <Text style={{ ...styles.detail.item.value, flex: 1 }}>
              {item?.value || ""}
            </Text>
          </View>
        ))}
      </View>
    </PageLayoutV2>
  );
};

export default PageOverviewDetailV2;
