import { getDecimalFormating, getFormatedDate } from "utils";

export interface StyledRentValueProps {
  rent: any;
  valueKey: string;
}

const StyledRentValue: React.FC<StyledRentValueProps> = ({
  rent,
  valueKey,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "Period Start":
          case "Period End":
            if (!rent?.[valueKey]) return <></>;
            return <>{getFormatedDate(rent?.[valueKey])}</>;

          case "Rent PSF":
          case "Monthly Cost":
          case "Annual Cost":
            if (!rent?.[valueKey]) return <></>;
            return (
              <p>${getDecimalFormating(Number(rent?.[valueKey]), true)}</p>
            );

          default:
            if (rent?.hasOwnProperty(valueKey)) {
              return <>{rent?.[valueKey] || ""}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledRentValue;
