import { Close } from "@mui/icons-material";
import { SET_DETAIL_INFO, SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { patchGroupAPI } from "services";
import { Button, Input } from "ui-atoms";
import { Modal } from "ui-molecules";
import * as Yup from "yup";

interface IEditGroupModal {
  isOpen: boolean;
  setIsOpen: any;
  groups?: any;
  selectedGroup?: any;
}

const INIT_GROUP = {
  name: "", // Group Name
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
});

const EditGroupModal: React.FC<IEditGroupModal> = ({
  isOpen,
  setIsOpen,
  selectedGroup,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { detailInfo } = state;
  const { closeoutId } = useParams();
  const [patchGroup] = useApiCall(patchGroupAPI);

  useEffect(() => {
    if (!selectedGroup) {
      setFieldValue("name", "");
      return;
    }
    setFieldValue("name", selectedGroup?.name);
  }, [selectedGroup]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: INIT_GROUP,
    validationSchema,
    onSubmit: async () => {
      try {
        if (!selectedGroup) return;
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Submitting",
          },
        });

        let payload = {
          ...values,
          lease_closeout: closeoutId,
        };

        patchGroup({ id: selectedGroup?.id, payload })
          .then((res: any) => {
            if (!res) return;
            let groups = [...detailInfo?.groups];
            const index = [...groups].findIndex(
              (group) => group.id === res?.id,
            );
            if (index < 0) return;
            groups[index] = {
              ...groups[index],
              ...res,
            };
            dispatch({
              type: SET_DETAIL_INFO,
              payload: {
                ...detailInfo,
                groups,
              },
            });
          })
          .finally(() => {
            dispatch({
              type: SET_FULL_LOADING,
              payload: {
                open: false,
                label: "",
              },
            });
            setIsOpen(false);
          });
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      }
    },
  });

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            Edit Group Contact
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mt-6">
            <Input
              name="name"
              label="Group Contact Name"
              className="mb-2 w-full"
              onChange={handleChange}
              error={touched.name ? errors.name : ""}
              onBlur={handleBlur}
              value={values?.name}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex flex-row space-x-3 w-full justify-end">
            <Button
              variant="secondary"
              className="w-32"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="w-32"
              type="submit"
              disabled={!isValid || !dirty}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditGroupModal;
