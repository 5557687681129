export interface APIProps {
  pk: any;
  facets?: string[];
  page?: number;
  limit?: number;
  keyword?: string;
  user?: number;
  group?: number;
  uuid?: string;
}

export interface DirectionProps {
  facets?: string[];
  page?: number;
  limit?: number;
  keyword?: string;
}

export enum DirectionEnum {
  asc = "asc",
  desc = "desc",
}
