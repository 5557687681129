import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getDocumentsAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(
    `${API_PREFIX_URL}/closeout/documents/?${param_str}`,
  );
  return res;
};

export const getDocumentAPI = async (id: number) => {
  const res = await axios.get(`${API_PREFIX_URL}/closeout/documents/${id}/`, {
    responseType: "blob",
  });
  return res;
};

export const postDocumentAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/closeout/documents/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const deleteDocumentAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/closeout/documents/${pk}/`);
  return res;
};

export const getDocFileAPI = async ({ id, props }: any) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(
    `${API_PREFIX_URL}/closeout/files/${id}/?${param_str}`,
    {
      responseType: "blob",
    },
  );
  return res;
};
