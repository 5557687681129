import {
  AddOutlined,
  DeleteOutlineOutlined,
  DragIndicatorOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button } from "ui-atoms";
import { reorder } from "utils";
import ContactModal from "./ContactModal";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import {
  deleteContactAPI,
  putContactOrderAPI,
  putGroupOrderAPI,
} from "services";
import { useParams } from "react-router-dom";
import EditGroupModal from "./EditGroupModal";
import { SET_DETAIL_INFO, SET_FULL_LOADING } from "constant";
import { Table } from "ui-molecules";

const AdditionalContact = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { detailInfo } = state;
  const { closeoutId } = useParams();
  const [putGroupOrder] = useApiCall(putGroupOrderAPI);
  const [putContactOrder] = useApiCall(putContactOrderAPI);
  const [deleteContact] = useApiCall(deleteContactAPI);
  const [data, setData] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditGroupOpen, setIsEditGroupOpen] = useState(false);
  const [selectedGroup, setSeletedGroup] = useState(null);
  const [selectedContact, setSeletedContact] = useState(null);

  useEffect(() => {
    if (!detailInfo) {
      setData([]);
      return;
    }
    let new_data: any = [];
    detailInfo?.groups?.forEach((group: any) => {
      let contacts: any = [];
      detailInfo?.contacts?.forEach((contact: any) => {
        if (contact?.lease_closeout_contact_group?.id === group?.id) {
          contacts.push(contact);
        }
      });
      if (!!contacts?.length)
        new_data.push({
          ...group,
          contacts,
        });
    });
    setData(new_data);
  }, [detailInfo]);

  useEffect(() => {
    if (!isEditGroupOpen) {
      setSeletedGroup(null);
    }
  }, [isEditGroupOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSeletedContact(null);
    }
  }, [isOpen]);

  const onDragGroupEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    try {
      const new_data = [...data];
      const newOrder = reorder(
        [...data],
        result?.source.index,
        result?.destination.index,
      );
      setData(newOrder);
      const sort = newOrder.map((order) => order?.id);
      putGroupOrder({ pk: closeoutId, sort }).catch((err: any) => {
        setData(new_data);
      });
    } catch (err) {}
  };

  const onDragContactEnd = (result: any, groupId: number) => {
    if (!result.destination) {
      return;
    }
    try {
      const back_data = [...data];
      let new_data = [...data];
      const index = [...data].findIndex((item) => item?.id === groupId);
      const newOrder = reorder(
        [...new_data[index]?.contacts],
        result?.source.index,
        result?.destination.index,
      );
      new_data[index].contacts = newOrder;
      setData(new_data);
      const sort = newOrder.map((order) => order?.id);
      putContactOrder({ pk: groupId, sort }).catch((err: any) => {
        setData(back_data);
      });
    } catch (err) {}
  };

  const handleRemoveContact = (id: number) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Deleting...",
        },
      });
      deleteContact(id)
        .then((res: any) => {
          let contacts = [...detailInfo?.contacts];
          const idx = contacts?.findIndex((contact: any) => contact?.id === id);
          contacts.splice(idx, 1);
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              contacts,
            },
          });
        })
        .finally(() => {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  return (
    <div className="my-8 flex flex-col">
      <div className="mb-8 flex flex-row items-center justify-between w-full">
        <span className="text-jll-text-base-subdued">
          Add any additional contacts, i.e. landlord, management
        </span>
        <Button
          variant="secondary"
          size="medium"
          leadingIcon={AddOutlined}
          onClick={() => setIsOpen(true)}
        >
          Add Contact
        </Button>
      </div>
      {!data?.length && <Table.Loading size={4} />}
      {!!data?.length && (
        <div className="border border-jll-stroke-subdued">
          {/* Header */}
          <div className="flex flex-row items-center text-sm text-jll-text-base-default uppercase bg-jll-surface-base-secondary-subdued py-4 pl-[96px] pr-[108px]">
            <span className="w-4/12">Name</span>
            <span className="w-4/12">Company name</span>
            <span className="w-4/12">Email</span>
            <span className="w-4/12">Phone</span>
          </div>

          {/* Body */}
          <DragDropContext onDragEnd={onDragGroupEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided?.innerRef}
                  className="flex flex-col"
                >
                  {data?.map((group: any, idx: number) =>
                    group?.contacts && group?.contacts?.length ? (
                      <Draggable
                        key={idx}
                        draggableId={`group-${idx}`}
                        index={idx}
                      >
                        {(provided, snapshot) => (
                          <>
                            <div
                              className="flex flex-row bg-white px-8 py-4 items-center border-b border-b-jll-stroke-subdued justify-between"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className="flex flex-row items-center">
                                <div
                                  {...provided.dragHandleProps}
                                  className="mr-4"
                                >
                                  <DragIndicatorOutlined className="text-jll-color-textOndark-subdued" />
                                </div>
                                <p>{group.name}</p>
                              </div>
                              <div>
                                <Button
                                  variant="neutral"
                                  leadingIcon={EditOutlined}
                                  size="medium"
                                  leadingIconClass="text-jll-text-base-subdued"
                                  onClick={() => {
                                    setIsEditGroupOpen(true);
                                    setSeletedGroup(group);
                                  }}
                                />
                              </div>
                            </div>
                            <DragDropContext
                              onDragEnd={(result: any) =>
                                onDragContactEnd(result, group?.id)
                              }
                            >
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided?.innerRef}
                                    className="flex flex-col"
                                  >
                                    {group?.contacts?.map(
                                      (contact: any, idx1: number) => (
                                        <Draggable
                                          key={idx1}
                                          draggableId={`contact-${idx}-${idx1}`}
                                          index={idx1}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              className="flex flex-row bg-white pl-14 pr-8 py-4 items-center border-b border-b-jll-stroke-subdued"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                            >
                                              <div
                                                {...provided.dragHandleProps}
                                                className="mr-4"
                                              >
                                                <DragIndicatorOutlined className="text-jll-color-textOndark-subdued" />
                                              </div>
                                              <div className="flex w-full">
                                                <span className="w-4/12">
                                                  {contact?.contact_name || ""}
                                                </span>
                                                <span className="w-4/12">
                                                  {contact?.company_name || ""}
                                                </span>
                                                <span className="w-4/12">
                                                  {contact?.email || ""}
                                                </span>
                                                <span className="w-4/12">
                                                  {contact?.phone || ""}
                                                </span>
                                              </div>
                                              <div className="w-[76px] flex flex-row justify-end">
                                                <Button
                                                  variant="neutral"
                                                  leadingIcon={EditOutlined}
                                                  size="medium"
                                                  leadingIconClass="text-jll-text-base-subdued"
                                                  onClick={() => {
                                                    setIsOpen(true);
                                                    setSeletedContact(contact);
                                                  }}
                                                />
                                                <Button
                                                  variant="neutral"
                                                  leadingIcon={
                                                    DeleteOutlineOutlined
                                                  }
                                                  size="medium"
                                                  leadingIconClass="text-jll-text-base-subdued"
                                                  onClick={() =>
                                                    handleRemoveContact(
                                                      contact?.id,
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ),
                                    )}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </>
                        )}
                      </Draggable>
                    ) : null,
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}

      <ContactModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        groups={detailInfo?.groups}
        selectedContact={selectedContact}
      />
      <EditGroupModal
        isOpen={isEditGroupOpen}
        setIsOpen={setIsEditGroupOpen}
        selectedGroup={selectedGroup}
      />
    </div>
  );
};

export default AdditionalContact;
