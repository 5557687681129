import { toast } from "react-toastify";
import "./toast.css";
import {
  CheckCircleOutline,
  ErrorOutlineOutlined,
  ReportProblemOutlined,
} from "@mui/icons-material";

const createToast = (message: string, type: string) => {
  let title = "";
  let Icon = CheckCircleOutline;
  let iconClassName = "!w-8 !h-8";
  let classes = "";
  if (type === "success") {
    title = "Success";
    Icon = CheckCircleOutline;
    iconClassName += " text-jll-color-text-successAccessible";
    classes =
      "!bg-jll-color-surface-successSubdued before:bg-jll-color-text-successAccessible";
  } else if (type === "warn") {
    title = "Warning";
    Icon = ReportProblemOutlined;
    iconClassName += " text-jll-color-surface-warning-default";
    classes =
      "!bg-jll-color-surface-warning-subdued before:bg-jll-color-surface-warning-default";
  } else if (type === "error") {
    title = "Error";
    Icon = ErrorOutlineOutlined;
    iconClassName += " text-jll-color-surface-danger-default";
    classes =
      "!bg-jll-color-surface-danger-subdued before:bg-jll-color-surface-danger-default";
  }
  const htmlMsg = (
    <div className="flex flex-col">
      <p className="font-semibold text-jll-color-text-base-default">{title}</p>
      <p className="text-jll-color-coldGray-7 text-sm">{message}</p>
    </div>
  );
  const closeHtml = (
    <div className="flex justify-center items-center border-l border-black border-opacity-5">
      <span className="font-semibold text-sm text-jll-color-text-base-default px-4">
        Close
      </span>
    </div>
  );

  toast(htmlMsg, {
    icon: <Icon className={iconClassName} />,
    closeButton: closeHtml,
    className: `shadow-toast rounded !py-0 relative before:absolute before:content-[""] before:w-[6px] before:top-0  before:left-0 ${classes}`,
    position: toast.POSITION.TOP_RIGHT,
  });
};

const Toast = (message: string) => {
  createToast(message, "error");
};

Toast.success = (message: string) => createToast(message, "success");
Toast.warn = (message: string) => createToast(message, "warn");
Toast.error = (message: string) => createToast(message, "error");

export default Toast;
