import React, {
  useEffect,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
  useContext,
} from "react";
import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { GlobalContext } from "context";

const DynamicGallery = forwardRef(({}, ref) => {
  const lightGallery: React.MutableRefObject<any> = useRef(null);
  const { state } = useContext(GlobalContext);
  const { lightGalleryData } = state;

  useEffect(() => {
    if (lightGalleryData?.length > 0)
      lightGallery.current.refresh(lightGalleryData);
  }, [lightGalleryData]);

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  useImperativeHandle(ref, () => ({
    openGallery(num: number) {
      lightGallery.current.openGallery(num);
    },
    closeGallery() {
      lightGallery.current.closeGallery();
    },
  }));

  return (
    <LightGallery
      onInit={onInit}
      plugins={[lgThumbnail, lgZoom]} //lgFullScreen, lgRelativeCaption, lgShare
      mode="lg-fade"
      speed={100}
      dynamic
      dynamicEl={lightGalleryData}
      licenseKey={process.env.REACT_APP_LIGHT_GALLERY_LICENSE_KEY || ""}
      closeOnTap={false}
      mobileSettings={{
        showCloseIcon: true,
        download: true,
      }}
    />
  );
});

export default DynamicGallery;
