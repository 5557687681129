import {
  AdminPanelSettingsOutlined,
  GroupAddOutlined,
  ImportExportOutlined,
  ListAltOutlined,
} from "@mui/icons-material";

export const DETAIL_MENU_LIST = [
  {
    label: "Admin",
    value: "admin",
    icon: AdminPanelSettingsOutlined,
    sub_menu: [
      {
        label: "Settings",
        value: "settings",
      },
    ],
  },
  {
    label: "Lease Details",
    value: "lease_details",
    icon: ListAltOutlined,
    sub_menu: [
      {
        label: "Property",
        value: "property",
      },
      {
        label: "Spaces",
        value: "space",
      },
      {
        label: "Key Dates & Options",
        value: "key_dates",
      },
      {
        label: "Rent & Expenses",
        value: "rent_expenses",
      },
      {
        label: "Contacts",
        value: "contacts",
      },
      {
        label: "Additional Info",
        value: "additional_info",
      },
      // {
      //   label: "Documents",
      //   value: "additional_documents",
      // },
    ],
  },
  {
    label: "Page Reorder",
    icon: ImportExportOutlined,
    value: "page_reorder",
    isRoot: true,
  },
  {
    label: "Collaborators",
    value: "collaborators",
    icon: GroupAddOutlined,
    isRoot: true,
  },
];
