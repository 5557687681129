const styles: any = {
  view: {
    height: "100%",
    width: "100%",
    paddingLeft: 54,
    paddingRight: 54,
  },
  title: {
    color: "#01151C",
    fontSize: 24,
    marginBottom: 24,
  },

  section: {
    view: {
      paddingBottom: 44,
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    left: {
      view: {
        height: "100%",
        flex: 1,
        paddingRight: 16,
        display: "flex",
        flexDirection: "column",
      },
      image: {
        objectFit: "cover",
        width: "100%",
        paddingBottom: 16,
        flexGrow: 1,
      },
      building: {
        view: {
          backgroundColor: "#163D4F",
          width: "100%",
          paddingLeft: 28,
          paddingRight: 28,
          paddingTop: 20,
          paddingBottom: 20,
          display: "flex",
          flexDirection: "column",
        },
        label: {
          fontSize: 16,
          color: "white",
          lineHeight: 1.2,
        },
      },
    },

    right: {
      view: {
        width: 200,
        height: "100%",
      },
      info: {
        view: {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#F6F9FA",
          color: "#01151C",
          marginBottom: 16,
          paddingLeft: 28,
          paddingRight: 28,
        },
        item: {
          value: {
            fontSize: 16,
            lineHeight: 1.5,
          },
          label: {
            fontSize: 10,
          },
        },
      },
      image: {
        width: 200,
        minHeight: 200,
        objectFit: "cover",
      },
    },
  },

  footer: {
    view: {
      minHeight: 140,
      marginLeft: -54,
      marginRight: -54,
      backgroundColor: "#F6F9FA",
      paddingTop: 39,
      paddingLeft: 54,
      paddingRight: 54,
    },
    date: {
      view: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 22,
      },
      item: {
        view: {
          minWidth: 90,
          color: "#01151C",
        },
        date: {
          fontSize: 16,
          lineHeight: 1.75,
        },
        label: {
          fontSize: 10,
        },
      },
    },
    progress: {
      view: {
        display: "flex",
        flexDirection: "row",
        height: 3,
        width: "100%",
      },
    },
  },

  detail: {
    item: {
      view: {
        paddingTop: 8,
        paddingBottom: 8,
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        width: "100%",
      },
      value: {
        color: "#01151C",
        fontSize: 10,
        lineHeight: 1.4,
        paddingLeft: 10,
        paddingRight: 10,
        display: "flex",
        flexWrap: "wrap",
      },
    },
  },
};

export default styles;
