import type { FC } from "react";
import cn from "classnames";

export interface IChipProps extends React.HTMLAttributes<HTMLSpanElement> {
  variant: "primary" | "secondary" | "alert";
  size?: "small" | "default" | "icon";
  children?: any;
  className?: string;
}

const Chip: FC<IChipProps> = ({
  variant,
  children,
  className,
  size = "default",
  ...props
}) => {
  let variantClassName =
    "inline-flex items-center rounded-full font-medium capitalize";
  if (variant === "primary") {
    variantClassName =
      variantClassName +
      " bg-jll-color-surface-successSubdued text-jll-color-text-successAccessible ";
  } else if (variant === "secondary") {
    variantClassName =
      variantClassName +
      " bg-jll-surface-base-secondary text-jll-text-base-default";
  } else if (variant === "alert") {
    variantClassName =
      variantClassName +
      " bg-jll-color-surface-interactionSubdued text-jll-color-text-interaction";
  }

  if (size === "small") {
    variantClassName = variantClassName + " px-2.5 py-0.5 text-xs";
  } else if (size === "default") {
    variantClassName = variantClassName + " px-3 py-2 text-sm";
  } else if (size === "icon") {
    variantClassName = variantClassName + " px-2 py-2 text-base";
  }

  return (
    <div className={cn(variantClassName, className)} {...props}>
      {children}
    </div>
  );
};

export default Chip;
