import { View, Text } from "@react-pdf/renderer";
import styles from "./styles";
import { getFormatedDate, getUTCDate } from "utils";
import PageLayoutV2 from "../PageLayoutV2";

interface IPageKeyLeaseV2 {
  detail?: any;
  keyData?: any;
  pageNumber?: number;
  id?: string;
}

const HeaderView = ({ detail }: any) => {
  return (
    <View style={styles.header.view}>
      <View style={styles.header.progress.view}>
        <View
          style={{
            width: `30%`,
            backgroundColor: "#9FC5D3",
          }}
        />
        <View
          style={{
            width: `70%`,
            backgroundColor: "#4D788B",
          }}
        />
      </View>
      <View style={styles.header.date.view}>
        {!!detail?.execution_date && (
          <View
            style={{
              ...styles.header.date.item.view,
              width: `30%`,
            }}
          >
            <Text style={styles.header.date.item.date}>
              {getFormatedDate(detail?.execution_date)}
            </Text>
            <Text style={styles.header.date.item.label}>Execution</Text>
          </View>
        )}
        {!!detail?.commencement_date && (
          <View
            style={{
              ...styles.header.date.item.view,
              width: `30%`,
            }}
          >
            <Text style={styles.header.date.item.date}>
              {getFormatedDate(detail?.commencement_date)}
            </Text>
            <Text style={styles.header.date.item.label}>Commencement</Text>
          </View>
        )}
        {!!detail?.expiration_date && (
          <View
            style={{
              ...styles.header.date.item.view,
              width: "40%",
              textAlign: "right",
            }}
          >
            <Text style={styles.header.date.item.date}>
              {getFormatedDate(detail?.expiration_date)}
            </Text>
            <Text style={styles.header.date.item.label}>Expiration</Text>
          </View>
        )}
      </View>
    </View>
  );
};

const PageKeyLeaseV2: React.FC<IPageKeyLeaseV2> = ({
  detail,
  keyData,
  pageNumber,
  id,
}) => {
  return (
    <PageLayoutV2
      pageNumber={pageNumber}
      id={id}
      detail={detail}
      headerStyle={{
        backgroundColor: "#F6F9FA",
      }}
    >
      <View style={styles.view}>
        <Text style={{ ...styles.title }}>Key Dates & Options</Text>
        {(!!detail?.expiration_date ||
          !!detail?.commencement_date ||
          !!detail?.execution_date) && <HeaderView detail={detail} />}

        <View style={{ display: "flex", flexDirection: "column", height: 450 }}>
          {keyData?.map((item: any, idx: number) => (
            <View style={styles.section.view} key={idx}>
              <View style={styles.section.description.view}>
                <Text style={styles.section.description.label}>
                  {item?.type || item?.custom_type}
                </Text>
                {item?.value_min && item?.value_max ? (
                  <Text style={styles.section.date}>{`${getFormatedDate(
                    item?.value_min,
                  )} - ${getFormatedDate(item?.value_max)}`}</Text>
                ) : (
                  <Text style={styles.section.date}>{`${getFormatedDate(
                    item?.value_min,
                  )}`}</Text>
                )}
              </View>

              <Text style={styles.section.description.value}>
                {item?.description}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </PageLayoutV2>
  );
};

export default PageKeyLeaseV2;
