import cn from "classnames";

interface IOptionItem {
  label: string;
  value: string;
  isSelected?: boolean;
  onSelect?: any;
}

const OptionItem: React.FC<IOptionItem> = ({
  label,
  value,
  isSelected,
  onSelect,
}) => {
  return (
    <span
      className={cn(
        "px-3 py-2 cursor-pointer text-jll-text-base-default rounded-full flex justify-center items-center",
        {
          "border border-jll-stroke-default bg-white": !isSelected,
          "bg-jll-surface-base-secondary": isSelected,
        },
      )}
      onClick={() => onSelect(value)}
    >
      {label}
    </span>
  );
};

export default OptionItem;
