import { Close, EditOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { DEFAULT_LEASE_OVERVIEW_FIELDS, SEARCH_RESULT_LIMIT } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { DeliverablePDF } from "pages";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getContactUsersAPI,
  getDetailAPI,
  getKeyDatesAPI,
  getMapAPI,
} from "services";
import { Button } from "ui-atoms";
import { Loading, Modal } from "ui-molecules";
import {
  getDetailValue,
  groupBy,
  sliceIntoChunks,
  transformObject,
} from "utils";
import cn from "classnames";

interface IContactPageEntry {
  name: string;
  contacts?: any[]; // Use a more specific type for contacts if possible
}

const PreviewPDFModal = () => {
  const { state } = useContext(GlobalContext);
  const { previewPDF, detailInfo, meta } = state;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getDetail] = useApiCall(getDetailAPI);
  const [getKeyDates] = useApiCall(getKeyDatesAPI);
  const [getContactUsers] = useApiCall(getContactUsersAPI);
  const [getMap] = useApiCall(getMapAPI);

  const [pdfTemplate, setPdfTemplate] = useState<any>(null);
  const [contactsPage, setContactsPage] = useState<any>(null);
  const [overviewPage, setOverviewPage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [rentData, setRentData] = useState<any>(null);
  const [keyData, setKeyData] = useState<any>(null);
  const [keyDataPerPage, setKeyDataPerPage] = useState<any>([]);
  const [userData, setUserData] = useState(null);
  const [mapData, setMapData] = useState(null);

  useEffect(() => {
    if (!detailInfo?.id || !previewPDF?.open) {
      return;
    }
    setIsLoading(true);
    getDetail(detailInfo?.id)?.then((res: any) => {
      if (!res) {
        setData(null);
        return;
      }
      setData(res);
      if (res?.rent)
        setRentData({
          ...res?.rent,
          grid: transformObject(res?.rent?.grid),
        });
      else setRentData({});
    });
    getKeyDates({
      lease_closeout: detailInfo?.id,
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [1],
    }).then((res: any) => {
      if (!res) return;
      setKeyData(res?.docs);
    });

    getContactUsers({
      lease_closeout: [detailInfo?.id],
      page: 1,
      active_status: [1],
      limit: SEARCH_RESULT_LIMIT,
    }).then((res: any) => {
      if (!res) return;
      setUserData(res?.docs);
    });

    getMap({
      id: detailInfo?.id,
      zoom: 13,
      width: 350,
      height: 350,
    }).then((res: any) => {
      if (!res) return;
      setMapData(res);
    });
  }, [detailInfo, previewPDF]);

  useEffect(() => {
    if (!data || !rentData || !keyData || !userData || !mapData) return;
    const new_contacts: any = data?.groups
      ?.map((group: any) => {
        const contacts: any =
          groupBy(
            data?.contacts?.filter(
              (contact: any) =>
                contact?.lease_closeout_contact_group?.id === group?.id,
            ),
            "company_name",
          ) || [];

        return { ...group, contacts };
      })
      ?.filter(
        (item: any) =>
          !!item?.contacts && !!Object?.keys(item?.contacts)?.length,
      )
      ?.map((group: any) => {
        let row_contacts: any = [];
        const company_list = Object.keys(group?.contacts);
        let index = 0;
        for (; index < company_list?.length; ) {
          if (
            group?.contacts?.[company_list?.[index]]?.length === 1 &&
            !!company_list?.[index + 1] &&
            group?.contacts?.[company_list?.[index + 1]]?.length === 1
          ) {
            row_contacts.push({
              company1: company_list?.[index],
              company2: company_list?.[index + 1],
              rows: [
                ...group?.contacts?.[company_list?.[index]],
                ...group?.contacts?.[company_list?.[index + 1]],
              ],
            });
            index += 2;
          } else {
            const chunks = sliceIntoChunks(
              group?.contacts?.[company_list?.[index]],
              2,
            );
            chunks?.forEach((chunk: any, idx1: number) => {
              row_contacts.push({
                company1: idx1 === 0 ? company_list?.[index] : null,
                company2: null,
                rows: [...chunk],
              });
            });
            index++;
          }
        }

        return { ...group, contacts: row_contacts };
      });

    const totalHeight = 550;
    const titleHeight = 30; // 20 + 10
    const companyHeight = 20; // 16 + 6
    const contactHeight = 12;
    const groupBottomH = 16;
    const sectionBottomH = 16;

    let currentHeight = 0;
    let contactsPerPage: any = [];
    let currentContactPage: any = {};

    [...(new_contacts || [])]?.forEach((group: any, idx: number) => {
      if (
        currentHeight +
          titleHeight +
          companyHeight +
          contactHeight * 3 +
          sectionBottomH >
        totalHeight
      ) {
        currentHeight = 0;
        contactsPerPage.push(currentContactPage);
        currentContactPage = {};
      }
      currentHeight += titleHeight;

      group?.contacts?.forEach((contact: any) => {
        let contactH = !!contact?.company1
          ? contactHeight * 3 + companyHeight
          : contactHeight * 3;
        contactH += sectionBottomH;
        if (currentHeight + contactH > totalHeight) {
          contactsPerPage.push(currentContactPage);
          currentContactPage = {};
          currentContactPage[group?.id] = { contacts: [contact] };
          currentHeight = contactH;
        } else {
          if (!!currentContactPage?.[group?.id]) {
            let current_contacts = currentContactPage?.[group?.id]?.contacts;
            current_contacts.push(contact);
            currentContactPage[group?.id] = {
              ...group,
              contacts: current_contacts,
            };
          } else {
            currentContactPage[group?.id] = { ...group, contacts: [contact] };
          }
          currentHeight += contactH;
        }
      });

      currentHeight += groupBottomH;
    });

    if (!!currentContactPage && !!Object.keys(currentContactPage)?.length)
      contactsPerPage.push(currentContactPage);

    const keyTittleHeight = 24;
    const keyDesLineHeight = 14;
    const keyTotalHeight = 415;
    let keyHeight = 0;
    let keyPerPage: any = [];

    let default_data = [];

    if (data?.commencement_date_description) {
      default_data.push({
        type: "Commencement",
        description: data?.commencement_date_description || "",
        value_min: data?.commencement_date,
      });
    }
    if (data?.expiration_date_description) {
      default_data.push({
        type: "Expiration",
        description: data?.expiration_date_description || "",
        value_min: data?.expiration_date,
      });
    }
    if (data?.execution_date_description) {
      default_data.push({
        type: "Execution Date",
        description: data?.execution_date_description || "",
        value_min: data?.execution_date,
      });
    }

    [...default_data, ...keyData]?.forEach((item: any, idx: number) => {
      if (idx === 0) {
        keyPerPage.push([]);
        keyHeight = 0;
      }
      let sectionH = keyTittleHeight + 11;

      item?.description?.split(/\r*\n/)?.forEach((str: string) => {
        sectionH += Math.ceil((str?.length || 0) / 90) * keyDesLineHeight;
      });

      sectionH += 20;

      if (keyHeight < keyTotalHeight - sectionH) {
        keyPerPage[keyPerPage?.length - 1].push(item);
        keyHeight += sectionH;
      } else {
        keyHeight = sectionH;
        keyPerPage.push([]);
        keyPerPage?.[keyPerPage?.length - 1].push(item);
      }
    });

    const totalOverviewHeight = 560;
    const lineHeight = 14;
    const paddingHeight = 16;

    let totalOverviewData: any = [];
    let overviewPerPage: any = [];
    DEFAULT_LEASE_OVERVIEW_FIELDS.forEach(({ label, id }: any) => {
      const value = getDetailValue(data, id, meta);
      if (!value?.length) return;
      totalOverviewData.push({
        label,
        value: getDetailValue(data, id, meta),
      });
    });
    if (!!data?.additional?.length)
      data?.additional?.forEach((item: any) => {
        totalOverviewData.push({ label: item?.name, value: item?.description });
      });

    let currentOverviewHeight = 0;
    let currentPage: any = [];
    totalOverviewData.forEach((item: any, idx: number) => {
      let tempH = 0;
      let tempDescription = "";
      let lineDescription = "";
      tempH += paddingHeight;

      item?.value?.split(/\r*\n/)?.forEach((str: string, idx1: number) => {
        if (idx1 > 0) tempDescription += "\n";
        const words = str.split(" ");
        words?.forEach((word) => {
          if (lineDescription?.length + word?.length <= 75) {
            tempDescription += `${word} `;
            lineDescription += `${word} `;
          } else {
            if (
              currentOverviewHeight + tempH + lineHeight >
              totalOverviewHeight
            ) {
              overviewPerPage.push([
                ...currentPage,
                { label: item.label, value: tempDescription },
              ]);
              tempDescription = `${word} `;
              lineDescription = `${word} `;
              currentPage = [];
              currentOverviewHeight = 0;
              tempH = paddingHeight;
            } else {
              tempDescription += `${word} `;
              lineDescription = `${word} `;
              tempH += lineHeight;
            }
          }
        });
        tempH += lineHeight;
      });
      if (currentOverviewHeight + tempH > totalOverviewHeight) {
        currentOverviewHeight = 0;
        overviewPerPage.push(currentPage);
        currentPage = [];
      } else {
        currentPage.push({ label: item?.label, value: `${tempDescription}` });
        tempDescription = "";
        currentOverviewHeight += tempH;
      }
    });
    if (!!currentPage?.length) overviewPerPage.push(currentPage);

    setOverviewPage(overviewPerPage);
    setContactsPage(contactsPerPage);
    setKeyDataPerPage(keyPerPage);

    const layout: any = DeliverablePDF({
      detail: data,
      contactsPerPage,
      rentData,
      meta,
      keyDataPerPage: keyPerPage,
      userData,
      overviewPage: overviewPerPage,
      mapData,
    });
    setPdfTemplate(layout);
  }, [data, rentData, keyData, userData, mapData]);

  const handleClose = () => {
    if (searchParams.has("preview")) {
      searchParams.delete("preview");
      setSearchParams(searchParams);
    }
  };
  return (
    <Modal
      isOpen={previewPDF?.open || false}
      setIsOpen={handleClose}
      size="extra"
    >
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            {`Lease Overview - ${data?.client || ""} - ${
              data?.building_name || data?.building_address || ""
            }`}
          </h3>
          <div className="flex flex-row items-center space-x-4">
            <Button
              variant="secondary"
              size="medium"
              leadingIcon={EditOutlined}
              onClick={() => {
                handleClose();
                navigate(`/detail/${detailInfo?.id}?tab=page_reorder`);
              }}
            >
              Edit
            </Button>
            {pdfTemplate && (
              <PDFDownloadLink
                document={pdfTemplate}
                fileName={`lease_overview_${detailInfo?.client || ""}_${detailInfo?.building_name || ""}.pdf`}
              >
                {/* @ts-ignore */}
                {({ blob, url, loading, error }) => {
                  setIsLoading(loading);
                  return (
                    <Button
                      variant="primary"
                      size="medium"
                      leadingIcon={FileDownloadOutlined}
                      disabled={isLoading}
                    >
                      Download
                    </Button>
                  );
                }}
              </PDFDownloadLink>
            )}
            <Button
              variant="neutral"
              onClick={handleClose}
              leadingIcon={Close}
              size="large"
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-jll-color-strokeOndark-default h-full !max-h-full mt-4 -mb-6 !px-0 !h-[calc(100%-28px)]">
        <div
          className={cn("flex w-full justify-center h-full items-center", {
            hidden: !isLoading,
          })}
        >
          <Loading />
        </div>
        <div
          className={cn("flex w-full justify-center h-full", {
            "invisible !h-0": isLoading,
          })}
        >
          {data && contactsPage && (
            <PDFViewer
              className="w-full h-full bg-jll-color-strokeOndark-default"
              style={{ backgroundColor: "#4B5B61" }}
              showToolbar={false}
            >
              <DeliverablePDF
                detail={data}
                contactsPerPage={contactsPage}
                rentData={rentData}
                meta={meta}
                keyDataPerPage={keyDataPerPage}
                userData={userData}
                overviewPage={overviewPage}
                mapData={mapData}
              />
            </PDFViewer>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PreviewPDFModal;
