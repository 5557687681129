import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getAdditionalInfoAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(
    `${API_PREFIX_URL}/closeout/additional?${param_str}`,
  );
  return res;
};

export const patchAdditionalInfoAPI = async ({ id, payload }: any) => {
  const res = await axios.patch(
    `${API_PREFIX_URL}/closeout/additional/${id}/`,
    payload,
  );
  return res;
};

export const postAdditionalInfoAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/closeout/additional/`, data);
  return res;
};

export const deleteAdditionalInfoAPI = async (pk: number) => {
  const res = await axios.delete(
    `${API_PREFIX_URL}/closeout/additional/${pk}/`,
  );
  return res;
};

export const putAdditionalInfoOrderAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.put(
    `${API_PREFIX_URL}/closeout/additional/order/${pk}/`,
    payload,
  );
  return res;
};
