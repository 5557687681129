import type { FC } from "react";
import cn from "classnames";

interface ILabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: any;
  className?: string;
  optional?: boolean;
}

const Label: FC<ILabelProps> = ({
  children,
  className,
  optional,
  ...props
}) => {
  return (
    <label
      className={cn(
        "flex flex-wrap text-jll-text-base-subdued whitespace-nowrap truncate",
        className,
      )}
      {...props}
    >
      <span className={cn("flex flex-row items-center truncate")}>
        {children}
      </span>
    </label>
  );
};

export default Label;
