import type { FC } from "react";
import Label from "../Label";
import cn from "classnames";

interface IRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  description?: string;
  error?: boolean;
  className?: string;
}

const Radio: FC<IRadioProps> = ({
  label = "",
  error,
  className,
  description,
  ...props
}) => {
  return (
    <div className={cn("flex items-center space-x-3", className)}>
      <div className="flex h-6 items-center">
        <input
          type="radio"
          className={cn(
            "h-[18px] w-[18px] border-jll-stroke-default text-jll-color-text-interaction focus:ring-jll-color-text-interaction",
            {
              "border-jll-text-rag-danger-accessible text-jll-text-rag-danger-accessible focus:ring-jll-text-rag-danger-accessible text-sm":
                !!error,
            },
          )}
          {...props}
        />
      </div>
      <div className="ml-3 text-sm">
        {label && (
          <Label
            htmlFor={props.id}
            className={cn(
              "cursor-pointer !text-jll-text-base-default text-base",
              {
                "text-jll-text-rag-danger-accessible": !!error,
              },
            )}
          >
            {label}
          </Label>
        )}
        {description && (
          <Label
            htmlFor={props.id}
            className={cn(
              "cursor-pointer !text-jll-text-base-subdued text-base",
              {
                "text-jll-text-rag-danger-accessible": !!error,
              },
            )}
          >
            {description}
          </Label>
        )}
      </div>
    </div>
  );
};

export default Radio;
