import cn from "classnames";
import { Navbar } from "ui-molecules";
import LayoutBase from "../LayoutBase";

interface ILayoutProps {
  children: any;
  className?: string;
}

function LayoutHome<T extends object>({ children, className }: ILayoutProps) {
  return (
    <LayoutBase>
      <div className={cn("w-full relative", className)}>
        <Navbar />
        <div className="fixed top-[80px] left-0 right-0 bottom-0 flex flex-col">
          {children}
        </div>
      </div>
    </LayoutBase>
  );
}

export default LayoutHome;
