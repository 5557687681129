import cn from "classnames";

interface ILoading {
  size?: number;
}
const Loading: React.FC<ILoading> = ({ size = 6 }) => {
  let columns: string[] = [];
  if (size === 4) {
    columns = ["w-2/12", "w-3/12", "w-3/12", "w-4/12"];
  } else if (size === 6) {
    columns = ["w-2/12", "w-1/12", "w-4/12", "w-1/12", "w-3/12", "w-2/12"];
  } else if (size === 2) {
    columns = ["w-4/12", "w-8/12"];
  }
  return (
    <div className="animate-loading flex flex-col w-full space-y-4">
      {[...Array(6)].map((_, idx) => (
        <div className="flex flex-row space-x-3" key={idx}>
          {columns?.map((column, idx1) => (
            <div
              key={idx1}
              className={cn(
                "bg-jll-surface-base-secondary-subdued h-10",
                {
                  "bg-opacity-100": idx === 0,
                  "bg-opacity-90": idx === 1,
                  "bg-opacity-80": idx === 2,
                  "bg-opacity-70": idx === 3,
                  "bg-opacity-60": idx === 4,
                  "bg-opacity-50": idx === 5,
                },
                column,
              )}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Loading;
