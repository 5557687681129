import { LayoutDetail } from "ui-organisms";
import ContactDetail from "./ContactDetail";
import {
  DETAIL_MENU_LIST,
  SET_DETAIL_INFO,
  SET_FULL_LOADING,
  SET_PREVIEW_PDF,
} from "constant";
import { useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import PropertyDetail from "./PropertyDetail";
import RentDetail from "./RentDetail";
import KeyDateDetail from "./KeyDateDetail";
import CollaboratorDetail from "./CollaboratorDetail";
import ClientLogoDetail from "./ClientLogoDetail";
import CoverDetail from "./CoverDetail";
import PageReorderDetail from "./PageReorderDetail";
import SpaceDetail from "./SpaceDetail";
import SettingDetail from "./SettingDetail";
import { getDetailAPI } from "services";
import { useApiCall } from "hooks";
import { GlobalContext } from "context";
import AdditionalInfoDetail from "./AdditionalInfoDetail";

const DetailPage = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { state, dispatch } = useContext(GlobalContext);
  const location = useLocation();
  const [getDetail] = useApiCall(getDetailAPI, true);
  const { closeoutId } = useParams();
  const isAdmin =
    session?.is_superuser ||
    session?.is_staff ||
    !!session?.groups?.find((group: any) => group?.id === 1);
  const [activeTab, setActiveTab] = useState(
    isAdmin
      ? DETAIL_MENU_LIST[0]?.sub_menu?.[0].value
      : DETAIL_MENU_LIST[1]?.sub_menu?.[0].value,
  );

  useEffect(() => {
    const filters: any = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (filters?.tab && filters?.tab?.toString()?.toLowerCase() !== activeTab) {
      setActiveTab(filters?.tab?.toString()?.toLowerCase());
    }

    if (filters?.preview === "true") {
      dispatch({
        type: SET_PREVIEW_PDF,
        payload: {
          open: true,
        },
      });
    } else {
      dispatch({
        type: SET_PREVIEW_PDF,
        payload: {
          open: false,
        },
      });
    }
  }, [location]);

  useEffect(() => {
    if (!closeoutId) {
      dispatch({
        type: SET_DETAIL_INFO,
        payload: null,
      });
      return;
    }
    dispatch({
      type: SET_FULL_LOADING,
      payload: {
        open: true,
        label: "Loading Detail",
      },
    });
    dispatch({
      type: SET_DETAIL_INFO,
      payload: null,
    });
    getDetail(closeoutId)
      ?.then((res: any) => {
        if (!res) {
          dispatch({
            type: SET_DETAIL_INFO,
            payload: null,
          });
          return;
        }
        dispatch({
          type: SET_DETAIL_INFO,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      });
  }, [closeoutId]);

  return (
    <LayoutDetail>
      {activeTab === DETAIL_MENU_LIST?.[0]?.sub_menu?.[0].value && isAdmin && (
        <SettingDetail />
      )}

      {activeTab === DETAIL_MENU_LIST[1]?.sub_menu?.[0]?.value && (
        <PropertyDetail />
      )}
      {activeTab === DETAIL_MENU_LIST[1]?.sub_menu?.[1]?.value && (
        <SpaceDetail />
      )}
      {activeTab === DETAIL_MENU_LIST[1]?.sub_menu?.[2]?.value && (
        <KeyDateDetail />
      )}
      {/* {activeTab === DETAIL_MENU_LIST[0].sub_menu[3].value && (
        <FloorplanDetail />
      )} */}
      {activeTab === DETAIL_MENU_LIST[1]?.sub_menu?.[3]?.value && (
        <RentDetail />
      )}
      {activeTab === DETAIL_MENU_LIST[1]?.sub_menu?.[4]?.value && (
        <ContactDetail />
      )}
      {activeTab === DETAIL_MENU_LIST[1]?.sub_menu?.[5]?.value && (
        <AdditionalInfoDetail />
      )}
      {/* {activeTab === DETAIL_MENU_LIST[1].sub_menu[5].value && (
        <AdditionalDocumentDetail />
      )} */}

      {/* {activeTab === DETAIL_MENU_LIST[2].sub_menu[0].value && <CoverDetail />} */}
      {activeTab === DETAIL_MENU_LIST[2].value && <PageReorderDetail />}

      {activeTab === DETAIL_MENU_LIST[3].value && <CollaboratorDetail />}

      {activeTab === "client_logo" && <ClientLogoDetail />}
    </LayoutDetail>
  );
};

export default DetailPage;
