import { Image, Page, Text, View } from "@react-pdf/renderer";
import { PAGE_ORIENTATION, PAGE_SIZE } from "constant";
import JLLLogoImg from "assets/images/pdf/jll-logo.png";
import styles from "./styles";

interface IPageLayoutV2 {
  headerStyle?: any;
  footerStyle?: any;
  children?: any;
  pageNumber?: number | string;
  id?: string;
  detail: any;
  bgImg?: any;
}

const PageLayoutV2: React.FC<IPageLayoutV2> = ({
  headerStyle,
  footerStyle,
  children,
  pageNumber,
  id,
  detail,
  bgImg,
}) => {
  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.PORTRAIT}
      style={styles.page}
      id={id}
    >
      {bgImg && (
        <View style={styles.bg.view}>
          <Image src={bgImg} style={styles.bg.img} />
        </View>
      )}
      {/* Header */}
      <View style={{ ...styles.header.view, ...headerStyle }}>
        <Image src={JLLLogoImg} style={styles.header.logoImg} />
        <Text style={styles.header.title}>{detail?.client || ""}</Text>
      </View>

      {/* Content */}
      <View style={styles.content}>{children}</View>

      {/* Footer */}
      <View style={{ ...styles.footer.view, ...footerStyle }}>
        <Text style={styles.footer.description}>
          This lease summary has been prepared on the basis of the document(s)
          and as at the date referred to above. The lease summary is for
          information purposes. The information may be partial and incomplete
          because of the necessarily simplified nature of this lease summary,
          which does not have a contractual/ binding value. Although JLL has
          taken care over what is stated in this lease summary, we have not
          attempted to provide exhaustive statements or any opinions on specific
          legal issues. JLL declines any direct or indirect liability regarding
          the accuracy, absence of errors, updated nature and exhaustiveness of
          the information contained in the lease summary. JLL cannot be held
          liable for any decision made on the basis of information contained in
          the lease summary nor for the use that you or any third parties might
          make of it. You should not rely on it as legal advice, or to form a
          legal or financial view on the lease agreement concerned. JLL does not
          accept any liability to any person who does rely on the content of
          this lease summary.​
        </Text>

        {!!pageNumber && (
          <View style={styles.footer.pageNumber.view}>
            <Text style={styles.footer.pageNumber.text}>{pageNumber}</Text>
          </View>
        )}
      </View>
    </Page>
  );
};

export default PageLayoutV2;
