import type { FC } from "react";
import cn from "classnames";
import {
  Close,
  ExpandLess,
  ExpandMore,
  InfoOutlined,
} from "@mui/icons-material";
import { Label } from "ui-atoms";
import { Listbox } from "@headlessui/react";
import DotAnimation from "assets/images/dot-animation.svg";
import { getDecimalFormatingForInput } from "utils";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputSize?: "default" | "large" | "small";
  type?: "text" | "email" | "number";
  area?: "SQ FT." | "Acres" | "";
  currency?: string;
  month?: boolean;
  error?: any;
  className?: string;
  optional?: boolean;
  leadingIcon?: any;
  trailingIcon?: any;
  onTrailingClick?: any;
  trailing?: any;
  isClearable?: boolean;
  name: string;
  onChange?: any;
  leadingCurrency?: string;
  inputClassName?: string;
  trailingSelect?: any;
  selectedValue?: any;
  options?: { label: string | number; value: string | number }[];
  onSelectChange?: any;
  isLoading?: boolean;
  isComma?: boolean;
  value?: any;
  isRequired?: boolean;
}

const Input: FC<IInputProps> = ({
  label = "",
  type = "text",
  error = "",
  inputSize = "default",
  className,
  currency,
  leadingCurrency,
  area,
  month,
  optional,
  leadingIcon,
  trailingIcon,
  onTrailingClick,
  isClearable,
  trailing,
  inputClassName,
  trailingSelect,
  selectedValue,
  options,
  onSelectChange,
  isLoading,
  isComma,
  value,
  isRequired,
  ...props
}) => {
  let LeadingIcon;
  if (leadingIcon) {
    LeadingIcon = leadingIcon;
  }
  let TrailingIcon;
  if (trailingIcon) {
    TrailingIcon = trailingIcon;
  }
  return (
    <div className={cn("relative mb-8 p-px", className)}>
      {label && (
        <div className="flex flex-row items-center">
          {label ? (
            <Label htmlFor={props.id} className="mb-2">
              {label}
              {isRequired ? (
                <span className="text-jll-text-rag-danger-accessible"> *</span>
              ) : (
                ""
              )}
            </Label>
          ) : (
            <span></span>
          )}
        </div>
      )}
      <div className={cn("relative rounded")}>
        {leadingIcon && !currency && !area && !month && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">
            <LeadingIcon className="!h-5 !w-5 text-jll-text-base-subdued" />
          </div>
        )}
        {leadingCurrency && (
          <div
            className={cn(
              "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-jll-text-base-subdued",
            )}
          >
            {leadingCurrency}
          </div>
        )}
        <input
          className={cn(
            "block w-full rounded text-jll-text-base-default text-sm focus:border-jll-surface-interaction-default focus:ring-jll-surface-interaction-default text-jll-text-base-default placeholder:text-jll-text-base-subdued focus:text-jll-surface-interaction-default",
            {
              "pl-9": !!leadingIcon || leadingCurrency,
              "pr-10": !!trailingIcon || !!trailing,
              "pr-12": currency || isLoading,
              "pr-16": area || month,
              "border-jll-color-surface-accent-default": !!error,
              "border-jll-stroke-default": !error,
              "py-1.5": inputSize === "small",
              "py-2.5": inputSize === "default",
              "py-3 px-4": inputSize === "large",
            },
            inputClassName,
          )}
          type={type}
          disabled={isLoading}
          value={isComma ? getDecimalFormatingForInput(value) : value}
          {...props}
        />
        {trailingIcon && !currency && !area && !month && (
          <div
            className={cn("absolute inset-y-0 right-0 flex items-center pr-3", {
              "cursor-pointer": !!onTrailingClick,
              "pointer-events-none": !onTrailingClick,
            })}
            onClick={!!onTrailingClick ? onTrailingClick : undefined}
          >
            <TrailingIcon className="h-5 w-5 text-gray-400" />
          </div>
        )}
        {isClearable && !!value?.toString()?.length && (
          <div
            className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={() => props?.onChange({ target: { value: "" } })}
          >
            <Close className="h-5 w-5 text-jll-color-text-base-default" />
          </div>
        )}
        {currency && (
          <div
            className={cn(
              "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-jll-text-base-subdued",
            )}
          >
            {currency}
          </div>
        )}
        {isLoading && (
          <div
            className={cn(
              "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-jll-text-base-subdued",
            )}
          >
            <img src={DotAnimation} />
          </div>
        )}
        {area && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {area}
          </div>
        )}
        {month && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-jll-text-base-subdued">
            Months
          </div>
        )}
        {trailing && (
          <div
            className={cn(
              "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-jll-text-base-subdued",
            )}
          >
            {trailing}
          </div>
        )}

        {!!trailingSelect && !!options?.length && (
          <div
            className={cn(
              "absolute inset-y-0 right-0 flex items-center pr-3 text-jll-text-base-subdued z-30",
            )}
          >
            <div className="relative">
              <Listbox
                as="div"
                value={
                  options?.find(
                    (item) =>
                      item.value?.toString() === selectedValue?.toString(),
                  ) || null
                }
                onChange={onSelectChange}
              >
                {({ open }) => (
                  <>
                    <Listbox.Button className="flex flex-row items-center border-l border-l-jll-stroke-default cursor-pointer">
                      <span className="text-jll-text-base-subdued mx-2">
                        {
                          options?.find(
                            (item) =>
                              item.value?.toString() ===
                              selectedValue?.toString(),
                          )?.label
                        }
                      </span>
                      {open ? (
                        <ExpandLess className="!h-4 !w-4 text-jll-text-base-subdued" />
                      ) : (
                        <ExpandMore className="!h-4 !w-4 text-jll-text-base-subdued" />
                      )}
                    </Listbox.Button>
                    {open && (
                      <Listbox.Options
                        className={cn(
                          "origin-top-right absolute max-h-60 w-fit rounded-lg py-2 bg-white shadow-drop z-20 top-8 overflow-y-auto",
                        )}
                      >
                        {options.map((option) => (
                          <Listbox.Option
                            className="px-4 py-1 text-jll-text-base-default text-base cursor-pointer hover:bg-jll-surface-base-secondary-subdued"
                            key={option.value}
                            value={option}
                          >
                            {option?.label}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    )}
                  </>
                )}
              </Listbox>
            </div>
          </div>
        )}
      </div>
      {!!error && (
        <p
          className="absolute -bottom-5.5 text-jll-text-rag-danger-accessible flex items-center flex-row truncate left-0 right-0 text-sm"
          title={error}
        >
          <InfoOutlined className="!w-4 !h-4 mr-2 mt-0.5" />
          <span className="truncate w-full">{error}</span>
        </p>
      )}
    </div>
  );
};

export default Input;
