import { DeleteOutlineOutlined, Search } from "@mui/icons-material";
import { useApiCall } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import {
  deleteCollaboratorAPI,
  getAccessAPI,
  getContactUsersAPI,
  postCollaboratorAPI,
} from "services";
import { Button, InputSearch } from "ui-atoms";
import { Footer, Loading, StyledContactValue, Table } from "ui-molecules";
import cn from "classnames";
import { useParams } from "react-router-dom";
import { SEARCH_RESULT_LIMIT, SET_FULL_LOADING } from "constant";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { GlobalContext } from "context";

const COLLABORATOR_COLUMNS: {
  label: string;
  id: string;
}[] = [
  { label: "Name", id: "name" },
  { label: "Job Title", id: "job_title" },
  { label: "Email", id: "email" },
  { label: "Phone", id: "phone_number" },
];

const CollaboratorDetail = () => {
  const { dispatch } = useContext(GlobalContext);
  const { closeoutId } = useParams();
  const tableRef = useRef<any>(null);
  const [getContactUsers, isLoading] = useApiCall(getContactUsersAPI);
  const [deleteCollaborator] = useApiCall(deleteCollaboratorAPI);
  const [postCollaborator] = useApiCall(postCollaboratorAPI);
  const [getAccess] = useApiCall(getAccessAPI);
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setPage(1);
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
    getContactUsers({
      lease_closeout_access: [closeoutId],
      page: 1,
      active_status: [1],
      limit: SEARCH_RESULT_LIMIT,
    })
      .then((res: any) => {
        if (!res) {
          return;
        }
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      });
  }, [closeoutId]);

  const onSelectContact = (item: any) => {
    postCollaborator({
      lease_closeout: Number(closeoutId),
      user_json: item,
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any[]) => [
        ...prevData,
        ...[
          {
            ...res?.user?.usermeta,
            lease_closeout_access: res?.id,
            pk: res?.user?.id,
            email: res?.user?.usermeta?.email || res?.user?.email,
          },
        ],
      ]);
    });
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getContactUsers({
      lease_closeout: [closeoutId],
      page: nextPage,
      active_status: [1],
      limit: SEARCH_RESULT_LIMIT,
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any[]) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const onDeleteUser = async (pk: number) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Deleting...",
        },
      });
      deleteCollaborator(pk)
        .then((res: any) => {
          const new_data = [...data]?.filter(
            (item) => item?.lease_closeout_access !== pk,
          );
          setData(new_data);
        })
        .finally(() => {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  return (
    <div className="relative w-full h-full">
      <section className="absolute top-0 left-0 right-0 bottom-[80px] pt-10 flex flex-col pl-14">
        <p className="text-jll-text-base-default text-2xl mb-8">
          Manage Access
        </p>
        <div className="mb-8">
          <InputSearch
            placeholder="Enter a name or email address"
            leadingIcon={Search}
            className="!mb-0 max-w-[500px]"
            dropClassName="max-w-[500px]"
            serviceApi={getAccess}
            onChange={(item: any) => onSelectContact(item)}
            kind="contact"
          />
        </div>
        {!isLoading && !data?.length && <Table.Loading size={4} />}

        {!!data?.length && (
          <div className="h-full -ml-14 overflow-y-auto" ref={tableRef}>
            <Table className="mb-2">
              <Table.Thead>
                <Table.Tr>
                  {COLLABORATOR_COLUMNS.map((column, idx) => (
                    <Table.Th
                      key={idx}
                      itemClassName={cn({ "!pl-14": idx === 0 })}
                    >
                      {column.label}
                    </Table.Th>
                  ))}
                  <Table.Th itemClassName="!pr-14" />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {data?.map((user: any, idx: number) => (
                  <Table.Tr key={idx} className="bg-white">
                    {COLLABORATOR_COLUMNS.map((column, idx1) => (
                      <Table.Td
                        key={idx1}
                        className={cn({ "!pl-14": idx1 === 0 })}
                      >
                        <StyledContactValue
                          contact={user}
                          valueKey={column.id}
                        />
                      </Table.Td>
                    ))}
                    <Table.Td className="!pr-14 space-x-1 text-right w-20">
                      <Button
                        variant="neutral"
                        kind="icon"
                        leadingIcon={DeleteOutlineOutlined}
                        onClick={() =>
                          onDeleteUser(user?.lease_closeout_access)
                        }
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
            {!!(total > data?.length) && (
              <div
                className="w-full py-2 flex justify-center items-center"
                ref={sentryRef}
              >
                <Loading />
              </div>
            )}
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};

export default CollaboratorDetail;
