import {
  DeleteOutlineOutlined,
  DragIndicatorOutlined,
  EditOutlined,
  Search,
} from "@mui/icons-material";
import { Button, InputSearch } from "ui-atoms";
import {
  DeleteConfirmModal,
  Loading,
  StyledContactValue,
  Table,
} from "ui-molecules";
import DealTimeModal from "./DealTimeModal";
import { useContext, useEffect, useRef, useState } from "react";
import { useApiCall } from "hooks";
import {
  deleteContactUserAPI,
  getAccessAPI,
  getContactUsersAPI,
  postUserAPI,
  putUserOrderAPI,
} from "services";
import { useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SEARCH_RESULT_LIMIT, SET_FULL_LOADING } from "constant";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { reorder } from "utils";
import { GlobalContext } from "context";

const DEAL_COLUMNS = [
  { label: "Name", id: "name" },
  { label: "Job Title", id: "job_title" },
  { label: "Email", id: "email" },
  { label: "Phone", id: "phone_number" },
];

const DealTeam = () => {
  const { dispatch } = useContext(GlobalContext);
  const { closeoutId } = useParams();
  const tableRef = useRef<any>(null);
  const [getAccess] = useApiCall(getAccessAPI);
  const [getContactUsers, isLoading] = useApiCall(getContactUsersAPI);
  const [postUser] = useApiCall(postUserAPI);
  const [deleteContactUser] = useApiCall(deleteContactUserAPI);
  const [putUserOrder] = useApiCall(putUserOrderAPI);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {
    setPage(1);
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
    getContactUsers({
      lease_closeout: [closeoutId],
      page: 1,
      active_status: [1],
      limit: SEARCH_RESULT_LIMIT,
    })
      .then((res: any) => {
        if (!res) {
          return;
        }
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      });
  }, [closeoutId]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getContactUsers({
      lease_closeout: [closeoutId],
      page: nextPage,
      active_status: [1],
      limit: SEARCH_RESULT_LIMIT,
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any[]) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const onSelectContact = (item: any) => {
    postUser({
      lease_closeout: Number(closeoutId),
      user_json: item,
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any[]) => [
        ...[
          {
            ...res?.user?.usermeta,
            headshot: res?.user?.usermeta?.headshot || res?.user?.photo,
            lease_closeout_user: res?.id,
            pk: res?.user?.id,
            email: res?.user?.usermeta?.email || res?.user?.email,
          },
        ],
        ...prevData,
      ]);
    });
  };

  const onDragContactEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    try {
      const new_data = [...data];
      const newOrder = reorder(
        [...new_data],
        result.source.index,
        result.destination.index,
      );
      setData(newOrder);
      const sort = newOrder.map(
        (order) => order?.lease_closeout_user || order?.user,
      );
      putUserOrder({ pk: closeoutId, sort }).catch((err: any) => {
        setData(new_data);
      });
    } catch (err) {}
  };

  const onDeleteUser = async (pk: number) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Deleting...",
        },
      });
      deleteContactUser(pk)
        .then((res: any) => {
          const new_data = [...data]?.filter(
            (item) => item?.lease_closeout_user !== pk,
          );
          setData(new_data);
        })
        .finally(() => {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  return (
    <div className="mt-8 flex flex-col h-full">
      <p className="text-jll-text-base-subdued mb-8">
        Add your team members to include on contacts section of the deliverable.
      </p>
      <div className="mb-8">
        <InputSearch
          placeholder="Enter a name or email address"
          leadingIcon={Search}
          className="!mb-0 max-w-[500px]"
          dropClassName="max-w-[500px]"
          serviceApi={getAccess}
          onChange={(item: any) => onSelectContact(item)}
          kind="contact"
        />
      </div>
      {!isLoading && !data?.length && <Table.Loading size={4} />}

      {!!data?.length && (
        <div className="h-full -mx-14 overflow-y-auto" ref={tableRef}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th itemClassName="!pl-14" />
                {DEAL_COLUMNS.map((column, idx) => (
                  <Table.Th key={idx}>{column.label}</Table.Th>
                ))}
                <Table.Th itemClassName="!pr-14" />
              </Table.Tr>
            </Table.Thead>
            <DragDropContext onDragEnd={onDragContactEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Table.Tbody
                    {...provided.droppableProps}
                    ref={provided?.innerRef}
                  >
                    {data?.map((item: any, idx: number) => (
                      <Draggable
                        key={idx}
                        draggableId={`user-${idx}`}
                        index={idx}
                      >
                        {(provided, snapshot) => (
                          <Table.Tr
                            key={idx}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="bg-white"
                          >
                            <Table.Td
                              {...provided.dragHandleProps}
                              className="!pl-14 w-10"
                            >
                              <DragIndicatorOutlined className="text-jll-color-textOndark-subdued" />
                            </Table.Td>
                            {DEAL_COLUMNS.map((column, idx) => (
                              <Table.Td key={idx}>
                                <StyledContactValue
                                  contact={item}
                                  valueKey={column.id}
                                />
                              </Table.Td>
                            ))}
                            <Table.Td className="!pr-14 space-x-1 text-right w-20">
                              <Button
                                variant="neutral"
                                kind="icon"
                                leadingIcon={EditOutlined}
                                onClick={() => {
                                  setIsOpen(true);
                                  setSelectedContact(item);
                                }}
                              />
                              <Button
                                variant="neutral"
                                kind="icon"
                                leadingIcon={DeleteOutlineOutlined}
                                onClick={() =>
                                  onDeleteUser(item?.lease_closeout_user)
                                }
                              />
                            </Table.Td>
                          </Table.Tr>
                        )}
                      </Draggable>
                    ))}
                  </Table.Tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
          {!!(total > data?.length) && (
            <div
              className="w-full py-2 flex justify-center items-center"
              ref={sentryRef}
            >
              <Loading />
            </div>
          )}
        </div>
      )}
      <DealTimeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        contact={selectedContact}
        data={data}
        setData={setData}
      />
      <DeleteConfirmModal isOpen={false} setIsOpen={() => {}} />
    </div>
  );
};

export default DealTeam;
