import { FacetsProps } from "types";
import cn from "classnames";
import { Button, DatePicker, Input, Label, RangeSlider } from "ui-atoms";
import {
  CalendarMonthOutlined,
  Close,
  SearchOutlined,
} from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Facet, Loading } from "ui-molecules";
import { useLocation, useSearchParams } from "react-router-dom";
import { getApiDate, getFilteredFacets, getUTCDate } from "utils";
import { useApiCall } from "hooks";
import { getUsersAPI } from "services";
import { SEARCH_RESULT_LIMIT } from "constant";
import debounce from "lodash.debounce";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface IAdminFilter {
  className?: string;
  facets?: FacetsProps;
  setIsSlidePane?: any;
}

const AdminFilter: React.FC<IAdminFilter> = ({
  className,
  facets,
  setIsSlidePane,
}) => {
  const location = useLocation();
  const [getUsers, isLoading] = useApiCall(getUsersAPI);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredBrokers, setFilteredBrokers] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [brokerKeyword, setBrokerKeyword] = useState("");
  const [sizeRange, setSizeRange] = useState<any>([
    Number(facets?.rsf_range?.rsf_minimum),
    Number(facets?.rsf_range?.rsf_maximum),
  ]);

  useEffect(() => {
    setFilteredBrokers([]);
    setBrokerKeyword("");
    setPage(1);
    getUsers({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      keyword: brokerKeyword,
      is_broker: true,
    }).then((data: any) => {
      if (!data) return;
      setFilteredBrokers([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
    });
  }, []);

  useEffect(() => {
    if (!searchParams?.get("rsf_min") || !searchParams?.get("rsf_max")) return;
    setSizeRange([
      Number(searchParams?.get("rsf_min")) ||
        Number(facets?.rsf_range?.rsf_minimum),
      Number(searchParams?.get("rsf_max")) ||
        Number(facets?.rsf_range?.rsf_maximum),
    ]);
  }, [searchParams]);

  const handleDateRange = (values: any, valueKey: string) => {
    let minValue, maxValue;
    if (Array.isArray(values)) {
      [minValue, maxValue] = values;
    } else {
      minValue = values;
    }
    if (!!minValue) {
      searchParams.set(`${valueKey}_min`, getApiDate(minValue) || "");
    } else {
      searchParams.delete(`${valueKey}_min`);
    }

    if (!!maxValue) {
      searchParams.set(`${valueKey}_max`, getApiDate(maxValue) || "");
    } else {
      searchParams.delete(`${valueKey}_max`);
    }

    setSearchParams(searchParams);
  };

  const handleSizeRange = () => {
    searchParams.set("rsf_min", sizeRange[0]);
    searchParams.set("rsf_max", sizeRange[1]);
    setSearchParams(searchParams);
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getUsers({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      keyword: brokerKeyword,
      is_broker: true,
    }).then((data: any) => {
      if (!data) return;
      setFilteredBrokers((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
    });
  };

  const updateKeywordFilter = (e: any) => {
    setFilteredBrokers([]);
    setPage(1);
    getUsers({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      keyword: e?.target?.value,
      is_broker: true,
    }).then((data: any) => {
      if (!data) return;
      setFilteredBrokers([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
    });
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [filteredBrokers],
  );

  const onChangeInput = (e: any) => {
    setBrokerKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > filteredBrokers?.length,
    onLoadMore: loadMore,
  });

  return (
    <div
      className={cn(
        "absolute top-0 left-0 bottom-0 w-full p-6 bg-white col-span-2 h-full overflow-y-auto",
        className,
      )}
    >
      <div className="relative pt-6 mb-3.5 flex flex-row items-center justify-between">
        <span className="text-black text-xl">Filters</span>
        <a
          type="button"
          className="text-black text-sm underline cursor-pointer"
          onClick={() => {
            setSearchParams({});
          }}
        >
          Clear all
        </a>
        <Button
          variant="neutral"
          onClick={() => setIsSlidePane(false)}
          leadingIcon={Close}
          size="large"
          className="absolute -top-3 -right-3"
          leadingIconClass="text-jll-color-text-disabled"
        />
      </div>

      <div className="flex flex-col mb-6">
        <Label className="text-jll-text-base-subdued capitalize mb-3.5">
          Brokers
        </Label>
        <Input
          name={""}
          placeholder="Search"
          leadingIcon={SearchOutlined}
          inputSize="small"
          className="!mb-3 !p-0"
          value={brokerKeyword}
          onChange={onChangeInput}
        />
        <div className="border border-jll-stroke-default py-2.5 px-3 rounded h-[200px] overflow-y-auto space-y-2">
          {filteredBrokers?.map((item: any, idx: number) => (
            <Facet
              key={idx}
              pageId={location.pathname.replace("/", "")}
              parentId="broker_team"
              label={
                item?.display_name ||
                [item?.first_name, item?.last_name]?.join(" ") ||
                item?.email
              }
              value={item?.pk}
              id={item?.pk}
              {...item}
            />
          ))}
          {!!(total > filteredBrokers?.length) && (
            <div
              className="w-full py-2 flex justify-center items-center"
              ref={sentryRef}
            >
              <Loading size="small" />
            </div>
          )}
        </div>
      </div>

      <DatePicker
        selectsRange={true}
        onChange={(update: any) => {
          handleDateRange(update, "execution_date");
        }}
        startDate={
          !!searchParams?.get("execution_date_min")
            ? getUTCDate(searchParams?.get("execution_date_min"))
            : undefined
        }
        endDate={
          !!searchParams?.get("execution_date_max")
            ? getUTCDate(searchParams?.get("execution_date_max"))
            : undefined
        }
        label="Execution Date"
        placeholderText="MM/DD/YYYY - MM/DD/YYYY"
        pickerClassName="!py-[7px]"
        trailingIconClass="!h-[36px] !bottom-0"
        trailingIcon={CalendarMonthOutlined}
        isClearable={true}
      />

      <DatePicker
        selectsRange={true}
        onChange={(update: any) => {
          handleDateRange(update, "commencement_date");
        }}
        startDate={
          !!searchParams?.get("commencement_date_min")
            ? getUTCDate(searchParams?.get("commencement_date_min"))
            : undefined
        }
        endDate={
          !!searchParams?.get("commencement_date_max")
            ? getUTCDate(searchParams?.get("commencement_date_max"))
            : undefined
        }
        label="Commencement Date"
        placeholderText="MM/DD/YYYY - MM/DD/YYYY"
        pickerClassName="!py-[7px]"
        trailingIconClass="!h-[36px] !bottom-0"
        trailingIcon={CalendarMonthOutlined}
        isClearable={true}
      />

      <DatePicker
        selectsRange={true}
        onChange={(update: any) => {
          handleDateRange(update, "expiration_date");
        }}
        startDate={
          !!searchParams?.get("expiration_date_min")
            ? getUTCDate(searchParams?.get("expiration_date_min"))
            : undefined
        }
        endDate={
          !!searchParams?.get("expiration_date_max")
            ? getUTCDate(searchParams?.get("expiration_date_max"))
            : undefined
        }
        label="Expiration Date"
        placeholderText="MM/DD/YYYY - MM/DD/YYYY"
        pickerClassName="!py-[7px]"
        trailingIconClass="!h-[36px] !bottom-0"
        trailingIcon={CalendarMonthOutlined}
        isClearable={true}
      />

      <div className="flex flex-col mb-6">
        <Label className="text-jll-text-base-subdued capitalize mb-3.5">
          Size (RSF)
        </Label>
        <RangeSlider
          max={facets?.rsf_range?.rsf_maximum}
          min={facets?.rsf_range?.rsf_minimum}
          value={sizeRange}
          onInput={setSizeRange}
          onThumbDragEnd={() => {
            handleSizeRange();
          }}
        />
        <div className="mt-5 flex flex-row justify-between items-center">
          <Input
            name={""}
            inputSize="small"
            className="!p-0 w-[80px] !mb-0"
            value={sizeRange[0]}
            onChange={(e: any) => {
              const inputValue = e?.target?.value?.replaceAll(",", "");
              const regex = /^[0-9\b]+$/;
              if (regex.test(inputValue) || inputValue === "") {
                setSizeRange([Number(inputValue), sizeRange?.[1]]);
              }
            }}
            isComma
            onBlur={handleSizeRange}
          />
          <Input
            name={""}
            inputSize="small"
            className="!p-0 w-[80px] !mb-0"
            value={sizeRange[1]}
            onChange={(e: any) => {
              const inputValue = e?.target?.value?.replaceAll(",", "");
              const regex = /^[0-9\b]+$/;
              if (regex.test(inputValue) || inputValue === "") {
                setSizeRange([sizeRange?.[0], Number(inputValue)]);
              }
            }}
            isComma
            onBlur={handleSizeRange}
          />
        </div>
      </div>

      <div className="flex flex-col mb-6">
        <Label className="text-jll-text-base-subdued capitalize mb-3.5">
          Status
        </Label>
        <div className="flex flex-col space-y-2">
          {getFilteredFacets(facets)
            ?.find((facet: any) => facet?.id === "status")
            ?.facets?.map((item: any, idx: number) => (
              <Facet
                key={idx}
                pageId={location.pathname.replace("/", "")}
                parentId="status"
                {...item}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default AdminFilter;
