import {
  AddOutlined,
  DeleteOutlineOutlined,
  DragIndicatorOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { useApiCall } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  deleteAdditionalInfoAPI,
  getAdditionalInfoAPI,
  putAdditionalInfoOrderAPI,
} from "services";
import { Button } from "ui-atoms";
import {
  Footer,
  Loading,
  StyledAdditionalInfoValue,
  Table,
} from "ui-molecules";
import cn from "classnames";
import AdditionalInfoModal from "./AdditionalInfoModal";
import { GlobalContext } from "context";
import {
  SEARCH_RESULT_LIMIT,
  SET_DELETE_MODAL,
  SET_FULL_LOADING,
} from "constant";
import { useParams } from "react-router-dom";
import { reorder } from "utils";

const COLUMNS = [
  { label: "Field", id: "name" },
  { label: "Description", id: "description" },
];

const AdditionalInfoDetail = () => {
  const { dispatch } = useContext(GlobalContext);
  const tableRef = useRef<any>(null);
  const { closeoutId } = useParams();
  const [getAdditionalInfo, isLoading] = useApiCall(getAdditionalInfoAPI);
  const [deleteAdditionalInfo] = useApiCall(deleteAdditionalInfoAPI);
  const [putAdditionalInfoOrder] = useApiCall(putAdditionalInfoOrderAPI);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState<any>(null);

  useEffect(() => {
    if (!closeoutId) return;
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
    getAdditionalInfo({
      lease_closeout: Number(closeoutId),
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [1],
    })
      .then((res: any) => {
        if (!res) {
          return;
        }
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      });
  }, [closeoutId]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getAdditionalInfo({
      lease_closeout: Number(closeoutId),
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [1],
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any[]) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const handleDeleteInfo = async (pk: number) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Deleting Info...",
        },
      });
      deleteAdditionalInfo(pk)
        .then((res: any) => {
          const new_data = [...data]?.filter((item) => item?.pk !== pk);
          setData(new_data);
        })
        .finally(() => {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "Deleting Info...",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "Deleting Info...",
        },
      });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    try {
      const new_data = [...data];
      const newOrder = reorder(
        [...new_data],
        result.source.index,
        result.destination.index,
      );
      setData(newOrder);
      const sort = newOrder.map((order) => order?.pk);
      putAdditionalInfoOrder({ pk: closeoutId, sort }).catch((err: any) => {
        setData(new_data);
      });
    } catch (err) {}
  };

  return (
    <>
      <div className="relative w-full h-full">
        <section className="absolute top-0 left-0 right-0 bottom-[80px] pt-10 flex flex-col px-14">
          <div className="w-full flex flex-row items-center justify-between mb-6">
            <p className="text-jll-text-base-default text-2xl">
              Additional Information
            </p>
            <Button
              variant="secondary"
              leadingIcon={AddOutlined}
              size="medium"
              onClick={() => {
                setIsOpen(true);
                setSelectedInfo(null);
              }}
            >
              Add Custom Field
            </Button>
          </div>

          {!isLoading && !data?.length && <Table.Loading size={2} />}

          {!!data?.length && (
            <div className="h-full -mx-14 overflow-y-auto" ref={tableRef}>
              <Table className="mb-2">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th itemClassName="!pl-14" />
                    {COLUMNS.map((column, idx) => (
                      <Table.Th key={idx}>{column.label}</Table.Th>
                    ))}
                    <Table.Th itemClassName="!pr-14" />
                  </Table.Tr>
                </Table.Thead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="space-reorder">
                    {(provided, snapshot) => (
                      <Table.Tbody
                        {...provided.droppableProps}
                        ref={provided?.innerRef}
                      >
                        {data?.map((item, idx) => (
                          <Draggable
                            key={idx}
                            draggableId={`space-item-${idx}`}
                            index={idx}
                          >
                            {(provided, snapshot) => (
                              <Table.Tr
                                key={idx}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={cn({
                                  "w-full table bg-white": snapshot.isDragging,
                                })}
                              >
                                <Table.Td className="!pl-14 w-10 align-top">
                                  <div
                                    className="w-6 h-6"
                                    {...provided.dragHandleProps}
                                  >
                                    <DragIndicatorOutlined className="!w-full !h-full text-jll-text-base-subdued" />
                                  </div>
                                </Table.Td>
                                {COLUMNS.map((column, idx1) => (
                                  <Table.Td key={idx1} className="align-top">
                                    <StyledAdditionalInfoValue
                                      info={item}
                                      valueKey={column?.id}
                                      index={idx}
                                    />
                                  </Table.Td>
                                ))}
                                <Table.Td className="!pr-14 space-x-1 text-right w-20 align-top">
                                  <Button
                                    variant="neutral"
                                    kind="icon"
                                    leadingIcon={EditOutlined}
                                    onClick={() => {
                                      setIsOpen(true);
                                      setSelectedInfo(item);
                                    }}
                                  />
                                  <Button
                                    variant="neutral"
                                    kind="icon"
                                    leadingIcon={DeleteOutlineOutlined}
                                    onClick={() => {
                                      dispatch({
                                        type: SET_DELETE_MODAL,
                                        payload: {
                                          open: true,
                                          content: {
                                            action: () =>
                                              handleDeleteInfo(item?.pk),
                                          },
                                        },
                                      });
                                    }}
                                  />
                                </Table.Td>
                              </Table.Tr>
                            )}
                          </Draggable>
                        ))}
                      </Table.Tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>

              {!!(total > data?.length) && (
                <div
                  className="w-full py-2 flex justify-center items-center"
                  ref={sentryRef}
                >
                  <Loading />
                </div>
              )}
            </div>
          )}
        </section>
        <Footer />
      </div>

      <AdditionalInfoModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedInfo={selectedInfo}
        data={data}
        setData={setData}
      />
    </>
  );
};

export default AdditionalInfoDetail;
