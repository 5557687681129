import { Close } from "@mui/icons-material";
import { SET_DELETE_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

const DeleteModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { deleteModalOpen, deleteModalContent } = state;

  const handleClose = () => {
    dispatch({
      type: SET_DELETE_MODAL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  return (
    <Modal isOpen={deleteModalOpen} setIsOpen={handleClose} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            Are you sure you want to delete?
          </h3>
          <Button
            variant="neutral"
            onClick={handleClose}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <Modal.Footer className="!mt-8">
        <div className="flex flex-row space-x-3 justify-end w-full">
          <Button variant="secondary" className="w-32" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            className="w-32"
            onClick={() => {
              deleteModalContent?.action();
              handleClose();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
