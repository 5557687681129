import {
  Close,
  DeleteOutlineOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import { Button, Dropzone, Input } from "ui-atoms";
import { Modal } from "ui-molecules";
import { SET_ERROR_MODAL, SET_FULL_LOADING } from "constant";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApiCall } from "hooks";
import { patchContactUserAPI, postImageAPI } from "services";
import { checkFileType, lazyLoadImageOnError } from "utils";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface IDealTimeModal {
  isOpen: boolean;
  setIsOpen: any;
  contact?: any;
  data?: any;
  setData?: any;
}

const INIT_CONTACT = {
  name: "",
  job_title: "",
  email: "",
  phone_number: "",
  headshot: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  // job_title: Yup.string().required("This field is required"),
  email: Yup.string().nullable().email("Invalid Email"),
  // phone_number: Yup.string().required("This field is required"),
});

const DealTimeModal: React.FC<IDealTimeModal> = ({
  isOpen,
  setIsOpen,
  contact,
  data,
  setData,
}) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { errorModalOpen } = state;
  const [patchContactUser] = useApiCall(patchContactUserAPI);
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    if (!contact) {
      setValues({ ...INIT_CONTACT });
      setFiles([]);
      return;
    }
    let formValues: any = {};
    Object.keys(INIT_CONTACT).forEach((key: string) => {
      if (key === "name") {
        formValues = {
          ...formValues,
          [key]:
            contact?.["display_name"] ||
            contact?.["name"] ||
            [contact?.["first_name"], contact?.["last_name"]].join(" ") ||
            contact?.get_full_name ||
            "",
        };
      } else
        formValues = {
          ...formValues,
          [key]: contact?.[key] || "",
        };
    });
    setValues({ ...formValues });
    if (!!formValues?.headshot) {
      setFiles([formValues?.headshot]);
    } else {
      setFiles([]);
    }
  }, [contact]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: INIT_CONTACT,
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Submitting",
          },
        });
        let result = null;
        if (files?.length > 0) {
          if (typeof files?.[0] !== "string")
            result = await postImageAPI(files[0]);
          else result = files?.[0];
        }

        let payload = {};
        payload = { ...values, headshot: result };

        if (!!contact) {
          patchContactUser({ id: contact?.pk, payload })
            .then((res: any) => {
              if (!res) return;
              let new_data = [...data];
              const index = new_data?.findIndex(
                (item) => item.user === res?.user || item?.pk === res?.user,
              );
              if (index < 0) return;
              new_data[index] = { ...new_data[index], ...res };
              setData(new_data);
            })
            .finally(() => {
              dispatch({
                type: SET_FULL_LOADING,
                payload: {
                  open: false,
                  label: "",
                },
              });
              setIsOpen(false);
            });
        } else {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "",
            },
          });
        }
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      }
    },
  });

  const onRemovePhoto = () => {
    setFiles([]);
  };

  const handleClose = () => {
    if (errorModalOpen) return;
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={handleClose} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            {`${!!contact && "Edit "}Contact`}
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mt-6">
            <div className="flex flex-row space-x-6 items-end mb-6">
              <LazyLoadImage
                className="w-[110px] h-[110px] rounded-full object-cover"
                src={
                  files?.[0]
                    ? typeof files?.[0] === "string"
                      ? files[0]
                      : URL.createObjectURL(files[0])
                    : ""
                }
                onError={lazyLoadImageOnError}
              />
              <div className="flex flex-col space-y-2">
                <Dropzone
                  divClassName="!border-none"
                  setDropFiles={async (files: any) => {
                    const fileType = await checkFileType(files[0]);

                    if (
                      !fileType?.includes("image/jpeg") &&
                      !fileType?.includes("image/png")
                    ) {
                      dispatch({
                        type: SET_ERROR_MODAL,
                        payload: {
                          open: true,
                          content: {
                            label: "Warning",
                            value:
                              "File not accepted. Only PNG and JPEG files can be rendered in the deliverable. Please upload a PNG or JPEG. ",
                          },
                        },
                      });
                      return;
                    }
                    setFiles(files);
                  }}
                  maxFileCount={1}
                  acceptType={{ "image/*": [".png", ".jpg", ".jpeg"] }}
                >
                  <Button
                    variant="secondary"
                    size="small"
                    leadingIcon={FileUploadOutlined}
                  >
                    Add Image
                  </Button>
                </Dropzone>

                <Button
                  variant="secondary"
                  size="small"
                  className="flex flex-row justify-center"
                  leadingIcon={DeleteOutlineOutlined}
                  onClick={onRemovePhoto}
                >
                  Remove
                </Button>
              </div>
            </div>

            <Input
              name="name"
              label="Name"
              onChange={handleChange}
              error={touched.name ? errors.name : ""}
              onBlur={handleBlur}
              value={values?.name}
            />

            <Input
              name="job_title"
              label="Job Title"
              onChange={handleChange}
              error={touched.job_title ? errors.job_title : ""}
              onBlur={handleBlur}
              value={values?.job_title}
            />

            <Input
              name="email"
              label="Email"
              onChange={handleChange}
              error={touched.email ? errors.email : ""}
              onBlur={handleBlur}
              value={values?.email}
            />

            <Input
              name="phone_number"
              label="Phone"
              className="mb-2"
              onChange={handleChange}
              error={touched.phone_number ? errors.phone_number : ""}
              onBlur={handleBlur}
              value={values?.phone_number}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex flex-row space-x-3 w-full justify-end">
            <Button
              variant="secondary"
              className="w-32"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" className="w-32" type="submit">
              Save
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DealTimeModal;
