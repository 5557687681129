import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input } from "ui-atoms";
import debounce from "lodash.debounce";
import { useComponentVisible } from "hooks";
import { Loading } from "ui-molecules";
import { Transition } from "@headlessui/react";
import cn from "classnames";

interface IInputSearch {
  [key: string]: any;
  serviceApi?: any;
  onChange?: any;
  className?: string;
  dropClassName?: string;
  kind?: "building" | "contact";
}

const InputSearch: React.FC<IInputSearch> = ({
  serviceApi,
  name = "",
  onChange,
  className,
  kind = "building",
  dropClassName,
  ...props
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  const [keyword, setKeyword] = useState("");
  const searchResultRef = useRef([]);
  const loadingRef = useRef(false);

  const loadData = useCallback((e: any) => {
    try {
      if (!serviceApi || !e?.target?.value) {
        searchResultRef.current = [];
        setIsComponentVisible(false);
        return;
      }
      if (e?.target?.value === keyword) return;
      loadingRef.current = true;
      serviceApi({ keyword: e?.target?.value }).then((res: any) => {
        if (!!res?.data) {
          searchResultRef.current =
            res?.data?.search?.propertySearchResults || res?.data || [];
          setIsComponentVisible(true);
        } else if (!!res?.docs) {
          searchResultRef.current = res?.docs || [];
          setIsComponentVisible(true);
        } else {
          searchResultRef.current = [];
          setIsComponentVisible(false);
        }
        loadingRef.current = false;
      });
    } catch (err) {
      loadingRef.current = false;
      searchResultRef.current = [];
      setIsComponentVisible(false);
    }
  }, []);

  const debounceUpdateKeyword = useMemo(() => debounce(loadData, 500), []);

  const onChangeInput = (e: any) => {
    setKeyword(e?.target?.value);
    debounceUpdateKeyword(e);
  };

  const onSelectItem = (item: any) => {
    onChange && onChange(item);
    searchResultRef.current = [];
    setIsComponentVisible(false);
  };

  return (
    <div className="relative" ref={ref}>
      <Input
        name={name}
        {...props}
        onChange={onChangeInput}
        className={className}
      />
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className={cn(
          "absolute w-full rounded bg-white shadow-lg z-30",
          dropClassName,
        )}
        show={loadingRef.current}
      >
        <div className="absolute origin-top-right py-3 w-full flex justify-center items-center shadow-drop bg-white">
          <Loading />
        </div>
      </Transition>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className={cn(
          "absolute mt-1 w-full rounded bg-white shadow-lg z-40 ",
          dropClassName,
        )}
        show={
          isComponentVisible &&
          !!searchResultRef.current?.length &&
          !loadingRef.current
        }
      >
        <div className="absolute origin-top-right z-40 w-full rounded py-2 shadow-drop bg-white max-h-[250px] overflow-y-auto">
          {kind === "building" &&
            searchResultRef.current.map((item: any, idx) => (
              <div
                key={idx}
                className="py-2 px-4 cursor-pointer hover:bg-jll-surface-base-secondary-subdued"
                onClick={() => onSelectItem(item)}
              >
                <p className="text-jll-text-base-default">
                  {item?.name || item?.market || ""}
                </p>
                <p className="text-jll-text-base-subdued truncate">
                  {item?.market || item?.full_address || ""}
                </p>
              </div>
            ))}
          {kind === "contact" &&
            searchResultRef.current.map((item: any, idx) => (
              <div
                key={idx}
                className="py-2 px-4 cursor-pointer hover:bg-jll-surface-base-secondary-subdued"
                onClick={() => onSelectItem(item)}
              >
                <p className="text-jll-text-base-default">
                  {[item?.firstName, item?.lastName].join(" ")}
                </p>
                <p className="text-jll-text-base-subdued">
                  {item?.email || ""}
                </p>
              </div>
            ))}
        </div>
      </Transition>
    </div>
  );
};

export default InputSearch;
