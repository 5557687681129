import { CheckOutlined } from "@mui/icons-material";
import { STATUS_IN_REVIEW, STATUS_READY, STATUS_TO_DO } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { ListProps } from "types";
import { Chip } from "ui-atoms";
import {
  getDecimalFormating,
  getFormatedDate,
  getFormatedDateTime,
} from "utils";
import { format } from "date-fns";

export interface StyledListValueProps {
  list: ListProps;
  valueKey: keyof ListProps | "broker";
}

const StyledListValue: React.FC<StyledListValueProps> = ({
  list,
  valueKey,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "commencement_date":
          case "expiration_date":
            if (!list?.[valueKey]) return <></>;
            return <>{getFormatedDate(list?.[valueKey])}</>;

          case "update_timestamp":
            if (!list?.updated_user_name)
              return <>{getFormatedDateTime(list?.[valueKey])}</>;
            return (
              <>
                <p className="text-sm">
                  {format(new Date(list?.[valueKey]), "MM/dd/yyyy hh:mma")}
                </p>
                <p className="text-sm">{list?.updated_user_name}</p>
              </>
            );

          case "status":
            let variant: "primary" | "secondary" | "alert" = "secondary";
            if (list?.[valueKey] === STATUS_TO_DO) variant = "secondary";
            else if (list?.[valueKey] === STATUS_IN_REVIEW) variant = "alert";
            else if (list?.[valueKey] === STATUS_READY) variant = "primary";

            return (
              <Chip variant={variant} className="flex flex-row items-center">
                {list?.[valueKey] === STATUS_READY && (
                  <CheckOutlined className="text-jll-color-text-successAccessible !w-4 !h-4 mr-2" />
                )}
                {meta?.leasecloseout?.status?.[list?.[valueKey]]?.value}
              </Chip>
            );

          case "total_rsf":
            if (!list?.[valueKey]) return <></>;
            return <>{getDecimalFormating(list?.[valueKey])}</>;

          case "broker":
            if (!list?.broker_team_ids?.length) return <></>;
            return (
              <>
                <p className="text-sm">
                  {list?.broker_team_ids?.[0]?.get_full_name}
                </p>
                <p className="text-sm">{list?.broker_team_ids?.[0]?.email}</p>
              </>
            );

          default:
            if (list?.hasOwnProperty(valueKey)) {
              return <>{list?.[valueKey] || ""}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledListValue;
