import { Footer } from "ui-molecules";
import BaseRent from "./BaseRent";

const RentDetail = () => {
  return (
    <div className="relative w-full h-full">
      <section className="absolute top-10 left-0 right-0 bottom-[80px] flex flex-col px-14">
        <p className="text-jll-text-base-default text-2xl mb-10">
          Rent & Expenses
        </p>
        <div className="h-full relative">
          <BaseRent />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default RentDetail;
