import { View, Text, Image } from "@react-pdf/renderer";
import PageLayoutV2 from "../PageLayoutV2";
import LocationLogo from "assets/images/pdf/location_on.png";
import PhoneLogo from "assets/images/pdf/phone_enabled.png";
import UnknownUserImg from "assets/images/pdf/unknown-user.png";
import GlobalLogo from "assets/images/pdf/v2/global.png";
import styles from "./styles";

interface IPageContactV2 {
  users: any;
  detail: any;
  pageNumber?: string | number;
  id?: string;
}

interface IContactItem {
  photo?: string;
  name?: string;
  title?: string;
  email?: string;
  phone_number?: string;
  size?: "default" | "lg";
}

const ContactItem: React.FC<IContactItem> = ({
  photo,
  name,
  title,
  email,
  phone_number,
  size = "default",
}) => {
  let contact_styles;

  switch (size) {
    case "lg":
      contact_styles = { ...styles?.contact.list.lg };
      break;
    default:
      contact_styles = { ...styles?.contact.list.default };
  }

  return (
    <View style={contact_styles.view}>
      {!!photo?.length ? (
        <Image src={photo} style={contact_styles.photo} />
      ) : (
        <Image
          src={UnknownUserImg}
          style={{ ...contact_styles.photo, objectFit: "cover" }}
        />
      )}
      <View style={contact_styles.description.view}>
        <View style={contact_styles.description.propertyView1}>
          {!!name?.length && (
            <Text style={contact_styles.description.name}>{name}</Text>
          )}
          {!!title?.length && (
            <Text style={contact_styles.description.title}>{title}</Text>
          )}
        </View>
        {!!email?.length && (
          <Text style={contact_styles.description.email}>{email}</Text>
        )}
        {!!phone_number?.length && (
          <Text style={contact_styles.description.email}>{phone_number}</Text>
        )}
      </View>
    </View>
  );
};

const PageContactV2: React.FC<IPageContactV2> = ({
  users,
  detail,
  pageNumber,
  id,
}) => {
  const isJLLAddress =
    !!detail?.jll_building_name?.length ||
    !!detail?.jll_building_address?.length ||
    !!detail?.jll_building_city?.length ||
    !!detail?.jll_building_state?.length ||
    !!detail?.jll_building_zip?.length
      ? true
      : false;

  const isJLLNumber = !!detail?.jll_building_phone?.length ? true : false;

  return (
    <PageLayoutV2
      footerStyle={{
        backgroundColor: "#F6F9FA",
      }}
      pageNumber={pageNumber}
      id={id}
      detail={detail}
    >
      <View style={styles.contact.view}>
        <Text style={styles.contact.title}>Your JLL Team</Text>
        <Text
          style={{
            ...styles.contact.description,
            marginBottom: users?.length > 2 ? 43 : 51,
          }}
        >
          We bring deep expertise across all asset types, locations and size
          ranges.{"\n"}Our goal is to help you find the best space that inspires
          your workforce and{"\n"}helps your business thrive in the changing
          world of work.
        </Text>
        <View style={styles.contact.list.view}>
          {users.map((item: any, idx: number) => {
            if (!item) return;
            return (
              <ContactItem
                key={idx}
                photo={item?.headshot}
                name={item?.display_name}
                title={item?.job_title}
                email={item?.email}
                phone_number={item?.phone_number}
                size={users?.length <= 2 ? "lg" : "default"}
              />
            );
          })}
        </View>

        <View style={styles.reach.view}>
          <Text
            style={{
              ...styles.reach.label,
              width: "27%",
            }}
          >
            How to Reach Us
          </Text>
          <View style={{ width: "73%", display: "flex", flexDirection: "row" }}>
            {!!isJLLAddress && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 20,
                  width: "50%",
                }}
              >
                <Image
                  src={LocationLogo}
                  style={{ marginRight: 8, minWidth: 12, height: 12 }}
                />
                <View>
                  {!!detail?.jll_building_name?.length && (
                    <Text style={{ ...styles.reach.label }}>
                      {detail?.jll_building_name}
                    </Text>
                  )}
                  {!!detail?.jll_building_address?.length && (
                    <Text style={{ ...styles.reach.label }}>
                      {detail?.jll_building_address}
                    </Text>
                  )}
                  {(!!detail?.jll_building_city?.length ||
                    !!detail?.jll_building_state?.length ||
                    !!detail?.jll_building_zip?.length) && (
                    <Text style={{ ...styles.reach.label }}>
                      {[
                        detail?.jll_building_city,
                        [
                          detail?.jll_building_state,
                          detail?.jll_building_zip,
                        ].join(" "),
                      ].join(", ")}
                    </Text>
                  )}
                </View>
              </View>
            )}

            <View
              style={{
                width: "50%",
              }}
            >
              {!!isJLLNumber && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 4,
                  }}
                >
                  <Image
                    src={PhoneLogo}
                    style={{ marginRight: 8, minWidth: 12, height: 12 }}
                  />
                  <Text style={{ ...styles.reach.label }}>
                    {detail?.jll_building_phone}
                  </Text>
                </View>
              )}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Image
                  src={GlobalLogo}
                  style={{
                    marginRight: 8,
                    minWidth: 12,
                    height: 10,
                    objectFit: "contain",
                  }}
                />
                <Text style={{ ...styles.reach.label }}>us.jll.com</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </PageLayoutV2>
  );
};

export default PageContactV2;
