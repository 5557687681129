import { DeleteOutlineOutlined, FileUploadOutlined } from "@mui/icons-material";
import { SET_DETAIL_INFO, SET_ERROR_MODAL, SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { patchDetailAPI, postImageAPI } from "services";
import { Button, Dropzone, Input } from "ui-atoms";
import { capitalize, checkFileType, lazyLoadImageOnError } from "utils";

const ClientLogoDetail = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { detailInfo } = state;
  const [patchDetail] = useApiCall(patchDetailAPI);
  const { closeoutId } = useParams();
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    if (!detailInfo) {
      setClientName("");
      return;
    }
    setClientName(detailInfo?.client);
  }, [detailInfo]);

  const handleSubmitImg = async (files: any) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Uploading Logo...",
        },
      });
      if (files?.length <= 0) return;
      const result = await postImageAPI(files[0]);
      if (!result) return;

      patchDetail({ id: closeoutId, payload: { client_logo: result } })
        .then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              client_logo: res?.client_logo,
            },
          });
        })
        .finally(() => {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  const handleDetail = (name: string, value: any, e?: any) => {
    try {
      if (name === "client" && clientName === detailInfo?.["client"]) return;
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: `Updating ${capitalize(name)}...`,
        },
      });
      patchDetail({ id: closeoutId, payload: { [name]: value } })
        .then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_DETAIL_INFO,
            payload: {
              ...detailInfo,
              [name]: res?.[name],
            },
          });
        })
        .finally(() => {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  return (
    <div className="relative w-full h-full">
      <section className="absolute top-0 left-0 right-0 bottom-0 pt-10 flex pb-4 flex-col pl-14 overflow-y-auto">
        <p className="text-jll-text-base-default text-2xl mb-10">Client Logo</p>
        <p className="text-jll-text-base-subdued mb-2">Upload client logo</p>
        {!detailInfo?.client_logo ? (
          <Dropzone
            className="w-[160px] min-h-[160px] bg-jll-surface-base-secondary-subdued mb-8"
            setDropFiles={async (files: any) => {
              const fileType = await checkFileType(files[0]);

              if (
                !fileType?.includes("image/jpeg") &&
                !fileType?.includes("image/png")
              ) {
                dispatch({
                  type: SET_ERROR_MODAL,
                  payload: {
                    open: true,
                    content: {
                      label: "Warning",
                      value:
                        "File not accepted. Only PNG and JPEG files can be rendered in the deliverable. Please upload a PNG or JPEG. ",
                    },
                  },
                });
                return;
              }
              handleSubmitImg(files);
            }}
            maxFileCount={1}
            acceptType={{ "image/*": [".png", ".jpg", ".jpeg"] }}
          >
            <div className="flex flex-col justify-center items-center w-[130px]">
              <FileUploadOutlined className="!w-8 !h-8 text-jll-color-text-interaction mb-2" />
              <p className="text-jll-text-base-subdued text-sm text-center">
                Drag an image here, or click to browse
              </p>
            </div>
          </Dropzone>
        ) : (
          <div className="flex flex-row items-end space-x-8 mb-8">
            <LazyLoadImage
              src={detailInfo?.client_logo}
              onError={lazyLoadImageOnError}
              className="w-[160px] h-[160px] object-contain rounded border border-jll-stroke-subdued"
            />
            <div className="flex flex-col justify-between h-full">
              <p className="text-jll-text-base-subdued w-[150px]">
                Use jpg or png format smaller than 2MB
              </p>
              <div className="flex flex-col space-y-2.5 w-fit">
                <Dropzone
                  divClassName="!border-none"
                  setDropFiles={async (files: any) => {
                    const fileType = await checkFileType(files[0]);

                    if (
                      !fileType?.includes("image/jpeg") &&
                      !fileType?.includes("image/png")
                    ) {
                      dispatch({
                        type: SET_ERROR_MODAL,
                        payload: {
                          open: true,
                          content: {
                            label: "Warning",
                            value:
                              "File not accepted. Only PNG and JPEG files can be rendered in the deliverable. Please upload a PNG or JPEG. ",
                          },
                        },
                      });
                      return;
                    }
                    handleSubmitImg(files);
                  }}
                  maxFileCount={1}
                  acceptType={{ "image/*": [".png", ".jpg", ".jpeg"] }}
                >
                  <Button
                    variant="secondary"
                    leadingIcon={FileUploadOutlined}
                    size="medium"
                  >
                    Add Logo
                  </Button>
                </Dropzone>
                <Button
                  variant="secondary"
                  size="medium"
                  className="flex flex-row justify-center"
                  leadingIcon={DeleteOutlineOutlined}
                  onClick={() => handleDetail("client_logo", null)}
                >
                  Remove
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="w-[400px]">
          <Input
            name="name"
            label="Client Company Name"
            className="w-full"
            value={clientName}
            onChange={(e: any) => setClientName(e.currentTarget.value)}
            onBlur={(e) => handleDetail("client", clientName, e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleDetail("client", clientName);
              }
            }}
          />
        </div>
      </section>
    </div>
  );
};

export default ClientLogoDetail;
