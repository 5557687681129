import { Image, Page, Text, View } from "@react-pdf/renderer";
import { PAGE_ORIENTATION, PAGE_SIZE } from "constant";
import LogoWhiteImg from "assets/images/pdf/v2/logo-white.png";
import SolarBgImg from "assets/images/pdf/v2/solar-white.png";
import LocationLogo from "assets/images/pdf/v2/location_white.png";
import PhoneLogo from "assets/images/pdf/v2/phone_white.png";
import GlobalLogo from "assets/images/pdf/v2/global-white.png";
import styles from "./styles";

interface IPageBackV2 {
  detail: any;
}

const PageBackV2: React.FC<IPageBackV2> = ({ detail }) => {
  const isJLLAddress =
    !!detail?.jll_building_name?.length ||
    !!detail?.jll_building_address?.length ||
    !!detail?.jll_building_city?.length ||
    !!detail?.jll_building_state?.length ||
    !!detail?.jll_building_zip?.length
      ? true
      : false;

  const isJLLNumber = !!detail?.jll_building_phone?.length ? true : false;

  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.PORTRAIT}
      style={styles.page}
    >
      <View style={styles.header.view}>
        <Image src={LogoWhiteImg} style={styles.header.img} />
        <Text style={styles.header.client}>{detail?.client || ""}</Text>
      </View>

      <View style={styles.content.view}>
        <View style={styles.content.back.view}>
          <Image src={SolarBgImg} style={styles.content.back.img} />
        </View>

        <View style={styles.content.section.view}>
          <Text style={styles.content.section.title}>Thank you</Text>

          <View style={styles.content.section.reach.view}>
            {!!isJLLAddress && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 20,
                  width: "40%",
                }}
              >
                <Image
                  src={LocationLogo}
                  style={{ marginRight: 8, minWidth: 12, height: 12 }}
                />
                <View>
                  {!!detail?.jll_building_name?.length && (
                    <Text style={{ ...styles.content.section.reach.label }}>
                      {detail?.jll_building_name}
                    </Text>
                  )}
                  {!!detail?.jll_building_address?.length && (
                    <Text style={{ ...styles.content.section.reach.label }}>
                      {detail?.jll_building_address}
                    </Text>
                  )}
                  {(!!detail?.jll_building_city?.length ||
                    !!detail?.jll_building_state?.length ||
                    !!detail?.jll_building_zip?.length) && (
                    <Text style={{ ...styles.content.section.reach.label }}>
                      {[
                        detail?.jll_building_city,
                        [
                          detail?.jll_building_state,
                          detail?.jll_building_zip,
                        ].join(" "),
                      ].join(", ")}
                    </Text>
                  )}
                </View>
              </View>
            )}

            <View
              style={{
                width: "50%",
              }}
            >
              {!!isJLLNumber && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 4,
                  }}
                >
                  <Image
                    src={PhoneLogo}
                    style={{ marginRight: 8, minWidth: 12, height: 12 }}
                  />
                  <Text style={{ ...styles.content.section.reach.label }}>
                    {detail?.jll_building_phone}
                  </Text>
                </View>
              )}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Image
                  src={GlobalLogo}
                  style={{
                    marginRight: 8,
                    minWidth: 14,
                    height: 12,
                    objectFit: "contain",
                  }}
                />
                <Text
                  style={{
                    ...styles.content.section.reach.label,
                    marginBottom: 0,
                  }}
                >
                  us.jll.com
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.content.section.description.view}>
            <View style={{ marginBottom: 6 }}>
              <Text style={{ marginBottom: 6 }}>About JLL</Text>
              <Text>
                JLL (NYSE: JLL) is a leading professional services firm that
                specializes in real estate and investment management. JLL shapes
                the future of real estate for a better world by using the most
                advanced technology to create rewarding opportunities, amazing
                spaces and sustainable real estate solutions for our clients,
                our people and our communities. JLL is a Fortune 500 company
                with annual revenue of $16.6 billion in 2020, operations in over
                80 countries and a global workforce of more than 91,000 as of
                March 31, 2021. JLL is the brand name, and a registered
                trademark, of Jones Lang LaSalle Incorporated.​
              </Text>
            </View>

            <View>
              <Text style={{ marginBottom: 6 }}>Disclaimer​</Text>
              <Text>
                This lease summary has been prepared on the basis of the
                document(s) and as at the date referred to above. The lease
                summary is for information purposes. The information may be
                partial and incomplete because of the necessarily simplified
                nature of this lease summary, which does not have a contractual/
                binding value. Although JLL has taken care over what is stated
                in this lease summary, we have not attempted to provide
                exhaustive statements or any opinions on specific legal issues.
                JLL declines any direct or indirect liability regarding the
                accuracy, absence of errors, updated nature and exhaustiveness
                of the information contained in the lease summary. JLL cannot be
                held liable for any decision made on the basis of information
                contained in the lease summary nor for the use that you or any
                third parties might make of it. You should not rely on it as
                legal advice, or to form a legal or financial view on the lease
                agreement concerned. JLL does not accept any liability to any
                person who does rely on the content of this lease summary.​
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default PageBackV2;
