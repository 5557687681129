import {
  CREATE_LIGHTGALLERY,
  SET_CALC_REF,
  SET_DELETE_MODAL,
  SET_DETAIL_INFO,
  SET_ERROR_MODAL,
  SET_FULL_LOADING,
  SET_META,
  SET_NO_DATA_MODAL,
  SET_PREVIEW_PDF,
  SET_TOOLTIP,
  UPDATE_LIGHTGALLERY_DATA,
} from "constant";
import { useReducer, createContext } from "react";

const initialState: any = {
  meta: null,

  showTooltip: null,
  hideTooltip: null,

  lightGalleryData: [],
  lightGalleryRef: null,

  previewPDF: null,

  fullLoadingOpen: false,
  fullLoadingContent: "",

  detailInfo: null,

  calcRef: null,

  errorModalOpen: false,
  errorModalContent: null,

  noDataModalOpen: false,
  noDataModalContent: null,

  deleteModalOpen: false,
  deleteModalContent: null,
};

export const GlobalContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_META:
      return {
        ...state,
        meta: action?.payload,
      };

    case SET_TOOLTIP:
      return {
        ...state,
        showTooltip: action?.payload?.showTooltip,
        hideTooltip: action?.payload?.hideTooltip,
      };

    case UPDATE_LIGHTGALLERY_DATA:
      return {
        ...state,
        lightGalleryData: action.payload,
      };

    case CREATE_LIGHTGALLERY:
      return {
        ...state,
        lightGalleryRef: action.payload,
      };

    case SET_PREVIEW_PDF:
      return {
        ...state,
        previewPDF: action.payload,
      };

    case SET_FULL_LOADING:
      return {
        ...state,
        fullLoadingOpen: action?.payload?.open,
        fullLoadingContent: action?.payload?.label,
      };

    case SET_DETAIL_INFO:
      return {
        ...state,
        detailInfo: action?.payload,
      };

    case SET_CALC_REF:
      return {
        ...state,
        calcRef: action?.payload,
      };

    case SET_ERROR_MODAL:
      return {
        ...state,
        errorModalOpen: action?.payload?.open,
        errorModalContent: action?.payload?.content,
      };

    case SET_NO_DATA_MODAL:
      return {
        ...state,
        noDataModalOpen: action?.payload?.open,
        noDataModalContent: action?.payload?.content,
      };

    case SET_DELETE_MODAL:
      return {
        ...state,
        deleteModalOpen: action.payload?.open,
        deleteModalContent: action.payload?.content,
      };

    default:
      return {
        state,
      };
  }
};

export const GlobalContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
