import React, { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import cn from "classnames";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface OptionProps {
  label: string | number;
  value: string | number;
  sort?: any;
}

interface SingleSelectProps {
  label?: string;
  options: OptionProps[];
  selectedOption?: OptionProps;
  name?: string;
  className?: string;
  onChange?: any;
  onBlur?: React.FocusEventHandler<HTMLDivElement> | undefined;
  title?: string;
  onChangeInput?: any;
  align?: string;
  zIdx?: string;
  divClassName?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

const SingleSelect = ({
  label = "",
  options = [],
  selectedOption,
  name = "",
  className,
  onChange,
  onBlur,
  align = "left",
  zIdx,
  divClassName,
  isLoading,
  disabled,
}: SingleSelectProps) => {
  const [selected, setSelected] = useState<OptionProps>();

  useEffect(() => {
    setSelected(selectedOption);
  }, [selectedOption]);

  const handleSelect = (e: any) => {
    setSelected(e);
    onChange && onChange(e);
  };

  return (
    <div
      className={cn("z-30 p-px", divClassName)}
      style={{ zIndex: zIdx ? zIdx : 30 }}
    >
      <Listbox
        as="div"
        className="space-y-1"
        value={selected}
        onChange={handleSelect}
        onBlur={onBlur}
        disabled={isLoading || disabled}
      >
        {({ open }) => {
          return (
            <>
              {label && (
                <Listbox.Label className="block text-jll-text-base-subdued mb-2">
                  {label}
                </Listbox.Label>
              )}
              <div
                className={cn("relative", {
                  "bg-jll-surface-utilities-disabled": isLoading || disabled,
                })}
              >
                <span className="inline-block w-full">
                  <Listbox.Button
                    className={`flex flex-row items-center justify-between text-sm space-x-2 w-full py-2.5 px-4 border border-jll-stroke-default rounded text-jll-text-base-default w-[200px] ${className} ${
                      open && ""
                    }`}
                  >
                    <span className="block truncate">
                      {selected?.label || ""}
                    </span>
                    <span className="flex items-center ml-2">
                      {open ? (
                        <ExpandLess className="!h-5 !w-5 text-jll-text-base-subdued" />
                      ) : (
                        <ExpandMore className="!h-5 !w-5 text-jll-text-base-subdued" />
                      )}
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-30"
                >
                  <Listbox.Options
                    static
                    className={cn(
                      'origin-top-right absolute max-h-60 min-w-[200px] w-full rounded-lg py-2.5 bg-white shadow-drop z-20 after:content-[""] after:absolute after:right-[10px] after:-top-[10px] after:border-[10px] after:border-solid after:border-x-transparent after:border-t-transparent after:border-t-0 after:border-b-white overflow-y-auto',
                      {
                        "right-0": align === "right",
                        "left-0": align !== "right",
                        "bottom-14": align === "top",
                      },
                    )}
                  >
                    {options?.map((option: any) => {
                      return (
                        <Listbox.Option key={option.value} value={option.value}>
                          {({ active }) => (
                            <div
                              className={cn(
                                `text-jll-text-base-default select-none flex justify-between items-center px-4 py-2`,
                                {
                                  "bg-jll-color-surface-accent bg-opacity-5":
                                    active,
                                  "bg-jll-surface-base-secondary-subdued":
                                    option.value === selected?.value,
                                },
                              )}
                            >
                              <div
                                className={`flex items-center whitespace-nowrap leading-6`}
                              >
                                {option.label}
                              </div>
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          );
        }}
      </Listbox>
    </div>
  );
};

export default SingleSelect;
