import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { GlobalContextProvider } from "context";
import { BrowserRouter } from "react-router-dom";
import "index.css";
import App from "App";
import { HelmetProvider } from "react-helmet-async";

const rootElement = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

rootElement.render(
  <HelmetProvider>
    <BrowserRouter>
      <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
    </BrowserRouter>
  </HelmetProvider>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//
reportWebVitals();
