import {
  CalculateOutlined,
  CalendarMonthOutlined,
  Close,
} from "@mui/icons-material";
import {
  Button,
  DatePicker,
  Input,
  Label,
  Select,
  Switch,
  Textarea,
} from "ui-atoms";
import { Modal } from "ui-molecules";
import { useContext, useEffect, useRef, useState } from "react";
import { useApiCall } from "hooks";
import { patchKeyDatesAPI, postKeyDateAPI } from "services";
import { SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getApiDate, getFormatedDate, getMetaOptions, getUTCDate } from "utils";
import { useParams } from "react-router-dom";

interface IKeyDateModal {
  isOpen: boolean;
  setIsOpen: any;
  content?: any;
  data?: any;
  setData?: any;
}

const INIT_OPTION = {
  type: "",
  custom_type: "",
  value_min: undefined,
  value_max: undefined,
  description: undefined,
};

const initValidationSchema = Yup.object().shape({
  type: Yup.string().required("This field is required"),
  value_min: Yup.string().optional().nullable(),
  description: Yup.string().optional().nullable(),
});

const KeyDateModal: React.FC<IKeyDateModal> = ({
  isOpen,
  setIsOpen,
  content,
  data,
  setData,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta } = state;
  const calcRef = useRef<any>(null);
  const [postKeyDate] = useApiCall(postKeyDateAPI);
  const [patchKeyDates] = useApiCall(patchKeyDatesAPI);
  const { closeoutId } = useParams();
  const [isRange, setIsRange] = useState(false);
  const [validationSchema, setValidationSchema] =
    useState(initValidationSchema);

  useEffect(() => {
    if (!isOpen) return;
    setTouched({}, false);
    if (!content) {
      setValues({ ...INIT_OPTION });
      setIsRange(false);
      return;
    }
    let formValues: any = {};
    Object.keys(INIT_OPTION).forEach((key: string) => {
      if (key === "type") {
        if (!content?.type?.length && !!content?.custom_type?.length) {
          formValues = {
            ...formValues,
            [key]: "Custom",
          };
          return;
        }
      }

      if (!!content?.value_max?.length) setIsRange(true);
      else setIsRange(false);

      formValues = {
        ...formValues,
        [key]: content?.[key] || "",
      };
    });
    setValues({ ...formValues });
  }, [content, isOpen]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
    setTouched,
  } = useFormik({
    initialValues: INIT_OPTION,
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Submitting",
          },
        });
        let payload = {};
        if (values?.type === "Custom") {
          payload = {
            custom_type: values.custom_type,
            type: null,
          };
        } else {
          payload = {
            type: values.type,
            custom_type: null,
          };
        }

        if (isRange) {
          payload = {
            ...payload,
            value_min: getApiDate(values.value_min),
            value_max: getApiDate(values.value_max),
          };
        } else {
          payload = {
            ...payload,
            value_min: getApiDate(values.value_min),
            value_max: null,
          };
        }

        payload = {
          ...payload,
          lease_closeout: closeoutId,
          description: values?.description,
        };

        if (!content) {
          const result = await postKeyDate(payload);
          if (result) {
            const new_data = [...data];
            new_data.push({ ...result, pk: result?.id });
            setData(new_data);
          }
        } else {
          const result = await patchKeyDates({
            id: content?.pk || content?.id,
            payload,
          });
          if (result) {
            const new_data = [...data];
            const index = new_data.findIndex(
              (item) => item?.id === content?.id || item?.id === content?.pk,
            );
            new_data[index] = {
              ...result,
              pk: result?.id,
            };
            setData(new_data);
          }
        }
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
        setIsOpen(false);
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      }
    },
  });

  useEffect(() => {
    let newValidationSchema;
    if (values?.type === "Custom") {
      newValidationSchema = Yup.object().shape({
        type: Yup.string().required("This field is required"),
        custom_type: Yup.string().required("This field is required"),
        value_min: Yup.string().optional().nullable(),
        description: Yup.string().optional().nullable(),
      });
    } else {
      newValidationSchema = Yup.object().shape({
        type: Yup.string().required("This field is required"),
        value_min: Yup.string().optional().nullable(),
        description: Yup.string().optional().nullable(),
      });
    }
    setValidationSchema(newValidationSchema);
  }, [values]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size="default"
      calcRef={calcRef}
    >
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            {!!content ? "Edit " : "Add "}Lease Option
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mt-6">
            <Select.Single
              options={getMetaOptions(meta?.leasecloseoutdates?.type) || []}
              label="Option Type"
              name="type"
              divClassName="w-full !mb-6"
              onBlur={handleBlur as any}
              onChange={(value: any) => {
                if (!value) {
                  setFieldValue("type", null);
                } else {
                  if (value === "custom") {
                    setFieldValue("type", null);
                  } else {
                    setFieldValue("type", value);
                    setFieldValue("custom_type", null);
                  }
                }
              }}
              selectedOption={
                values?.type
                  ? {
                      label:
                        meta?.leasecloseoutdates?.type?.[values?.type]?.value,
                      value: values?.type,
                    }
                  : undefined
              }
            />

            {values?.type === "Custom" && (
              <Input
                name="custom_type"
                label="Custom Option Type"
                onChange={handleChange}
                error={touched.custom_type ? errors.custom_type : ""}
                onBlur={handleBlur}
                value={values?.custom_type}
              />
            )}

            <div className="flex flex-row items-center justify-between">
              <Label className="mb-2">Notice Date</Label>
              <Button
                variant="neutral"
                leadingIcon={CalculateOutlined}
                size="small"
                leadingIconClass="text-jll-color-text-interaction"
                className="!text-jll-color-text-interaction"
                onClick={() => calcRef.current.openCalc()}
              >
                Date Calculator
              </Button>
            </div>
            <div className="flex flex-row items-center space-x-2.5">
              <DatePicker
                className="!mb-2 w-full"
                trailingIcon={CalendarMonthOutlined}
                onBlur={handleBlur}
                selected={
                  values?.value_min ? getUTCDate(values?.value_min) : null
                }
                error={touched.value_min ? errors.value_min : ""}
                value={
                  values?.value_min
                    ? getFormatedDate(getUTCDate(values?.value_min).toString())
                    : undefined
                }
                onChange={(date: Date) => setFieldValue("value_min", date)}
              />
              {isRange && (
                <>
                  <div className="border border-jll-stroke-default w-2.5 h-px mb-3" />
                  <DatePicker
                    className="!mb-2 w-full"
                    trailingIcon={CalendarMonthOutlined}
                    onBlur={handleBlur}
                    selected={
                      values?.value_max ? getUTCDate(values?.value_max) : null
                    }
                    error={touched.value_max ? errors.value_max : ""}
                    value={
                      values?.value_max
                        ? getFormatedDate(
                            getUTCDate(values?.value_max)?.toString(),
                          )
                        : undefined
                    }
                    onChange={(date: Date) => setFieldValue("value_max", date)}
                  />
                </>
              )}
            </div>
            <Switch
              enabled={isRange}
              onChange={setIsRange}
              label_2="Date Range"
              className="!mb-7"
            />
            <Textarea
              label="Descripton"
              name="description"
              placeholder=""
              className="mb-2"
              rows={5}
              onChange={handleChange}
              error={touched.description ? errors.description : ""}
              onBlur={handleBlur}
              value={values?.description}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="flex flex-row space-x-3 justify-end w-full">
            <Button
              variant="secondary"
              className="w-32"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="w-32"
              type="submit"
              disabled={!isValid || !dirty}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default KeyDateModal;
