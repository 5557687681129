import { getDecimalFormating, getFormatedDate } from "utils";

export const getDetailValue = (detail: any, valueKey: string, meta: any) => {
  switch (valueKey) {
    case "address":
      return (
        [
          detail?.building_address,
          detail?.building_city,
          detail?.building_state,
        ].join(", ") || ""
      );

    case "suite":
      let suiteLabel = "";
      detail?.spaces?.forEach((space: any) => {
        if (!space?.name && !space?.floor) return;
        if (!!suiteLabel?.length) suiteLabel += ", ";
        if (!!space?.name) {
          suiteLabel += `Suite ${space?.name}`;
          return;
        }
        if (!!space?.floor) {
          suiteLabel += `Floor ${space?.floor}`;
          return;
        }
      });
      return suiteLabel;

    case "total_size":
      if (!detail?.spaces?.length || !detail?.spaces?.[0]?.total_rsf) return "";
      return `${getDecimalFormating(detail?.spaces?.[0]?.total_rsf) || ""} RSF`;

    case "rent":
      if (!detail?.rent?.rent) return "";
      return `$${getDecimalFormating(detail?.rent?.rent, true)}/RSF/Year`;

    case "rent_type":
      if (!detail?.rent?.rent_type) return "";
      return meta?.leasecloseoutrentcalculator?.rent_type?.[
        detail?.rent?.rent_type
      ]?.value;

    case "free_rent":
      if (!detail?.rent?.free_rent) return "";
      return `${detail?.rent?.free_rent} months`;

    case "escalation":
      if (!detail?.rent?.escalation) return "";
      return `${detail?.rent?.escalation}%`;

    case "execution_date":
    case "expiration_date":
    case "commencement_date":
      if (!detail?.[valueKey]) return "";
      return getFormatedDate(detail?.[valueKey]);

    case "term":
      if (!detail?.rent?.term) return;
      return `${detail?.rent?.term || ""} months`;

    default:
      if (detail?.hasOwnProperty(valueKey)) {
        return detail?.[valueKey] || "";
      }
      return "";
  }
};
