import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getPropertiesAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/ms/property/?${param_str}`);
  return res;
};

export const getMapAPI = async (payload: any) => {
  const props = { ...payload };
  if (props?.id) delete props.id;
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(
    `${API_PREFIX_URL}/closeout/map/${payload?.id}/?${param_str}`,
  );
  return res;
};

export const postPropertyAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/building/ms/`, data);
  return res;
};
