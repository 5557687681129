import { useEffect, useState } from "react";
import queryString from "query-string";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Cancel, Error, LinkOff } from "@mui/icons-material";
import JllLogo from "assets/images/jll-logo.svg";

interface IStatusError {
  statusCode?: number;
}

const StatusError: React.FC<IStatusError> = ({ statusCode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState(Number(statusCode) || null);

  useEffect(() => {
    const query = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (query?.status) {
      setCode(Number(query?.status));
    }
  }, [location]);

  let ErrorIcon;
  let title = "";
  let description = "";
  switch (code) {
    case 403:
      ErrorIcon = Cancel;
      title = "Permission Denied";
      description = "Check your access permissions and try again";
      break;
    case 404:
      ErrorIcon = Error;
      title = "404";
      description = "The requested page URL was not found.";
      break;
    default:
      ErrorIcon = LinkOff;
      title = "Server Error";
      description = "Try to reload page or try bit later";
  }
  return (
    <div className="w-screen h-screen flex flex-col">
      <div className="mx-auto w-full px-4 sm:px-6 lg:px-8 border-b border-b-jll-stroke-subdued">
        <div className="flex h-20 justify-between">
          <div className="flex flex-shrink-0 items-center w-[200px] pr-7">
            <Link to="/" className="flex flex-row items-center">
              <img
                className="block h-7 w-auto relative mr-3"
                src={JllLogo}
                alt="Jll"
              />
              <div className="pl-4 border-l border-black">
                <span className="text-jll-text-base-default">My Leases</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <section className="w-full h-full flex justify-center items-center">
        <div className="flex flex-col items-center justify-center mt-8 mx-10 mb-12">
          <p className="text-[96px] font-light mb-6 leading-[108px]">{title}</p>
          <p className="text-[24px]">{description}</p>
        </div>
      </section>
    </div>
  );
};

export default StatusError;
