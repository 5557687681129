const styles: any = {
  page: {
    backgroundColor: "#00384D",
    color: "white",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: 612,
    height: 792,
    fontWeight: 400,
  },
  bgImage: {
    width: 270,
    height: "100%",
  },

  content: {
    view: {
      width: 342,
      height: "100%",
      paddingLeft: 54,
      paddingRight: 46,
      paddingBottom: 110,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      position: "relative",
    },

    header: {
      fontSize: 48,
      fontWeight: 300,
      lineHeight: 1.25,
      marginBottom: 50,
    },

    order: {
      view: {
        display: "flex",
        flexDirection: "row",
        alignItem: "center",
        justifyContent: "space-between",
        fontSize: 16,
        lineHeight: 1.5,
        paddingTop: 12,
        paddingBottom: 12,
        borderBottomColor: "#4C7382",
        textDecoration: "none",
      },
      label: {
        color: "white",
      },
      number: {
        color: "#EDE3D7",
      },
    },

    footer: {
      view: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 36,
        paddingLeft: 54,
        paddingRight: 46,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
      img: {
        height: 17,
        objectFit: "contain",
      },
    },
  },
};

export default styles;
