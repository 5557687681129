import { Image, Page, Text, View } from "@react-pdf/renderer";
import { PAGE_ORIENTATION, PAGE_SIZE } from "constant";
import LogoWhiteFull from "assets/images/pdf/v2/logo-white-full.png";
import SolarBgImg from "assets/images/pdf/v2/solar-white.png";
import styles from "./styles";
import { getFormatedDateWithMonth } from "utils";

interface IPageCoverV2 {
  detail: any;
}

const PageCoverV2: React.FC<IPageCoverV2> = ({ detail }) => {
  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.PORTRAIT}
      style={styles.page}
    >
      <View style={styles.content.view}>
        <View style={styles.content.back.view}>
          <Image src={SolarBgImg} style={styles.content.back.img} />
        </View>
        <View style={styles.content.section.view}>
          {!!detail && (
            <Text style={styles.content.section.header}>
              Lease{"\n"}Overview
            </Text>
          )}
          {!!detail?.client?.length && (
            <Text style={{ ...styles.content.section.title, marginBottom: 32 }}>
              {detail?.client}
            </Text>
          )}
          {(!!detail?.building_address?.length ||
            !!detail?.building_city?.length ||
            !!detail?.building_state?.length) && (
            <View
              style={{
                marginBottom: 32,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!!detail?.building_address?.length && (
                <Text style={styles.content.section.title}>
                  {detail?.building_address}
                </Text>
              )}
              {(!!detail?.building_city?.length ||
                !!detail?.building_state?.length) && (
                <Text style={styles.content.section.title}>
                  {[detail?.building_city, detail?.building_state].join(", ")}
                </Text>
              )}
            </View>
          )}
          {!!detail?.update_timestamp?.length && (
            <Text style={styles.content.section.updated_date}>
              {getFormatedDateWithMonth(detail?.update_timestamp)}
            </Text>
          )}
        </View>
      </View>
      <View style={styles.footer.view}>
        <Image src={LogoWhiteFull} style={styles.footer.img} />
      </View>
    </Page>
  );
};

export default PageCoverV2;
