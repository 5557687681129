import NoImage from "assets/images/noImage.png";

export const lazyLoadImageOnError = (
  event: React.SyntheticEvent<HTMLImageElement, Event>,
) => {
  event.currentTarget.onerror = null;
  event.currentTarget.src = NoImage;
};

// Get the image url from PDF url (need to change the extension)
export const convertExtension = (url: string = "") => {
  try {
    if (url?.toLowerCase()?.includes(".pdf")) {
      return url.replace(".pdf", ".png");
    } else if (url?.toLowerCase()?.includes(".ai")) {
      return url.replace(".ai", ".png");
    } else return url;
  } catch (err) {
    return "";
  }
};

export const checkFileType = (file: File): Promise<string | null> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    // Define the event listener for when the file is loaded
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const uintArray = new Uint8Array(arrayBuffer).subarray(0, 4);
      let header = "";

      // Convert the first 4 bytes of the file into a hexadecimal string
      for (let i = 0; i < uintArray.length; i++) {
        header += uintArray[i].toString(16);
      }
      // Check the file signature to determine its type
      switch (header) {
        case "89504e47":
          resolve("image/png");
          break;
        case "ffd8ffe0":
          resolve("image/jpeg");
          break;
        case "25504446":
          resolve("application/pdf");
          break;
        default:
          resolve(null); // Unknown file type
          break;
      }
    };

    // Read the first 4 bytes of the file
    reader.readAsArrayBuffer(file.slice(0, 4));
  });
};
