// @ts-ignore
import { default as ReactRangeSlider } from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./style.css";

interface IRangeSlider {
  [key: string]: any;
}

const RangeSlider: React.FC<IRangeSlider> = ({ ...props }) => {
  return (
    <ReactRangeSlider className="!bg-jll-color-colorGray-2 !h-0.5" {...props} />
  );
};

export default RangeSlider;
