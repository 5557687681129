import {
  AddOutlined,
  DeleteOutlineOutlined,
  DragIndicatorOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { Button } from "ui-atoms";
import {
  Footer,
  Loading,
  StyledSpaceValue,
  StyledSpaceValueProps,
  Table,
} from "ui-molecules";
import SpaceModal from "./SpaceModal";
import { useContext, useEffect, useRef, useState } from "react";
import { useApiCall } from "hooks";
import { deleteSpaceAPI, getSpacesAPI, putSpaceOrderAPI } from "services";
import { useParams } from "react-router-dom";
import { SEARCH_RESULT_LIMIT, SET_FULL_LOADING } from "constant";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import cn from "classnames";
import { getDecimalFormating, reorder } from "utils";
import { DirectionEnum } from "types";
import { GlobalContext } from "context";

interface TableColumnProps {
  id: StyledSpaceValueProps["valueKey"];
  label: string;
  sort?: string;
}

const SPACE_COLUMNS: TableColumnProps[] = [
  { label: "Floor", id: "floor" },
  { label: "Suite", id: "name" },
  { label: "Size", id: "rsf" },
  { label: "Floor plan", id: "floorplan" },
];

const SpaceDetail = () => {
  const { dispatch } = useContext(GlobalContext);
  const tableRef = useRef<any>(null);
  const [getSpaces, isLoading] = useApiCall(getSpacesAPI);
  const [putSpaceOrder] = useApiCall(putSpaceOrderAPI);
  const [deleteSpace] = useApiCall(deleteSpaceAPI);
  const { closeoutId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    setPage(1);
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
    getSpaces({
      lease_closeout: closeoutId,
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [1],
      sort: "sort",
      direction: DirectionEnum.asc,
    })
      .then((res: any) => {
        if (!res) {
          return;
        }
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
        setTotalSize(res?.facets?.total);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      });
  }, [closeoutId]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getSpaces({
      lease_closeout: closeoutId,
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [1],
      sort: "sort",
      direction: DirectionEnum.asc,
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any[]) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
      setTotalSize(res?.facets?.total);
    });
  };

  const onDeleteSpace = async (pk: number) => {
    try {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: true,
          label: "Deleting Space...",
        },
      });
      deleteSpace(pk)
        .then((res: any) => {
          const current_data = [...data]?.find((item) => item?.pk === pk);
          const new_data = [...data]?.filter((item) => item?.pk !== pk);
          setData(new_data);
          setTotalSize(totalSize - current_data?.rsf);
        })
        .finally(() => {
          dispatch({
            type: SET_FULL_LOADING,
            payload: {
              open: false,
              label: "",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_FULL_LOADING,
        payload: {
          open: false,
          label: "",
        },
      });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    try {
      const new_data = [...data];
      const newOrder = reorder(
        [...new_data],
        result.source.index,
        result.destination.index,
      );
      setData(newOrder);
      const sort = newOrder.map((order) => order?.pk);
      putSpaceOrder({ pk: closeoutId, sort }).catch((err: any) => {
        setData(new_data);
      });
    } catch (err) {}
  };

  return (
    <>
      <div className="relative w-full h-full">
        <section className="absolute top-0 left-0 right-0 bottom-[80px] pt-10 flex flex-col px-14">
          <div className="w-full flex flex-row items-center justify-between mb-6">
            <p className="text-jll-text-base-default text-2xl">Spaces</p>
            <Button
              variant="secondary"
              leadingIcon={AddOutlined}
              size="medium"
              onClick={() => {
                setIsOpen(true);
                setSelectedSpace(null);
              }}
            >
              Add New Space
            </Button>
          </div>
          {!!data?.length && (
            <div className="w-full flex flex-row items-center justify-between mb-6">
              <div className="text-jll-color-text-interaction bg-jll-color-surface-interactionSubdued rounded-full py-2 px-3 text-sm">
                Total Size (RSF): {getDecimalFormating(totalSize)}
              </div>
            </div>
          )}

          {!isLoading && !data?.length && <Table.Loading />}

          {!!data?.length && (
            <div className="h-full -mx-14 overflow-y-auto" ref={tableRef}>
              <Table className="mb-2">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th itemClassName="!pl-14" />
                    {SPACE_COLUMNS.map((column, idx) => (
                      <Table.Th key={idx}>{column.label}</Table.Th>
                    ))}
                    <Table.Th itemClassName="!pr-14" />
                  </Table.Tr>
                </Table.Thead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="space-reorder">
                    {(provided, snapshot) => (
                      <Table.Tbody
                        {...provided.droppableProps}
                        ref={provided?.innerRef}
                      >
                        {data?.map((item, idx) => (
                          <Draggable
                            key={idx}
                            draggableId={`space-item-${idx}`}
                            index={idx}
                          >
                            {(provided, snapshot) => (
                              <Table.Tr
                                key={idx}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={cn({
                                  "w-full table bg-white": snapshot.isDragging,
                                })}
                              >
                                <Table.Td className="!pl-14 w-10">
                                  <div
                                    className="w-6 h-6"
                                    {...provided.dragHandleProps}
                                  >
                                    <DragIndicatorOutlined className="!w-full !h-full text-jll-text-base-subdued" />
                                  </div>
                                </Table.Td>
                                {SPACE_COLUMNS.map((column, idx1) => (
                                  <Table.Td key={idx1}>
                                    <StyledSpaceValue
                                      space={item}
                                      valueKey={column?.id}
                                    />
                                  </Table.Td>
                                ))}
                                <Table.Td className="!pr-14 space-x-1 text-right w-20">
                                  <Button
                                    variant="neutral"
                                    kind="icon"
                                    leadingIcon={EditOutlined}
                                    onClick={() => {
                                      setIsOpen(true);
                                      setSelectedSpace(item);
                                    }}
                                  />
                                  <Button
                                    variant="neutral"
                                    kind="icon"
                                    leadingIcon={DeleteOutlineOutlined}
                                    onClick={() => onDeleteSpace(item?.pk)}
                                  />
                                </Table.Td>
                              </Table.Tr>
                            )}
                          </Draggable>
                        ))}
                      </Table.Tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>

              {!!(total > data?.length) && (
                <div
                  className="w-full py-2 flex justify-center items-center"
                  ref={sentryRef}
                >
                  <Loading />
                </div>
              )}
            </div>
          )}
        </section>
        <Footer />
      </div>
      <SpaceModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        content={selectedSpace}
        data={data}
        setData={setData}
        setTotalSize={setTotalSize}
      />
    </>
  );
};

export default SpaceDetail;
