export const reorder = (
  list: any[] = [],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const transformObject = (obj: any) => {
  if (!obj) return;
  const result = [];
  const keys = Object.keys(obj);
  const length = obj[keys?.[0]]?.length || 0;

  for (let i = 0; i < length; i++) {
    let newObj: any = {};
    keys.forEach((key) => {
      newObj[key] = obj[key][i];
    });
    result.push(newObj);
  }

  return result;
};
