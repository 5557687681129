import cn from "classnames";
import type { ReactDatePickerProps } from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import { Label } from "ui-atoms";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { Portal } from "react-overlays";
import { InfoOutlined } from "@mui/icons-material";
import { getApiDate, getUTCDate } from "utils";
import { forwardRef, useEffect, useRef, useState } from "react";

interface DatePickerProps {
  label?: string;
  error?: any;
  className?: string;
  pickerClassName?: string;
  pickerDivClassName?: string;
  optional?: boolean;
  trailingIcon?: any;
  trailingIconClass?: string;
  onTrailingClick?: any;
  onChange: any;
  selectsRange?: boolean;
  [key: string]: any;
}

const CalendarContainer = ({ children }: any) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

const CustomInput = forwardRef(({ value, onChange, ...props }: any, ref) => {
  const [date, setDate] = useState<any>("");

  useEffect(() => {
    setDate(getApiDate(value));
  }, [value]);

  const handleChange = (e: any) => {
    setDate(e?.target?.value);
  };

  const handleBlur = (e: any) => {
    if (!!onChange) {
      onChange(getUTCDate(date));
    }
  };

  return (
    <input
      type="date"
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      value={date}
      ref={ref}
    />
  );
});

const DatePicker: React.FC<DatePickerProps> = ({
  className,
  optional,
  label,
  error,
  pickerClassName,
  pickerDivClassName,
  trailingIcon,
  onTrailingClick,
  onChange,
  selectsRange,
  trailingIconClass,
  ...props
}) => {
  const inputRef = useRef(null);
  let TrailingIcon;
  if (trailingIcon) {
    TrailingIcon = trailingIcon;
  }
  const handleChangeRaw = (date: any) => {
    if (!selectsRange) {
      onChange(date);
      return;
    }
    const newRaw = new Date(date?.currentTarget?.value || date || "");
    if (newRaw instanceof Date && !isNaN(Date.parse(newRaw.toString()))) {
      onChange(newRaw);
    }
  };

  return (
    <div className={cn("relative z-20 mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? (
            <Label htmlFor={props.id} className="mr-2">
              {label}
            </Label>
          ) : (
            <span></span>
          )}
        </div>
      )}
      <div
        className={cn(
          "relative rounded-md w-full shadow-sm",
          pickerDivClassName,
          {
            "mt-2": !!label,
          },
        )}
      >
        <ReactDatePicker
          className={cn(
            "block w-full rounded py-2.5 focus:border-jll-surface-interaction-default focus:ring-jll-surface-interaction-default sm:text-sm text-jll-text-base-default placeholder:text-jll-text-base-subdued focus:text-jll-surface-interaction-default",
            {
              "border-jll-color-surface-accent-default": !!error,
              "border-jll-stroke-default": !error,
              "pr-10": !!trailingIcon,
            },
            pickerClassName,
          )}
          {...props}
          dateFormat="MM/dd/yyyy"
          popperContainer={CalendarContainer}
          customInput={!selectsRange && <CustomInput inputRef={inputRef} />}
          onChangeRaw={(e) => handleChangeRaw(e)}
          onChange={(date: any) => {
            onChange(date);
          }}
          selectsRange={selectsRange}
        />
        {trailingIcon && (
          <div
            className={cn(
              "absolute bottom-[2px] h-[40px] right-0 flex items-center pr-3",
              {
                "cursor-pointer": !!onTrailingClick,
                "pointer-events-none": !onTrailingClick,
              },
              trailingIconClass,
            )}
            onClick={!!onTrailingClick ? onTrailingClick : undefined}
          >
            <TrailingIcon className="h-5 w-5 text-gray-400" />
          </div>
        )}
      </div>

      {!!error && (
        <p className="absolute -bottom-7 text-jll-text-rag-danger-accessible flex items-center flex-row text-sm">
          <InfoOutlined className="!w-4 !h-4 mr-2 mt-0.5" />
          {error}
        </p>
      )}
    </div>
  );
};

export default DatePicker;
