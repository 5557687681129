import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getKeyDatesAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/closeout/dates/?${param_str}`);
  return res;
};

export const postKeyDateAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/closeout/dates/`, data);
  return res;
};

export const patchKeyDatesAPI = async ({ id, payload }: any) => {
  const res = await axios.patch(
    `${API_PREFIX_URL}/closeout/dates/${id}/`,
    payload,
  );
  return res;
};

export const deleteKeyDateAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/closeout/dates/${pk}/`);
  return res;
};
