import { Avatar } from "../../ui-atoms";
import { useContext, useEffect, useState } from "react";
import { useApiCall } from "../../hooks";
import { getDetailAPI } from "../../services";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { GlobalContext } from "context";

interface IFooter {
  className?: string;
}

function Footer({ className }: IFooter) {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { state } = useContext(GlobalContext);
  const { detailInfo } = state;
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [lastUpdatedUserName, setLastUpdatedUserName] = useState<any>(null);
  const [lastUpdatedDate, setLastUpdatedDate] = useState<any>(null);
  const [getDetail] = useApiCall(getDetailAPI);
  const { closeoutId } = useParams();

  useEffect(() => {
    if (!session || session?.id === currentUser?.value) return;
    setCurrentUser({
      label: session?.get_full_name || session?.email,
      value: session?.id,
    });
  }, [session]);

  useEffect(() => {
    if (!detailInfo) {
      setLastUpdatedDate(null);
      setLastUpdatedUserName(null);
      return;
    }
    setLastUpdatedDate(detailInfo?.update_timestamp);
    setLastUpdatedUserName(detailInfo?.updated_user_name);
  }, [detailInfo]);

  return (
    <>
      <section className="absolute bottom-0 left-0 right-0 h-[80px] bg-white border-t border-t-jll-stroke-subdued flex flex-row justify-between space-x-4 items-center px-14">
        <div className="text-sm text-jll-text-base-default flex flex-row items-center space-x-2">
          <span>Last updated by</span>
          <Avatar
            src={session?.photo}
            name={lastUpdatedUserName || ""}
            size="small"
          />
          <span>
            {lastUpdatedUserName} on{" "}
            {format(new Date(lastUpdatedDate), "MM/dd/yyyy hh:mma")}
          </span>
        </div>
      </section>
    </>
  );
}

export default Footer;
