import { MAX_ONCE_FILE_LIMIT, SET_RIGHT_CONTEXT } from "constant";
import { useDropzone } from "react-dropzone";
import { useContext, useState } from "react";
import { GlobalContext } from "context";
import "./dropzone.css";
import { FileCopyOutlined } from "@mui/icons-material";

interface IDropzone {
  acceptType?: any;
  dropFiles?: any[];
  setDropFiles?: any;
  setFileUrl?: any;
  className?: string;
  name?: string;
  submitImg?: any;
  icon?: any;
  children?: any;
  divClassName?: string;
  maxFileCount?: number;
}

const Dropzone: React.FC<IDropzone> = ({
  acceptType,
  setDropFiles,
  className,
  name,
  submitImg,
  setFileUrl,
  icon = FileCopyOutlined,
  children,
  divClassName = "",
  maxFileCount = MAX_ONCE_FILE_LIMIT,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { rightContextId } = state;
  const [url, setUrl] = useState("");
  const onDrop = (acceptedFiles: any[]) => {
    setDropFiles([...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFileCount,
    onDrop,
    multiple: true,
    ...acceptType,
  });

  const handleContextMenu = () => {
    dispatch({
      type: SET_RIGHT_CONTEXT,
      payload: {
        func: submitImg,
        id: name,
      },
    });
  };

  const handleFileUrl = () => {
    setFileUrl(url);
    setUrl("");
  };

  return (
    <div className={className}>
      <div
        {...getRootProps({
          className: `w-full flex h-full cursor-pointer bg-jll-color-surface-info-subdued border border-dashed border-jll-color-surface-info-secondary rounded justify-center items-center ${divClassName}`,
        })}
      >
        <input {...getInputProps()} />
        <div className="flex items-center justify-center">{children}</div>
      </div>
    </div>
  );
};

export default Dropzone;
