import { Button } from "ui-atoms";
import LogoImg from "assets/images/jll-logo.svg";
import { ArrowForwardOutlined } from "@mui/icons-material";

const LandingPage = () => {
  return (
    <div
      className={`w-full h-screen flex items-center justify-center bg-cover bg-[url('assets/images/bg-landingpage.png')]`}
    >
      <div className="bg-white shadow-landing rounded p-10 text-jll-text-base-default">
        <div className="flex flex-row items-center space-x-4 mb-8">
          <img src={LogoImg} className="h-8" />
          <span className="text-2xl">My Leases</span>
        </div>

        <div className="mb-10">
          <h1 className="text-3xl mb-4">Welcome back</h1>
          <p className="">
            Sign in to start sharing meaningful lease insights with your
            clients.
          </p>
        </div>

        <Button
          variant="primary"
          className="w-full"
          trailingIcon={ArrowForwardOutlined}
          onClick={() => {
            window.location.href = process.env.REACT_APP_BACKEND_AUTH_URL || "";
          }}
        >
          Sign in
        </Button>
      </div>
    </div>
  );
};

export default LandingPage;
