export const SET_TOOLTIP = "SET_TOOLTIP";
export const SET_CONFIRM_MODAL = "SET_CONFIRM_MODAL";
export const SET_META = "SET_META";
export const SET_RIGHT_CONTEXT = "SET_RIGHT_CONTEXT";
export const SET_PREVIEW_PDF = "SET_PREVIEW_PDF";
export const CREATE_LIGHTGALLERY = "CREATE_LIGHTGALLERY";
export const UPDATE_LIGHTGALLERY_DATA = "UPDATE_LIGHTGALLERY_DATA";
export const SET_FULL_LOADING = "SET_FULL_LOADING";
export const SET_DETAIL_INFO = "SET_DETAIL_INFO";
export const SET_CALC_REF = "SET_CALC_REF";
export const SET_ERROR_MODAL = "SET_ERROR_MODAL";
export const SET_NO_DATA_MODAL = "SET_NO_DATA_MODAL";
export const SET_DELETE_MODAL = "SET_DELETE_MODAL";

export const HTTP_ERROR_UNAUTHORIZED = 401;
export const HTTP_ERROR_BAD_REQUEST = 400;
export const HTTP_ERROR_WRITE_ACCESS = 403;
export const HTTP_ERROR_NOT_FOUND = 404;

export const PUBLISH_STATUS_PUBLISHED = 1;
export const PUBLISH_STATUS_DRAFT = 2;
export const PUBLISH_STATUS_REMOVED = 4;

export const STATUS_TO_DO = 1;
export const STATUS_IN_REVIEW = 2;
export const STATUS_READY = 3;

export const MAX_ONCE_FILE_LIMIT = 10;

export const SEARCH_RESULT_LIMIT = 10;

export const URLS = {
  ERROR: "error",
};

export * from "./helmet";
export * from "./api";
export * from "./package";
export * from "./detail";
export * from "./landing";
export * from "./pdf";
