import React, { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Checkbox } from "ui-atoms";
import cn from "classnames";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SingleSelect from "./SingleSelect";
import StatusSelect from "./StatusSelect";
import RoleSelect from "./RoleSelect";

interface SelectProps {
  label?: string;
  options: any;
  selectedOptions?: any;
  name?: string;
  className?: string;
  onChange?: any;
  onBlur?: React.FocusEventHandler<HTMLDivElement> | undefined;
  title?: string;
  onChangeInput?: any;
  searchWord?: string;
  align?: string;
  zIdx?: string;
  divClassName?: string;
  filters?: any;
  setFilters?: any;
  isMulti?: boolean;
}

const Select = ({
  label = "",
  options = [],
  selectedOptions = [],
  name = "",
  className,
  onChange,
  onBlur,
  title = "",
  align = "left",
  zIdx,
  divClassName,
  filters,
  setFilters,
  isMulti,
}: SelectProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paramFilters, setParamFilters] = useState<any>(null);

  useEffect(() => {
    if (filters) {
      setParamFilters(filters);
    } else {
      const newFilters = queryString.parse(location?.search, {
        arrayFormat: "bracket",
      });
      setParamFilters(newFilters);
    }
  }, [location, filters]);

  const isSelected = (value: any) => {
    if (paramFilters?.[value]) return true;
    return selectedOptions?.find((el: any) => el.value === value)
      ? true
      : false;
  };

  const handleFilter = (selectedOptions: any, name: string) => {
    const values =
      selectedOptions?.map(({ value }: any) => {
        return value;
      }) || [];
    let updatedFilters = {
      ...paramFilters,
      [name]: values,
    };
    updatedFilters = {
      ...updatedFilters,
    };
    if (setFilters) {
      setFilters(updatedFilters);
    } else
      navigate(
        `${location.pathname}?${queryString.stringify(updatedFilters, {
          arrayFormat: "bracket",
        })}`,
      );
  };

  const handleSelect = (value: any) => {
    if (!value) return;
    if (!isSelected(value)) {
      let selectedItemsUpdated = [];
      if (isMulti)
        selectedItemsUpdated = [
          ...selectedOptions,
          options.find((el: any) => el.value === value),
        ];
      else
        selectedItemsUpdated = [options.find((el: any) => el.value === value)];
      if (onChange) onChange(selectedItemsUpdated, name);
      else handleFilter(selectedItemsUpdated, name);
    } else {
      handleDeselect(value);
    }
  };

  const handleDeselect = (value: any) => {
    const selectedItemsUpdated = selectedOptions.filter(
      (el: any) => el.value !== value,
    );
    if (onChange) onChange(selectedItemsUpdated, name);
    else handleFilter(selectedItemsUpdated, name);
  };

  return (
    <div
      className={cn("z-30 p-px", divClassName)}
      style={{ zIndex: zIdx ? zIdx : 30 }}
    >
      <Listbox
        as="div"
        className="space-y-1"
        value={selectedOptions}
        onChange={handleSelect}
        onBlur={onBlur}
      >
        {({ open }) => {
          return (
            <>
              {label && (
                <Listbox.Label className="block text-jll-text-base-subdued mb-2">
                  {label}
                </Listbox.Label>
              )}
              <div className="relative">
                <span className="inline-block w-full">
                  <Listbox.Button
                    className={`flex flex-row items-center min-w-[200px] justify-between space-x-2 w-full py-2 px-4 border border-jll-stroke-default rounded text-jll-text-base-subdued w-[200px] ${className} ${
                      open && ""
                    }`}
                  >
                    <span className="block truncate">
                      {selectedOptions.length < 1
                        ? title
                        : `${title} (${selectedOptions.length})`}
                    </span>
                    <span className="flex items-center ml-2">
                      {open ? (
                        <ExpandLess className="!h-5 !w-5 text-jll-text-base-subdued" />
                      ) : (
                        <ExpandMore className="!h-5 !w-5 text-jll-text-base-subdued" />
                      )}
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-30"
                >
                  <Listbox.Options
                    static
                    className={cn(
                      'origin-top-right absolute max-h-60 min-w-[200px] w-full rounded-lg py-2 bg-white shadow-drop z-20 after:content-[""] after:absolute after:right-[10px] after:-top-[10px] after:border-[10px] after:border-solid after:border-x-transparent after:border-t-transparent after:border-t-0 after:border-b-white overflow-y-auto',
                      {
                        "right-0": align === "right",
                        "left-0": align !== "right",
                      },
                    )}
                  >
                    {options.map((option: any) => {
                      const selected = isSelected(option.value);
                      return (
                        <Listbox.Option key={option.value} value={option.value}>
                          {({ active }) => (
                            <div
                              className={cn(
                                `text-jll-text-base-default select-none flex justify-between items-center px-4 py-2`,
                                {
                                  "bg-jll-color-surface-accent bg-opacity-5":
                                    active,
                                  "bg-jll-surface-base-secondary-subdued":
                                    selected,
                                },
                              )}
                            >
                              <div
                                className={`flex items-center whitespace-nowrap leading-6`}
                              >
                                {option.label}
                              </div>
                              {!!isMulti && (
                                <div className="flex items-center pl-3">
                                  <Checkbox
                                    checked={selected}
                                    id={""}
                                    boxSize="small"
                                    onChange={(e: any) => {
                                      if (e.target.checked)
                                        handleSelect(option.value);
                                      else handleDeselect(option.value);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          );
        }}
      </Listbox>
    </div>
  );
};

Select.Single = SingleSelect;
Select.Status = StatusSelect;
Select.Role = RoleSelect;

export default Select;
