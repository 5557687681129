import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getDetailAPI = async (id: string | number) => {
  const res = await axios.get(`${API_PREFIX_URL}/closeout/${id}/`);
  return res;
};

export const patchDetailAPI = async ({ id, payload }: any) => {
  const res = await axios.patch(`${API_PREFIX_URL}/closeout/${id}/`, payload);
  return res;
};

export const getBuildingsAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/building/jll/?${param_str}`);
  return res;
};

export const getGroupAPI = async (id: string | number) => {
  const res = await axios.get(`${API_PREFIX_URL}/closeout/group/${id}/`);
  return res;
};

export const postGroupAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/closeout/group/`, data);
  return res;
};

export const patchGroupAPI = async ({ id, payload }: any) => {
  const res = await axios.patch(
    `${API_PREFIX_URL}/closeout/group/${id}/`,
    payload,
  );
  return res;
};

export const deleteGroupAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/closeout/group/${pk}/`);
  return res;
};

export const postContactAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/closeout/contact/`, data);
  return res;
};

export const patchContactAPI = async ({ id, payload }: any) => {
  const res = await axios.patch(
    `${API_PREFIX_URL}/closeout/contact/${id}/`,
    payload,
  );
  return res;
};

export const deleteContactAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/closeout/contact/${pk}/`);
  return res;
};

export const getAccessAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/asa/user/?${param_str}`);
  return res;
};

export const getContactUsersAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/user/?${param_str}`);
  return res;
};

export const postUserAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/closeout/user/`, data);
  return res;
};

export const patchContactUserAPI = async ({ id, payload }: any) => {
  const res = await axios.patch(`${API_PREFIX_URL}/user/meta/${id}/`, payload);
  return res;
};

export const deleteContactUserAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/closeout/user/${pk}/`);
  return res;
};

export const putUserOrderAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.put(
    `${API_PREFIX_URL}/closeout/user/order/${pk}/`,
    payload,
  );
  return res;
};

export const putGroupOrderAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.put(
    `${API_PREFIX_URL}/closeout/contact/group/order/${pk}/`,
    payload,
  );
  return res;
};

export const putContactOrderAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.put(
    `${API_PREFIX_URL}/closeout/contact/order/${pk}/`,
    payload,
  );
  return res;
};
