import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext } from "react";
import { deleteUserGroupAPI, postUserGroupAPI } from "services";
import { Select } from "ui-atoms";

export interface StyledRoleValueProps {
  user: any;
  valueKey: string;
}

const StyledRoleValue: React.FC<StyledRoleValueProps> = ({
  user,
  valueKey,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [postUserGroup] = useApiCall(postUserGroupAPI);
  const [deleteUserGroup] = useApiCall(deleteUserGroupAPI);

  const onChangeRole = (user: any, groups: any[]) => {
    const userGroups = user?.group || [];
    const updateGroups = [...groups]?.map((x) => x.value) || [];
    const deleteGroups =
      [...userGroups]?.filter((x) => {
        return !updateGroups.includes(x);
      }) || [];

    updateGroups?.forEach((group) => {
      postUserGroup({
        user: user.pk,
        group,
      });
    });

    deleteGroups?.forEach((group) => {
      deleteUserGroup({
        user: user.pk,
        group,
      });
    });
  };

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "name":
            return (
              <>
                {user?.["display_name"] ||
                  [user?.["first_name"], user?.["last_name"]]?.join(" ") ||
                  ""}
              </>
            );

          case "role":
            return (
              <Select.Role
                options={
                  [...(meta?.user_group || [])]?.map((x: any) => ({
                    value: x?.id,
                    label: x?.name,
                  })) || []
                }
                selectedOptions={
                  user?.group?.map((groupId: number, index: number) => ({
                    value: groupId,
                    label: user?.group_name?.[index] || "",
                  })) || []
                }
                onChange={(options: any) => onChangeRole(user, options)}
              />
            );

          default:
            if (user?.hasOwnProperty(valueKey)) {
              return <>{user?.[valueKey] || ""}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledRoleValue;
