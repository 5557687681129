const styles: any = {
  view: {
    height: "100%",
    width: "100%",
  },
  title: {
    color: "#01151C",
    fontSize: 24,
    paddingBottom: 44,
    backgroundColor: "#F6F9FA",
    paddingLeft: 54,
    paddingRight: 54,
  },

  header: {
    view: {
      minHeight: 100,
      backgroundColor: "#F6F9FA",
      paddingLeft: 54,
      paddingRight: 54,
      marginBottom: 35,
    },
    date: {
      view: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
      item: {
        view: {
          minWidth: 80,
          color: "#01151C",
        },
        date: {
          fontSize: 16,
          lineHeight: 1.75,
        },
        label: {
          fontSize: 10,
        },
      },
    },
    progress: {
      view: {
        display: "flex",
        flexDirection: "row",
        height: 3,
        width: "100%",
        marginBottom: 17,
      },
    },
  },

  section: {
    view: {
      paddingLeft: 54,
      paddingRight: 54,
      display: "flex",
      flexDirection: "column",
      marginBottom: 20,
    },
    date: {
      fontSize: 16,
      color: "#01151C",
      textAlign: "right",
    },
    description: {
      view: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: 10,
      },
      label: {
        fontSize: 16,
        color: "#01151C",
        lineHeight: 1.5,
      },
      value: {
        fontSize: 10,
        color: "#667579",
        lineHeight: 1.4,
        maxWidth: 380,
      },
    },
  },
};

export default styles;
