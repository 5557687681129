import cn from "classnames";

interface ModalFooterProps {
  className?: string;
  children: any;
}

function ModalFooter({ className, children }: ModalFooterProps) {
  return (
    <div className={cn("mt-5 sm:mt-6 flex space-x-2 justify-end", className)}>
      {children}
    </div>
  );
}

export default ModalFooter;
