import React, { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Button, Checkbox, Input } from "ui-atoms";
import cn from "classnames";
import { CloseOutlined, ExpandLess, ExpandMore } from "@mui/icons-material";
import { OptionProps } from "types";

interface RoleSelectProps {
  label?: string;
  options: any;
  selectedOptions?: OptionProps[];
  name?: string;
  className?: string;
  onChange?: any;
  onBlur?: React.FocusEventHandler<HTMLDivElement> | undefined;
  title?: string;
  onChangeInput?: any;
  searchWord?: string;
  align?: string;
  zIdx?: string;
  divClassName?: string;
}

const RoleSelect = ({
  label = "",
  options = [],
  selectedOptions = [],
  name = "",
  className,
  onChange,
  onBlur,
  title = "",
  align = "left",
  zIdx,
  divClassName,
}: RoleSelectProps) => {
  const [selected, setSelected] = useState<OptionProps[]>([]);

  useEffect(() => {
    setSelected(selectedOptions);
  }, []);

  const isSelected = (value: any) => {
    return selected?.find((el: any) => el.value === value) ? true : false;
  };

  const handleSelect = (value: any) => {
    if (!isSelected(value)) {
      let selectedItemsUpdated = [];
      selectedItemsUpdated = [
        ...selected,
        options.find((el: any) => el.value === value),
      ];
      setSelected(selectedItemsUpdated);
      if (onChange) onChange(selectedItemsUpdated);
    } else {
      handleDeselect(value);
    }
  };

  const handleDeselect = (value: any, e: any = null) => {
    e?.stopPropagation();
    const selectedItemsUpdated = selected.filter(
      (el: any) => el.value !== value,
    );
    setSelected(selectedItemsUpdated);
    if (onChange) onChange(selectedItemsUpdated);
  };

  return (
    <div
      className={cn("z-30 p-px", divClassName)}
      style={{ zIndex: zIdx ? zIdx : 30 }}
    >
      <Listbox
        as="div"
        className="space-y-1"
        value={selectedOptions}
        onChange={handleSelect}
        onBlur={onBlur}
      >
        {({ open }) => {
          return (
            <>
              {label && (
                <Listbox.Label className="block text-jll-text-base-subdued mb-2">
                  {label}
                </Listbox.Label>
              )}
              <div className="relative">
                <span className="inline-block w-full">
                  <Listbox.Button
                    className={`flex flex-row items-center min-w-[200px] min-h-[44px] justify-between space-x-2 w-full py-1.5 px-4 border border-jll-stroke-default rounded text-jll-text-base-subdued w-[200px] ${className} ${
                      open && ""
                    }`}
                  >
                    <div className="flex flex-row flex-wrap truncate space-x-1">
                      {selected.map((item, idx) => (
                        <div
                          className={cn(
                            "px-3 py-1.5 flex flex-row items-center space-x-1 text-jll-text-base-default rounded-full",
                            {
                              "bg-jll-color-surface-warningSubdued":
                                item?.value === 1,
                              "bg-jll-color-surface-interactionSubdued":
                                item?.value !== 1,
                            },
                          )}
                          key={idx}
                        >
                          <span className="text-sm">{item?.label}</span>
                          <CloseOutlined
                            className="!w-4 !h-4"
                            onClick={(e) => handleDeselect(item?.value, e)}
                          />
                        </div>
                      ))}
                    </div>
                    <span className="flex items-center ml-2">
                      {open ? (
                        <ExpandLess className="!h-5 !w-5 text-jll-text-base-subdued" />
                      ) : (
                        <ExpandMore className="!h-5 !w-5 text-jll-text-base-subdued" />
                      )}
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-30"
                >
                  <Listbox.Options
                    static
                    className={cn(
                      'origin-top-right absolute max-h-60 min-w-[200px] w-full rounded-lg py-2 bg-white shadow-drop z-20 after:content-[""] after:absolute after:right-[10px] after:-top-[10px] after:border-[10px] after:border-solid after:border-x-transparent after:border-t-transparent after:border-t-0 after:border-b-white overflow-y-auto',
                      {
                        "right-0": align === "right",
                        "left-0": align !== "right",
                      },
                    )}
                  >
                    {options.map((option: any) => {
                      const selected = isSelected(option.value);
                      return (
                        <Listbox.Option key={option.value} value={option.value}>
                          {({ active }) => (
                            <div
                              className={cn(
                                `text-jll-text-base-default select-none flex justify-between items-center px-4 py-2`,
                                {
                                  "bg-jll-color-surface-accent bg-opacity-5":
                                    active,
                                },
                              )}
                            >
                              <div
                                className={`flex items-center whitespace-nowrap leading-6`}
                              >
                                {option.label}
                              </div>
                              <div className="flex items-center pl-3">
                                <Checkbox
                                  checked={selected}
                                  id={""}
                                  boxSize="small"
                                  onChange={(e: any) => {
                                    if (e.target.checked)
                                      handleSelect(option.value);
                                    else handleDeselect(option.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          );
        }}
      </Listbox>
    </div>
  );
};

export default RoleSelect;
