import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import JllLogo from "assets/images/jll-logo.svg";
import { Avatar, Input } from "ui-atoms";
import {
  ExpandLess,
  ExpandMore,
  OutdoorGrill,
  Search,
} from "@mui/icons-material";
import { useApiCall } from "hooks";
import { logoutApi } from "services";
import NavbarDetail from "./NavbarDetail";
import debounce from "lodash.debounce";

interface INavbar {
  className?: string;
}

function Navbar<T extends object>({ className }: INavbar) {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [logout] = useApiCall(logoutApi);
  const [keyword, setKeyword] = useState<string>();

  useEffect(() => {
    setKeyword(searchParams?.get("keyword") || "");
  }, []);

  useEffect(() => {
    if (!session || session?.id === currentUser?.value) return;
    setCurrentUser({
      label: session?.get_full_name || session?.email,
      value: session?.id,
    });
  }, [session]);

  const onClickLogout = () => {
    logout().then((data: any) => {
      window.location.href = data?.redirect;
    });
  };

  const updateKeywordFilter = (e: any) => {
    if (!!e?.target?.value?.length) {
      searchParams.set("keyword", e?.target?.value || "");
      setSearchParams(searchParams);
    } else {
      searchParams.delete("keyword");
      setSearchParams(searchParams);
    }
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [searchParams],
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const isAdmin =
    session?.is_superuser ||
    session?.is_staff ||
    !!session?.groups?.find((group: any) => group?.id === 1);

  return (
    <>
      <Disclosure
        as="nav"
        className={cn(
          "bg-white border-b border-b-jll-stroke-subdued fixed top-0 left-0 w-full z-20",
        )}
      >
        {({ open }) => (
          <>
            <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
              <div className="flex h-20 justify-between">
                <div className="flex flex-row w-full">
                  <div className="flex flex-shrink-0 items-center w-[200px] pr-7">
                    <Link to="/" className="flex flex-row items-center">
                      <img
                        className="block h-7 w-auto relative mr-3"
                        src={JllLogo}
                        alt="Jll"
                      />
                      <div className="pl-4 border-l border-black">
                        <span className="text-jll-text-base-default">
                          My Leases
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="w-full px-8 flex flex-row items-center">
                    <Input
                      name={""}
                      className="w-full !mb-0"
                      leadingIcon={Search}
                      placeholder="Search"
                      value={keyword}
                      onChange={onChangeInput}
                    />
                  </div>
                  <div className="flex flex-row items-center pl-8">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                      {({ open }) => (
                        <>
                          <div className="flex space-x-2 items-center">
                            <Menu.Button className="flex bg-white ">
                              <div className="text-jll-text-base-default flex flex-row items-center space-x-2">
                                <Avatar
                                  src={""}
                                  name={session?.get_full_name || ""}
                                />
                                {open ? (
                                  <ExpandLess className="text-lg text-jll-text-base-subdued" />
                                ) : (
                                  <ExpandMore className="text-lg text-jll-text-base-subdued" />
                                )}
                              </div>
                            </Menu.Button>
                          </div>
                          {open && (
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right divide-y divide-jll-stroke-subdued rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  {isAdmin && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          onClick={() =>
                                            navigate(`/role-management`)
                                          }
                                          className={cn(
                                            "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                            { "bg-gray-100": active },
                                          )}
                                        >
                                          Role Management
                                        </button>
                                      )}
                                    </Menu.Item>
                                  )}
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={onClickLogout}
                                        className={cn(
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                          { "bg-gray-100": active },
                                        )}
                                      >
                                        Sign Out
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          )}
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </>
  );
}

Navbar.Detail = NavbarDetail;

export default Navbar;
