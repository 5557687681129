import { LazyLoadImage } from "react-lazy-load-image-component";
import { SpaceProps } from "types";
import { getDecimalFormating } from "utils";

export interface StyledSpaceValueProps {
  space: SpaceProps;
  valueKey: keyof SpaceProps;
}

const StyledSpaceValue: React.FC<StyledSpaceValueProps> = ({
  space,
  valueKey,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "floorplan":
            if (!space?.[valueKey]?.length) return <></>;
            return (
              <div className="border border-jll-stroke-default rounded w-[46px] h-[46px]">
                <LazyLoadImage
                  src={space?.[valueKey]?.[0]}
                  className="w-full h-full object-contain rounded"
                />
              </div>
            );

          case "rsf":
            return <>{getDecimalFormating(space?.rsf) || ""}</>;

          default:
            if (space?.hasOwnProperty(valueKey)) {
              return <>{space?.[valueKey] || ""}</>;
            }
            return <></>;
        }
      })()}
    </>
  );
};

export default StyledSpaceValue;
