import { CalendarMonthOutlined, Close } from "@mui/icons-material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, DatePicker } from "ui-atoms";
import cn from "classnames";
import DateCalc from "./DateCalc";
import DurationCalc from "./DurationCalc";

interface ICalculatorModal {
  setOpened?: any;
}

const TAB_LIST = [
  { label: "Calculate Date", value: "date" },
  { label: "Calculate Duration", value: "duration" },
];

const CalculatorModal = forwardRef(
  ({ setOpened }: ICalculatorModal, rootRef) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(TAB_LIST[0].value);
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setIsFirst(false);
      }, 500);
    }, []);

    useImperativeHandle(rootRef, () => ({
      openCalc() {
        setIsOpen(true);
      },
      closeCalc() {
        setIsOpen(false);
      },
    }));

    useEffect(() => {
      setOpened && setOpened(isOpen);
    }, [isOpen]);

    return (
      <div
        className={cn(
          "fixed bottom-[120px] z-40 w-full max-w-[450px] h-full max-h-[500px] bg-jll-surface-base-secondary-subdued shadow-calc",
          {
            "animate-open right-6": isOpen,
            "animate-close -right-[450px]": !isOpen,
            "!-right-[450px]": isFirst,
          },
        )}
      >
        <div className="relative p-6 w-full h-full">
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            leadingIconClass="text-jll-text-base-subdued"
            size="large"
            className="absolute top-3 right-3"
          />
          <p className="text-jll-text-base-default leading-8 mb-8">
            Date Calculator
          </p>

          <div className="border-b border-b-jll-stroke-default space-x-5 pb-4">
            {TAB_LIST.map((tab, idx) => (
              <span
                key={idx}
                className={cn(
                  "leading-6 text-jll-text-base-subdued pb-[18px] cursor-pointer",
                  {
                    "!text-jll-text-base-default border-b border-b-black":
                      selectedTab === tab.value,
                  },
                )}
                onClick={() => setSelectedTab(tab.value)}
              >
                {tab.label}
              </span>
            ))}
          </div>

          {/* Date Section */}
          {selectedTab === TAB_LIST[0].value && <DateCalc />}
          {selectedTab === TAB_LIST[1].value && <DurationCalc />}
        </div>
      </div>
    );
  },
);

export default CalculatorModal;
