import cn from "classnames";
import { Navbar, Sidebar } from "ui-molecules";
import LayoutBase from "../LayoutBase";

interface ILayoutProps {
  children: any;
  className?: string;
  detail?: any;
}

function LayoutDetail<T extends object>({
  children,
  className,
  detail,
}: ILayoutProps) {
  return (
    <LayoutBase>
      <div className={cn("w-full relative", className)}>
        <Navbar.Detail />
        <Sidebar />
        <div className="fixed top-[80px] left-[232px] right-0 bottom-0">
          {children}
        </div>
      </div>
    </LayoutBase>
  );
}

export default LayoutDetail;
