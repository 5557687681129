import PageLayoutV2 from "../PageLayoutV2";
import ContactBg from "assets/images/pdf/v2/contact_bg.png";
import styles from "./styles";
import { View, Text } from "@react-pdf/renderer";

interface IPageAdditionalContactV2 {
  detail?: any;
  pageNumber?: number;
  id?: string;
  contacts?: any;
}

const PageAdditionalContactV2: React.FC<IPageAdditionalContactV2> = ({
  detail,
  pageNumber,
  id,
  contacts,
}) => {
  return (
    <PageLayoutV2
      pageNumber={pageNumber}
      id={id}
      detail={detail}
      bgImg={ContactBg}
    >
      <View style={styles.view}>
        <Text style={styles.title}>Additional Contacts</Text>
        <View style={styles.content.view}>
          {Object.keys(contacts)?.map((key: any, idx: number) => (
            <View key={idx} style={styles.content.group.view}>
              {!!contacts?.[key]?.name?.length && (
                <Text style={styles.content.group.title}>
                  {contacts?.[key]?.name}
                </Text>
              )}
              {contacts?.[key]?.contacts?.map(
                (rowContact: any, idx1: number) => (
                  <View
                    key={idx1}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {!!rowContact?.rows?.[0] && (
                      <View style={styles.content.group.item.view}>
                        {!!rowContact?.company1?.length && (
                          <Text style={styles.content.group.item.companyName}>
                            {rowContact?.company1}
                          </Text>
                        )}
                        <View>
                          <Text style={styles.content.group.item.label}>
                            {rowContact?.rows?.[0]?.contact_name || ""}
                          </Text>
                          <Text style={styles.content.group.item.label}>
                            {rowContact?.rows?.[0]?.phone || ""}
                          </Text>
                          <Text style={styles.content.group.item.label}>
                            {rowContact?.rows?.[0]?.email || ""}
                          </Text>
                        </View>
                      </View>
                    )}
                    {!!rowContact?.rows?.[1] && (
                      <View style={styles.content.group.item.view}>
                        {!!rowContact?.company2?.length && (
                          <Text style={styles.content.group.item.companyName}>
                            {rowContact?.company2 || ""}
                          </Text>
                        )}
                        <View
                          style={{
                            marginTop:
                              !!rowContact?.company1?.length &&
                              !rowContact?.company2?.length
                                ? 16
                                : 0,
                          }}
                        >
                          <Text style={styles.content.group.item.label}>
                            {rowContact?.rows?.[1]?.contact_name || ""}
                          </Text>
                          <Text style={styles.content.group.item.label}>
                            {rowContact?.rows?.[1]?.phone || ""}
                          </Text>
                          <Text style={styles.content.group.item.label}>
                            {rowContact?.rows?.[1]?.email || ""}
                          </Text>
                        </View>
                      </View>
                    )}
                  </View>
                ),
              )}
            </View>
          ))}
        </View>
      </View>
    </PageLayoutV2>
  );
};

export default PageAdditionalContactV2;
