import { Avatar } from "ui-atoms";

export interface StyledContactValueProps {
  contact: any;
  valueKey: string;
}

const StyledContactValue: React.FC<StyledContactValueProps> = ({
  contact,
  valueKey,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "name":
            return (
              <div className="flex flex-row items-center space-x-3">
                <Avatar
                  src={contact?.headshot}
                  size="middle"
                  name={
                    contact?.display_name ||
                    contact?.name ||
                    [contact?.first_name, contact?.last_name]?.join(" ") ||
                    ""
                  }
                />
                <p>
                  {contact?.display_name ||
                    contact?.name ||
                    [contact?.first_name, contact?.last_name]?.join(" ") ||
                    ""}
                </p>
              </div>
            );

          default:
            if (contact?.hasOwnProperty(valueKey)) {
              return (
                <p className="whitespace-normal line-clamp-2">
                  {contact?.[valueKey] || ""}
                </p>
              );
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledContactValue;
