import cn from "classnames";
import { useEffect, useState } from "react";
import DealTeam from "./DealTeam";
import MainOffice from "./MainOffice";
import AdditionalContact from "./AdditionalContact";
import { Footer } from "ui-molecules";
import { useSearchParams } from "react-router-dom";

const CONTACT_TAB_LIST = [
  { label: "Deal Team", value: "deal_team" },
  { label: "JLL Office", value: "jll_office" },
  { label: "Additional Contacts", value: "additional_contact" },
];

const ContactDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [subActiveTab, setSubActiveTab] = useState(CONTACT_TAB_LIST[0].value);

  useEffect(() => {
    setSubActiveTab(searchParams?.get("sub") || CONTACT_TAB_LIST[0].value);
  }, [searchParams]);

  return (
    <div className="relative w-full h-full">
      <section className="absolute top-0 left-0 right-0 bottom-[80px] pt-10 flex flex-col px-14">
        <p className="text-jll-text-base-default text-2xl mb-8">Contacts</p>
        <div className="border-b border-b-jll-stroke-default flex flex-row">
          {CONTACT_TAB_LIST.map((tab, idx) => (
            <div
              className={cn("mr-10 pb-6 cursor-pointer", {
                "text-jll-text-base-default border-b border-b-jll-text-base-default":
                  subActiveTab === tab.value,
                "text-jll-text-base-subdued": subActiveTab !== tab.value,
              })}
              key={idx}
              onClick={() => {
                searchParams.set("sub", tab.value);
                setSearchParams(searchParams);
              }}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className="h-full -mx-14 px-14 overflow-y-auto">
          {subActiveTab === CONTACT_TAB_LIST[0].value && <DealTeam />}
          {subActiveTab === CONTACT_TAB_LIST[1].value && <MainOffice />}
          {subActiveTab === CONTACT_TAB_LIST[2].value && <AdditionalContact />}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactDetail;
