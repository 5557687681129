import { Image, Link, Page, Text, View } from "@react-pdf/renderer";
import { PAGE_ORIENTATION, PAGE_SIZE } from "constant";
import GettyBgImg from "assets/images/pdf/v2/getty.png";
import LogoWhiteImg from "assets/images/pdf/v2/logo-white.png";
import styles from "./styles";

interface IPageContentV2 {
  pageNumberList?: any;
}

const PageContentV2: React.FC<IPageContentV2> = ({ pageNumberList }) => {
  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.PORTRAIT}
      style={styles.page}
    >
      <View style={styles.content.view}>
        <Text style={styles.content.header}>Table of{"\n"}Contents</Text>
        {!!pageNumberList?.length &&
          pageNumberList?.map((item: any, idx: number) => (
            <View>
              <Link
                src={`#Page_Item_${item?.id}`}
                style={{
                  ...styles.content.order.view,
                  borderBottomWidth: idx === pageNumberList?.length - 1 ? 0 : 1,
                }}
                key={idx}
              >
                <Text style={styles.content.order.label}>{item?.label}</Text>
                <Text style={styles.content.order.number}>{item?.number}</Text>
              </Link>
            </View>
          ))}
        <View style={styles.content.footer.view}>
          <Image src={LogoWhiteImg} style={styles.content.footer.img} />
        </View>
      </View>
      <Image src={GettyBgImg} style={styles.bgImage} />
    </Page>
  );
};

export default PageContentV2;
