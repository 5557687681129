import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const postCollaboratorAPI = async (data: APIProps) => {
  const res = await axios.post(`${API_PREFIX_URL}/closeout/access/`, data);
  return res;
};

export const deleteCollaboratorAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/closeout/access/${pk}/`);
  return res;
};
