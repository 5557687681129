import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getDeliverableAPI = async (id: string | number) => {
  const res = await axios.get(`${API_PREFIX_URL}/closeout/deliverable/${id}/`);
  return res;
};

export const postDeliverableAPI = async ({ payload }: any) => {
  const res = await axios.post(
    `${API_PREFIX_URL}/closeout/deliverable/`,
    payload,
  );
  return res;
};

export const patchDeliverableAPI = async ({ id, payload }: any) => {
  const res = await axios.patch(
    `${API_PREFIX_URL}/closeout/deliverable/${id}/`,
    payload,
  );
  return res;
};

export const postFileAPI = async ({ id, docId, payload }: any) => {
  const res = await axios.post(
    `${API_PREFIX_URL}/closeout/files/${id}/?doc_id=${docId}`,
    payload,
  );
  return res;
};
