import { FilteredFacetChildrenProps } from "types";

interface MetaValue {
  value: string;
  sort: number;
}
interface MetaProps {
  [key: string]: MetaValue;
}

export const getMetaOptions = (meta: MetaProps[]) => {
  if (!meta) {
    return [];
  }
  const options = Object.keys(meta).map((key) => {
    return {
      value: key,
      // @ts-ignore need to fix typing issue
      label: meta[key].value,
      // @ts-ignore need to fix typing issue
      sort: meta[key].sort,
    };
  });
  options.sort((a, b) => a.sort - b.sort);
  return options;
};

export const getMetaNumOptions = (meta: MetaProps[]) => {
  if (!meta) {
    return [];
  }
  const options = Object.keys(meta).map((key) => {
    return {
      value: Number(key),
      // @ts-ignore need to fix typing issue
      label: meta[key].value,
      // @ts-ignore need to fix typing issue
      sort: meta[key].sort,
    };
  });
  options.sort((a, b) => a.sort - b.sort);
  return options;
};

export const getContactTypeId = (meta: any, type: string) => {
  let contentTypeIds: number[] = [meta?.content_type?.[type]?.id] || [];
  return contentTypeIds;
};

export const getStateOptions = (meta: any) => {
  if (!meta) {
    return [];
  }
  const options = Object.keys(meta?.building?.state)?.map((key: string) => {
    return {
      ...meta?.building?.state[key],
      label: meta?.STATES_NAME_MAP?.[key] || meta?.building?.state[key]?.value,
    };
  });
  options.sort((a: MetaValue, b: MetaValue) => a.sort - b.sort);
  return options;
};

export const getFacetStateOptions = (
  meta: any,
  facets: FilteredFacetChildrenProps[],
) => {
  if (!meta) {
    return [];
  }
  const options = facets?.map((facet: FilteredFacetChildrenProps) => {
    return {
      ...facet,
      label: meta?.STATES_NAME_MAP?.[facet?.value] || facet?.label,
    };
  });
  options.sort(
    (a: FilteredFacetChildrenProps, b: FilteredFacetChildrenProps) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0,
  );
  return options;
};
