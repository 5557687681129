import type { FC } from "react";
import cn from "classnames";

export interface TdProps {
  className?: string;
  children?: any;
  width?: string | number;
  [key: string]: any;
}

const Td: FC<TdProps> = ({ children, className, ...props }) => {
  return (
    <td
      className={cn(
        "whitespace-nowrap px-3 py-4 text-jll-text-base-default relative z-0",
        className,
      )}
      {...props}
    >
      {children}
    </td>
  );
};

export default Td;
