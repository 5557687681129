const styles: any = {
  page: {
    backgroundColor: "#00384D",
    color: "white",
    display: "flex",
    position: "relative",
    width: 612,
    height: 792,
    fontWeight: 400,
  },

  header: {
    view: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 36,
      paddingLeft: 54,
      paddingRight: 54,
      height: 18,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      zIndex: 5,
    },
    img: {
      height: 18,
      objectFit: "contain",
    },
    client: {
      color: "white",
      fontSize: 10,
    },
  },

  content: {
    view: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    back: {
      view: {
        paddingLeft: 283,
        position: "relative",
        marginBottom: 20,
        flex: 1,
      },
      img: {
        width: 615,
        height: 670,
        position: "absolute",
        bottom: 0,
        right: -286,
      },
    },

    section: {
      view: {
        paddingLeft: 54,
        paddingRight: 54,
        paddingBottom: 36,
      },
      title: {
        fontFamily: "Source Serif Pro Italic",
        fontWeight: 300,
        fontSize: 52,
        lineHeight: 1.1,
        marginBottom: 45,
      },

      reach: {
        view: {
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: 66,
        },
        label: {
          fontSize: 10,
          marginBottom: 4,
        },
      },

      description: {
        view: {
          fontSize: 7,
          lineHeight: 1.42,
        },
      },
    },
  },
};

export default styles;
