import { Close } from "@mui/icons-material";
import { SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { patchAdditionalInfoAPI, postAdditionalInfoAPI } from "services";
import { Button, Input, Textarea } from "ui-atoms";
import { Modal } from "ui-molecules";
import * as Yup from "yup";

interface IAdditionalInfoModal {
  isOpen: boolean;
  setIsOpen: any;
  selectedInfo?: any;
  data?: any;
  setData?: any;
}

const INIT_INFO = {
  name: "",
  description: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  description: Yup.string().required("This field is required"),
});

const AdditionalInfoModal: React.FC<IAdditionalInfoModal> = ({
  isOpen,
  setIsOpen,
  selectedInfo,
  data,
  setData,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const { closeoutId } = useParams();
  const [postAdditionalInfo] = useApiCall(postAdditionalInfoAPI);
  const [patchAdditionalInfo] = useApiCall(patchAdditionalInfoAPI);

  useEffect(() => {
    if (!isOpen) return;
    setTouched({}, false);
    let formValues: any = {};
    if (!!selectedInfo) {
      Object.keys(INIT_INFO).forEach((key: string) => {
        formValues = {
          ...formValues,
          [key]: selectedInfo?.[key] || "",
        };
      });
    } else {
      formValues = { ...INIT_INFO };
    }
    setValues(formValues);
  }, [selectedInfo, isOpen]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
    setTouched,
  } = useFormik({
    initialValues: INIT_INFO,
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: `${!!selectedInfo ? "Updating" : "Adding"} Info...`,
          },
        });
        const payload = {
          lease_closeout: closeoutId,
          ...values,
        };
        if (!!selectedInfo) {
          patchAdditionalInfo({ id: selectedInfo?.pk, payload })
            .then((res: any) => {
              if (!res) return;
              let originalData = [...(data || [])];
              const idx = originalData?.findIndex(
                (item) => item?.pk === res?.pk || item?.pk === res?.id,
              );
              originalData[idx] = res;
              setData(originalData);
            })
            .finally(() => {
              setIsOpen(false);
              dispatch({
                type: SET_FULL_LOADING,
                payload: {
                  open: false,
                  label: `${!!selectedInfo ? "Updating" : "Adding"} Info...`,
                },
              });
            });
        } else {
          postAdditionalInfo({ ...payload, sort: data?.length || 0 })
            .then((res: any) => {
              if (!res) return;
              let originalData = [...(data || [])];
              originalData.push({ ...res, pk: res?.pk || res?.id });
              setData(originalData);
            })
            .finally(() => {
              setIsOpen(false);
              dispatch({
                type: SET_FULL_LOADING,
                payload: {
                  open: false,
                  label: `${!!selectedInfo ? "Updating" : "Adding"} Info...`,
                },
              });
            });
        }
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: `${!!selectedInfo ? "Updating" : "Adding"} Info...`,
          },
        });
      }
    },
  });

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            {`${!selectedInfo ? "Add" : "Edit"} Custom Field`}
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mt-6">
            <Input
              name="name"
              label="Field Name"
              onChange={handleChange}
              error={touched.name ? errors.name : ""}
              onBlur={handleBlur}
              value={values?.name}
              className="!mb-3"
            />

            <Textarea
              name="description"
              label="Description"
              onChange={handleChange}
              error={touched.description ? errors.description : ""}
              onBlur={handleBlur}
              value={values?.description}
              rows={5}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex flex-row space-x-3 justify-end w-full">
            <Button
              variant="secondary"
              className="w-32"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="w-32"
              type="submit"
              disabled={!isValid || !dirty}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AdditionalInfoModal;
