import { CalendarMonthOutlined } from "@mui/icons-material";
import { SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRentAPI, postRentAPI, putRentAPI } from "services";
import { Button, DatePicker, Input, Select } from "ui-atoms";
import { StyledRentValue, Table } from "ui-molecules";
import {
  getApiDate,
  getFormatedDate,
  getMetaOptions,
  getUTCDate,
  transformObject,
} from "utils";
import * as Yup from "yup";
import cn from "classnames";

const BASE_RENT_COLUMNS = [
  { label: "Month Start", id: "Month Start" },
  { label: "Month End", id: "Month End" },
  { label: "Date Start", id: "Period Start" },
  { label: "Date End", id: "Period End" },
  { label: "Period Months", id: "Period Months" },
  { label: "Base Rent PSF", id: "Rent PSF" },
  { label: "Monthly Cost", id: "Monthly Cost" },
  { label: "Annual Cost", id: "Annual Cost" },
];

const INIT_RENT = {
  term: "",
  rent_type: "",
  escalation: "",
  free_rent: "",
  operating_expenses: "",
  rent: "",
  escalation_date: "",
  rent_term: "",
  opex_term: "",
  escalation_type: "",
};

const validationSchema = Yup.object().shape({
  term: Yup.number()
    .required("This field is required")
    .moreThan(0, "This field will be over than 0"),
  rent_type: Yup.number().optional(),
  rent: Yup.number()
    .required("This field is required")
    .moreThan(0, "This field will be over than 0"),
  free_rent: Yup.number().optional(),
  escalation: Yup.number().optional(),
  escalation_date: Yup.string().optional().nullable(),
  operating_expenses: Yup.number().optional(),

  rent_term: Yup.string().required("This field is required"),
  opex_term: Yup.string().required("This field is required"),
});

const BaseRent = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta } = state;
  const { closeoutId } = useParams();
  const [getRent] = useApiCall(getRentAPI, false, true);
  const [postRent] = useApiCall(postRentAPI);
  const [putRent] = useApiCall(putRentAPI);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (!closeoutId) return;
    getRent(closeoutId).then((res: any) => {
      if (!res) {
        setData([]);
        return;
      }
      if (!!res?.grid) {
        setData({ ...res, grid: transformObject(res?.grid) });
      } else {
        setData([]);
      }
    });
  }, [closeoutId]);

  useEffect(() => {
    if (!data || !meta) {
      setValues(INIT_RENT);
      setFieldValue(
        "opex_term",
        getMetaOptions(meta?.leasecloseoutrentcalculator?.opex_term)[0]?.value,
      );
      setFieldValue(
        "rent_term",
        getMetaOptions(meta?.leasecloseoutrentcalculator?.rent_term)[0]?.value,
      );
      return;
    }
    let formValues: any = {};
    Object.keys(INIT_RENT).forEach((key: string) => {
      let value = data?.[key] || "";
      if (key === "rent" || key === "operating_expenses")
        value = Number(value).toFixed(2);
      formValues = {
        ...formValues,
        [key]: value,
      };
    });
    setValues(formValues);
    if (!formValues?.opex_term)
      setFieldValue(
        "opex_term",
        getMetaOptions(meta?.leasecloseoutrentcalculator?.opex_term)[0]?.value,
      );
    if (!formValues?.rent_term)
      setFieldValue(
        "rent_term",
        getMetaOptions(meta?.leasecloseoutrentcalculator?.rent_term)[0]?.value,
      );
  }, [data, meta]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: INIT_RENT,
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Submitting",
          },
        });
        let payload = {
          ...values,
          escalation_date: getApiDate(values?.escalation_date),
          lease_closeout: closeoutId,
        };

        putRent({ id: closeoutId, payload })
          .then((res: any) => {
            if (!res) {
              setData([]);
              return;
            }
            if (!!res?.grid) {
              setData({ ...res, grid: transformObject(res?.grid) });
            } else {
              setData([]);
            }
          })
          .finally(() => {
            dispatch({
              type: SET_FULL_LOADING,
              payload: {
                open: false,
                label: "",
              },
            });
          });
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      }
    },
  });

  return (
    <div className="-mx-14 flex flex-col h-full overflow-y-auto absolute top-0 left-0 right-0 bottom-0">
      <div className="mx-14">
        <p className="text-jll-text-base-subdued mb-8">
          Calculate the total base rent amount for the complete duration of the
          lease.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-row space-x-6 mb-6">
            <Select.Single
              options={getMetaOptions(
                meta?.leasecloseoutrentcalculator?.rent_type,
              )}
              name="rent_type"
              label="Rent Type"
              divClassName="w-[240px] !mb-0"
              onBlur={handleBlur as any}
              onChange={(value: any) => {
                if (!value) {
                  setFieldValue("rent_type", null);
                } else {
                  setFieldValue("rent_type", value);
                }
              }}
              selectedOption={
                values?.rent_type
                  ? {
                      label:
                        meta?.leasecloseoutrentcalculator?.rent_type?.[
                          values?.rent_type
                        ]?.value,
                      value: values?.rent_type,
                    }
                  : undefined
              }
            />
            <Input
              name="rent"
              type="number"
              label="Initial Rent"
              className="w-[240px] !mb-0"
              leadingCurrency="$"
              trailingSelect
              options={getMetaOptions(
                meta?.leasecloseoutrentcalculator?.rent_term,
              )}
              onSelectChange={(option: any) => {
                setFieldValue("rent_term", option?.value);
              }}
              selectedValue={values?.rent_term}
              inputClassName="pr-28"
              onChange={handleChange}
              error={touched.rent ? errors.rent : ""}
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue("rent", Number(values?.rent).toFixed(2));
              }}
              value={values?.rent}
              isRequired
            />
            <Input
              name="term"
              type="number"
              label="Term"
              month
              className="w-[240px] !mb-0"
              onChange={handleChange}
              error={touched.term ? errors.term : ""}
              onBlur={handleBlur}
              value={values?.term}
              isRequired
            />
            <Input
              name="free_rent"
              type="number"
              label="Free Rent"
              month
              className="w-[240px] !mb-0"
              onChange={handleChange}
              error={touched.free_rent ? errors.free_rent : ""}
              onBlur={handleBlur}
              value={values?.free_rent}
            />
          </div>

          <div className="flex flex-row space-x-6 mb-10">
            <Input
              name="escalation"
              type="number"
              label="Escalation"
              leadingCurrency={Number(values?.escalation_type) === 1 ? "" : "$"}
              trailingSelect
              options={getMetaOptions(
                meta?.leasecloseoutrentcalculator?.escalation_type,
              )}
              onSelectChange={(option: any) => {
                setFieldValue("escalation_type", Number(option?.value));
              }}
              selectedValue={values?.escalation_type}
              inputClassName={
                Number(values?.escalation_type) === 1 ? "pr-16" : "pr-28"
              }
              className="w-[240px] !mb-0"
              onChange={handleChange}
              error={touched.escalation ? errors.escalation : ""}
              onBlur={handleBlur}
              value={values?.escalation}
            />

            {values?.escalation ? (
              <DatePicker
                name="escalation_date"
                label="First Rent Escalation Date"
                trailingIcon={CalendarMonthOutlined}
                className="w-[240px] !mb-0"
                onBlur={handleBlur}
                selected={
                  values?.escalation_date
                    ? getUTCDate(values?.escalation_date)
                    : null
                }
                error={touched.escalation_date ? errors.escalation_date : ""}
                value={
                  values?.escalation_date
                    ? getFormatedDate(
                        getUTCDate(values?.escalation_date).toString(),
                      )
                    : undefined
                }
                onChange={(date: Date) => {
                  setFieldValue("escalation_date", date);
                }}
              />
            ) : (
              <></>
            )}

            <Input
              name="operating_expenses"
              type="number"
              label="Operating Expenses"
              className="w-[240px] !mb-0"
              leadingCurrency="$"
              trailingSelect
              options={getMetaOptions(
                meta?.leasecloseoutrentcalculator?.opex_term,
              )}
              inputClassName="pr-28"
              onSelectChange={(option: any) => {
                setFieldValue("opex_term", option?.value);
              }}
              selectedValue={values?.opex_term}
              onChange={handleChange}
              error={
                touched.operating_expenses ? errors.operating_expenses : ""
              }
              onBlur={(e) => {
                handleBlur(e);
                setFieldValue(
                  "operating_expenses",
                  Number(values?.operating_expenses).toFixed(2),
                );
              }}
              value={values?.operating_expenses}
            />
            {!values.escalation && <div className="w-[240px]" />}
            <div className="w-[240px]" />
          </div>

          <div className="flex flex-row items-end mb-10">
            <Button
              variant="primary"
              type="submit"
              disabled={!isValid || !dirty}
            >
              Calculate
            </Button>
          </div>
        </form>

        <div>
          <h2 className="text-jll-text-base-default text-xl mb-8">
            Base Rent Schedule
          </h2>
          {!data?.grid?.length ? (
            <Table.Loading size={6} />
          ) : (
            <div className="h-full -mx-14">
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    {BASE_RENT_COLUMNS.map((column: any, idx: number) => (
                      <Table.Th
                        key={idx}
                        itemClassName={cn({
                          "!pl-14": idx === 0,
                          "!pr-14": idx === BASE_RENT_COLUMNS?.length - 1,
                        })}
                      >
                        {column.label}
                      </Table.Th>
                    ))}
                    <Table.Th itemClassName="!pr-14" />
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {data?.grid?.map((item: any, idx: number) => (
                    <Table.Tr key={idx}>
                      {BASE_RENT_COLUMNS?.map((column: any, idx1: number) => (
                        <Table.Td
                          key={idx1}
                          className={cn({
                            "!pl-14": idx1 === 0,
                            "!pr-14": idx1 === BASE_RENT_COLUMNS?.length - 1,
                          })}
                        >
                          <StyledRentValue rent={item} valueKey={column.id} />
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseRent;
