import {
  Avatar,
  Button,
  Checkbox,
  Dropzone,
  Input,
  Select,
  Switch,
} from "ui-atoms";
import {
  ArrowDropDown,
  DeleteOutline,
  EditOutlined,
  Search,
  Share,
} from "@mui/icons-material";
import { Table } from "ui-molecules";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NoImage from "assets/images/noImage.png";

const UI = () => {
  return (
    <div className="py-8">
      <div className="rounded max-w-[900px] mx-auto p-4">
        <h1 className="text-heading1">Design system</h1>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Buttons</h3>
          <div className="space-x-2 mb-2">
            <Button variant="primary">Primary</Button>
            <Button variant="secondary">Secondary</Button>
            <Button variant="secondary" disabled>
              Disabled
            </Button>
          </div>

          <div className="space-x-2 mb-2">
            <Button variant="primary" size="medium">
              Small Primary
            </Button>
            <Button variant="secondary" size="medium">
              Small Secondary
            </Button>
            <Button variant="secondary" size="medium" disabled>
              Small Disabled
            </Button>
          </div>

          <div className="space-x-2 mb-2">
            <Button variant="primary" leadingIcon={Share}>
              Primary
            </Button>
            <Button variant="secondary" leadingIcon={Share} size="small">
              Secondary
            </Button>
            <Button variant="secondary" leadingIcon={Share} disabled>
              Disabled
            </Button>
          </div>

          <div className="space-x-2 mb-2">
            <Button variant="primary" trailingIcon={Share}>
              Primary
            </Button>
            <Button variant="secondary" trailingIcon={Share} size="medium">
              Secondary
            </Button>
            <Button variant="secondary" trailingIcon={Share} disabled>
              Disabled
            </Button>
          </div>

          <div className="space-x-2 mb-2">
            <Button variant="primary" kind="icon">
              <EditOutlined />
            </Button>
            <Button variant="secondary" kind="icon">
              <EditOutlined />
            </Button>
            <Button variant="secondary" trailingIcon="share" disabled>
              Disabled
            </Button>
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Avatar</h3>
          <div className="flex flex-row items-center space-x-2 mb-2">
            <Avatar
              src={
                "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              }
              size="small"
            />
            <Avatar
              src={
                "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              }
            />
            <Avatar
              src={
                "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              }
              size="large"
            />
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Input</h3>
          <div className="flex flex-row space-x-2 mb-2">
            <Input name={""} placeholder="Placeholder" className="w-[200px]" />
            <Input
              name={""}
              placeholder="Placeholder"
              inputSize="large"
              className="w-[200px]"
            />
          </div>

          <div className="flex flex-row space-x-2 mb-2">
            <Input
              name={""}
              placeholder="Placeholder"
              className="w-[200px]"
              label="Label"
            />
            <Input
              name={""}
              placeholder="Placeholder"
              inputSize="large"
              className="w-[200px]"
              label="Label"
            />
          </div>

          <div className="flex flex-row space-x-2 mb-2">
            <Input
              name={""}
              placeholder="Placeholder"
              className="w-[200px]"
              label="Label"
              leadingIcon={Search}
            />
            <Input
              name={""}
              placeholder="Placeholder"
              className="w-[200px]"
              label="Label"
              trailingIcon={ArrowDropDown}
            />
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Table</h3>
          <div className="flex flex-row space-x-2 mb-2">
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Name</Table.Th>
                  <Table.Th>JOB TITLE</Table.Th>
                  <Table.Th>EMAIL</Table.Th>
                  <Table.Th>PHONE</Table.Th>
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td></Table.Td>
                  <Table.Td>Vice President</Table.Td>
                  <Table.Td>michelle.johnson@jll.com</Table.Td>
                  <Table.Td>(415) 395-4900</Table.Td>
                  <Table.Td className="space-x-2 text-right">
                    <Button variant="secondary" kind="icon">
                      <EditOutlined className="!w-5 !h-5" />
                    </Button>
                    <Button variant="secondary" kind="icon">
                      <DeleteOutline className="!w-5 !h-5" />
                    </Button>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td></Table.Td>
                  <Table.Td>Vice President</Table.Td>
                  <Table.Td>michelle.johnson@jll.com</Table.Td>
                  <Table.Td>(415) 395-4900</Table.Td>
                  <Table.Td className="space-x-2 text-right">
                    <Button variant="secondary" kind="icon">
                      <EditOutlined className="!w-5 !h-5" />
                    </Button>
                    <Button variant="secondary" kind="icon">
                      <DeleteOutline className="!w-5 !h-5" />
                    </Button>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Checkbox</h3>
          <div className="flex flex-row space-x-2 mb-2">
            <Checkbox
              label="Email"
              name="email"
              id="email"
              onChange={() => {}}
            />
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Switch</h3>
          <div className="flex flex-row space-x-2 mb-2">
            <Switch enabled={true} onChange={() => {}} label="Label" />
            <Switch
              enabled={true}
              onChange={() => {}}
              label_1="Off"
              label_2="On"
            />
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Image</h3>
          <div className="flex flex-row space-x-2 mb-2">
            <LazyLoadImage src={NoImage} className="w-40 h-40" />
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Dropzone</h3>
          <div className="flex flex-row space-x-2 mb-2">
            <Dropzone />
          </div>
        </section>

        <section className="my-6">
          <h3 className="text-heading2 mb-2 font-bold">Select</h3>
          <div className="flex flex-row space-x-2 mb-2">
            <Select
              options={[
                { label: "List item", value: "list1" },
                { label: "List item", value: "list2" },
              ]}
              label="Label"
              title="Select"
              name="select"
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default UI;
