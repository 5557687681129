const styles: any = {
  contact: {
    view: {
      height: "100%",
      paddingLeft: 54,
      paddingRight: 54,
    },
    title: {
      color: "#01151C",
      fontSize: 24,
      marginBottom: 24,
    },
    description: {
      color: "#667579",
      fontSize: 10,
      lineHeight: 1.45,
    },

    list: {
      view: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      },
      default: {
        view: {
          width: "50%",
          height: 64,
          marginBottom: 36,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
        photo: {
          borderRadius: 3,
          width: 64,
          minWidth: 64,
          height: 64,
          objectFit: "contain",
          marginRight: 26,
        },
        description: {
          view: {
            color: "#01151C",
            display: "flex",
            flexDirection: "column",
            height: 64,
            maxWidth: 127,
            justifyContent: "center",
          },
          propertyView1: {
            marginBottom: 8,
            width: "100%",
          },
          name: {
            fontSize: 10,
            marginBottom: 2,
          },
          title: {
            fontSize: 8,
            display: "flex",
            flexWrap: "wrap",
            lineHeight: 1.45,
            color: "#667579",
          },
          email: {
            fontSize: 8,
            lineHeight: 1.45,
            color: "#667579",
          },
        },
      },

      lg: {
        view: {
          width: "100%",
          height: 114,
          marginBottom: 36,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
        photo: {
          borderRadius: 3,
          width: 114,
          minWidth: 114,
          height: 114,
          objectFit: "contain",
          marginRight: 26,
        },
        description: {
          view: {
            color: "#01151C",
            display: "flex",
            flexDirection: "column",
            height: 64,
            maxWidth: 310,
          },
          propertyView1: {
            marginBottom: 11,
            width: "100%",
          },
          name: {
            fontSize: 14,
            marginBottom: 2,
          },
          title: {
            fontSize: 10,
            display: "flex",
            flexWrap: "wrap",
            lineHeight: 1.45,
            color: "#667579",
          },
          email: {
            fontSize: 8,
            lineHeight: 1.45,
            color: "#667579",
          },
        },
      },
    },
  },

  reach: {
    view: {
      marginLeft: -54,
      marginRight: -54,
      minHeight: 100,
      backgroundColor: "#F6F9FA",
      paddingTop: 36,
      paddingLeft: 54,
      paddingRight: 54,
      display: "flex",
      flexDirection: "row",
    },
    label: {
      fontSize: 8,
      color: "#01151C",
      lineHeight: 1.45,
    },
  },
};

export default styles;
