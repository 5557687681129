import cn from "classnames";

interface ModalHeaderProps {
  className?: string;
  children: any;
}

function ModalHeader({ className, children }: ModalHeaderProps) {
  return <div className={cn(className)}>{children}</div>;
}

export default ModalHeader;
