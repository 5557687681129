const styles: any = {
  page: {
    backgroundColor: "#00384D",
    color: "white",
    display: "flex",
    position: "relative",
    width: 612,
    height: 792,
    fontWeight: 400,
  },

  content: {
    view: {
      position: "absolute",
      bottom: 55,
      top: 0,
      left: 0,
      right: 0,
    },

    back: {
      view: {
        paddingLeft: 170,
        position: "relative",
        height: "100%",
      },
      img: {
        width: 615,
        height: 670,
        position: "absolute",
        bottom: 0,
        right: -180,
      },
    },

    section: {
      view: {
        paddingLeft: 54,
        paddingRight: 54,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },

      header: {
        fontFamily: "Source Serif Pro Italic",
        fontWeight: 300,
        fontSize: 65,
        lineHeight: 1.1,
        marginBottom: 32,
      },
      title: {
        fontSize: 24,
        lineHeight: 1.3,
      },
      updated_date: {
        marginBottom: 30,
        fontSize: 10,
        lineHeight: 1.4,
      },
    },
  },

  footer: {
    view: {
      position: "absolute",
      bottom: 0,
      left: 0,
      paddingLeft: 54,
      paddingRight: 54,
      marginBottom: 36,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    img: {
      height: 17,
      objectFit: "contain",
    },
  },
};

export default styles;
