import { Close, InfoOutlined } from "@mui/icons-material";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

interface IDeleteConfirmModal {
  isOpen: boolean;
  setIsOpen: any;
}

const DeleteConfirmModal: React.FC<IDeleteConfirmModal> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Body>
        <div className="flex flex-row items-start justify-between">
          <div className="flex flex-row space-x-4">
            <div className="rounded-full bg-jll-surface-base-secondary p-3 h-fit">
              <InfoOutlined className="!w-8 !h-8 text-jll-text-base-subdued" />
            </div>
            <div className="text-jll-text-base-default">
              <h3 className="text-xl mb-1">Can’t delete this contact</h3>
              <p>
                You cannot delete this contact. There has to be at least one
                contact on the deal.
              </p>
            </div>
          </div>
          <div>
            <Button
              variant="neutral"
              onClick={() => setIsOpen(false)}
              leadingIcon={Close}
              size="large"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="w-full">
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmModal;
