/**
 * Capitalize string
 * @param value Ex: ryong
 * @returns Ex: Ryong
 */
export const capitalize = (s: string | undefined) => {
  if (!s) return "";
  return s[0].toUpperCase() + s.slice(1);
};

/**
 * Capitalize first letters of name
 * @param value Ex: ryong ma
 * @returns Ex: RM
 */
export const capitalizeName = (name: string = "") => {
  const arr = name?.split(" ");
  return `${arr[0]?.[0]?.toUpperCase() || ""}${
    arr[1]?.[0]?.toUpperCase() || ""
  }`;
};

/**
 * Capitalize all words
 * @param value Ex: date_available, _
 * @returns Ex: Date Available
 */
export const capitalizeWords = (
  str: string = "",
  splitLetter: string = " ",
) => {
  try {
    const arr = str?.split(splitLetter);
    let newStr = "";
    arr?.forEach((s) => (newStr += `${capitalize(s)} `));
    return newStr;
  } catch (err) {
    return "";
  }
};
