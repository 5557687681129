import Thead from "./Thead";
import Tbody from "./Tbody";
import Tr from "./Tr";
import Th from "./Th";
import Td from "./Td";
import cn from "classnames";
import Loading from "./Loading";

export interface TableProps
  extends React.TableHTMLAttributes<HTMLTableElement> {
  className?: string;
  children: any;
}

function Table<T extends object>({ children, className }: TableProps) {
  return (
    <div className="flex flex-row w-fit min-w-full">
      <table className={cn("w-full border-collapse border-hidden", className)}>
        {children}
      </table>
    </div>
  );
}

Table.Thead = Thead;
Table.Th = Th;
Table.Tbody = Tbody;
Table.Tr = Tr;
Table.Td = Td;
Table.Loading = Loading;

export default Table;
