import { CalendarMonthOutlined, Close } from "@mui/icons-material";
import { SET_DETAIL_INFO, SET_FULL_LOADING } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect } from "react";
import { patchDetailAPI } from "services";
import { Button, DatePicker, Textarea } from "ui-atoms";
import { Modal } from "ui-molecules";
import { getApiDate, getFormatedDate, getUTCDate } from "utils";
import * as Yup from "yup";

interface IDateModal {
  isOpen: boolean;
  setIsOpen: any;
  selectedId?: string;
}

const INIT_DATE = {
  date: "",
  description: "",
};

const INIT_DATA = [
  { label: "Commencement", id: "commencement_date" },
  { label: "Expiration", id: "expiration_date" },
  { label: "Execution Dates", id: "execution_date" },
];

const validationSchema = Yup.object().shape({
  date: Yup.string().required("This field is required"),
  description: Yup.string().optional().nullable(),
});

const DateModal: React.FC<IDateModal> = ({ isOpen, setIsOpen, selectedId }) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { detailInfo } = state;
  const [patchDetail] = useApiCall(patchDetailAPI);

  useEffect(() => {
    setTouched({}, false);
    if (!detailInfo || !selectedId || !isOpen) {
      setValues({ ...INIT_DATE });
      return;
    }
    setValues({
      date: detailInfo?.[selectedId],
      description: detailInfo?.[`${selectedId}_description`],
    });
  }, [detailInfo, isOpen]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
    setTouched,
  } = useFormik({
    initialValues: INIT_DATE,
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: true,
            label: "Submitting",
          },
        });

        let payload: any = {
          [`${selectedId}`]: getApiDate(values.date),
          [`${selectedId}_description`]: values.description,
        };

        patchDetail({ id: detailInfo?.id, payload })
          .then((res: any) => {
            if (!res) return;
            dispatch({
              type: SET_DETAIL_INFO,
              payload: {
                ...detailInfo,
                ...payload,
              },
            });
          })
          .finally(() => {
            dispatch({
              type: SET_FULL_LOADING,
              payload: {
                open: false,
                label: "",
              },
            });
          });

        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
        setIsOpen(false);
      } catch (err) {
        dispatch({
          type: SET_FULL_LOADING,
          payload: {
            open: false,
            label: "",
          },
        });
      }
    },
  });

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-xl leading-6 text-jll-text-base-default">
            Edit Date
          </h3>
          <Button
            variant="neutral"
            onClick={() => setIsOpen(false)}
            leadingIcon={Close}
            size="large"
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mt-6">
            <DatePicker
              className="w-full"
              trailingIcon={CalendarMonthOutlined}
              onBlur={handleBlur}
              selected={values?.date ? getUTCDate(values?.date) : null}
              error={touched.date ? errors.date : ""}
              value={
                values?.date
                  ? getFormatedDate(getUTCDate(values?.date).toString())
                  : undefined
              }
              onChange={(date: Date) => setFieldValue("date", date)}
              label={INIT_DATA.find((item) => item.id === selectedId)?.label}
            />

            <Textarea
              label="Descripton"
              name="description"
              placeholder=""
              className="mb-2"
              rows={5}
              onChange={handleChange}
              error={touched.description ? errors.description : ""}
              onBlur={handleBlur}
              value={values?.description}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex flex-row space-x-3 justify-end w-full">
            <Button
              variant="secondary"
              className="w-32"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="w-32"
              type="submit"
              disabled={!isValid || !dirty}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DateModal;
