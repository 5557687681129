import { GlobalContext } from "context";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  API_PREFIX_URL,
  HTTP_ERROR_BAD_REQUEST,
  HTTP_ERROR_NOT_FOUND,
  HTTP_ERROR_UNAUTHORIZED,
  HTTP_ERROR_WRITE_ACCESS,
  SET_ERROR_MODAL,
  URLS,
} from "constant";

const useApiCall = (
  service: any,
  isPage: boolean = false,
  hide: boolean = false,
) => {
  const { dispatch } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async (param: any) => {
    setLoading(true);
    try {
      const result = await service(param);
      setLoading(false);

      if (result?.status) {
        setData(result?.data || result?.result);
        return result?.data || result?.result;
      } else {
        if (
          (result?.error &&
            result?.error?.toLowerCase()?.includes("invalid_grant")) ||
          result?.detail?.statusCode === HTTP_ERROR_UNAUTHORIZED
        ) {
          navigate(`/`);
          return;
        }
      }
    } catch (error: any) {
      setLoading(false);
      setError(error?.message || error?.detail);

      if (
        error?.response?.status === HTTP_ERROR_UNAUTHORIZED ||
        error?.response?.status == HTTP_ERROR_WRITE_ACCESS
      ) {
        if (error?.request?.responseURL?.endsWith(`${API_PREFIX_URL}/session/`))
          return;

        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            open: true,
            content: {
              label: error?.response?.status || "",
              value: "You do not have access",
              isContact: true,
            },
          },
        });
        return;
      }
      if (isPage && error?.response?.status === HTTP_ERROR_NOT_FOUND) {
        navigate(`/${URLS.ERROR}?status=404`);
        return;
      }
      if (hide && error?.response?.status === HTTP_ERROR_NOT_FOUND) {
        return;
      }
      if (error?.response?.status === HTTP_ERROR_BAD_REQUEST) {
        let error_message = "";
        if (error?.response?.data) {
          if (Array.isArray(error?.response?.data)) {
            error?.response?.data?.forEach((item: any) => {
              error_message += `${item}`;
            });
          } else {
            for (let key in error?.response?.data) {
              error_message += `${key.replaceAll("_", " ")}:`;
              for (let item in error?.response?.data[key]) {
                error_message += `${error?.response?.data[key][item]}`;
              }
            }
          }
        }

        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            open: true,
            content: {
              label: error?.response?.status || "",
              value: error_message,
              isContact: true,
            },
          },
        });
        return;
      }
      if (error?.message || error?.detail?.message || error?.detail) {
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            open: true,
            content: {
              label: error?.response?.status || "",
              value: error?.message || error?.detail?.message || error?.detail,
              isContact: true,
            },
          },
        });
      }
    }
  }, []);
  return [fetchData as Function, loading, data, error];
};

export default useApiCall;
