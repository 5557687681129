import { View, Text, Image } from "@react-pdf/renderer";
import styles from "./styles";
import { getDecimalFormating, ordinalSuffixOf } from "utils";
import PageLayoutV2 from "../PageLayoutV2";

interface IPageFloorplanV2 {
  detail?: any;
  space?: any;
  pageNumber?: number;
  id?: string;
}

const PageFloorplanV2: React.FC<IPageFloorplanV2> = ({
  detail,
  space,
  pageNumber,
  id,
}) => {
  return (
    <PageLayoutV2 pageNumber={pageNumber} id={id} detail={detail}>
      <View style={styles.view}>
        <View style={styles.header.view}>
          <Text style={styles.header.title}>Floor Plan</Text>
          <View style={styles.header.valueView}>
            <Text style={styles.header.label}>
              {`Suite ${space?.name || ""} - `}
            </Text>
            <Text style={styles.header.label}>
              {getDecimalFormating(space?.rsf)} RSF
            </Text>
          </View>
        </View>

        {!!space?.floorplan?.length && (
          <View style={styles.content.view}>
            <Image src={space?.floorplan?.[0]} style={styles.content.img} />
          </View>
        )}
      </View>
    </PageLayoutV2>
  );
};

export default PageFloorplanV2;
