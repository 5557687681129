import { Close, ErrorOutlineOutlined } from "@mui/icons-material";
import { SET_ERROR_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

const ErrorModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { errorModalOpen, errorModalContent } = state;

  const handleClose = () => {
    dispatch({
      type: SET_ERROR_MODAL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  return (
    <Modal isOpen={errorModalOpen} setIsOpen={handleClose} size="normal">
      <Modal.Body>
        <Button
          variant="neutral"
          onClick={handleClose}
          leadingIcon={Close}
          leadingIconClass="text-jll-text-base-default"
          size="large"
          className="absolute top-4 right-4"
        />
        <div className="w-full h-full flex flex-row space-x-4 mb-8">
          <div className="rounded-full p-[14px] bg-jll-color-surface-dangerSubdued h-full">
            <ErrorOutlineOutlined className="text-jll-text-rag-danger-accessible !w-[30px] !h-[30px]" />
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-jll-text-base-default text-xl">
              {!!errorModalContent?.label
                ? `${errorModalContent?.label}${errorModalContent?.label === "Warning" ? "" : " Error"}`
                : ""}
            </p>
            <p className="text-jll-text-base-default">
              {errorModalOpen &&
                (errorModalContent?.value ||
                  "An error has occurred. Admins have been notified. If the problem persists please contact")}{" "}
              {!errorModalContent?.isContact ? (
                ""
              ) : (
                <a
                  href="closeout@jll.com"
                  className="text-jll-color-text-interaction"
                >
                  closeout@jll.com
                </a>
              )}
            </p>
          </div>
        </div>
        <div className="w-full flex flex-row justify-end">
          <Button
            variant="primary"
            size="medium"
            className="w-[104px]"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
