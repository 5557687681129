import { View, Text } from "@react-pdf/renderer";
import styles from "./styles";
import { getDecimalFormating, getFormatedDate } from "utils";
import PageLayoutV2 from "../PageLayoutV2";

interface IPageRentExpenseV2 {
  detail?: any;
  pageNumber?: number;
  rent?: any;
  meta?: any;
  id?: string;
}

const PageRentExpenseV2: React.FC<IPageRentExpenseV2> = ({
  detail,
  pageNumber,
  rent,
  meta,
  id,
}) => {
  return (
    <PageLayoutV2
      pageNumber={pageNumber}
      id={id}
      detail={detail}
      headerStyle={{
        backgroundColor: "#F6F9FA",
      }}
    >
      <View style={styles.view}>
        <Text style={{ ...styles.title }}>Rent & Expenses</Text>
        <View style={styles.detail.view}>
          {!!rent?.rent_type && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Rent Type</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  {
                    meta?.leasecloseoutrentcalculator?.rent_type?.[
                      rent?.rent_type
                    ]?.value
                  }
                </Text>
              </View>
            </View>
          )}
          {!!rent?.rent && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Initial Rent</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  ${getDecimalFormating(rent?.rent, true)}
                </Text>
                <Text style={styles.detail.item.unit}>/RSF/Year</Text>
              </View>
            </View>
          )}
          {!!detail?.lease_rsf && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Total Size</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  {getDecimalFormating(detail?.lease_rsf)}
                </Text>
                <Text style={styles.detail.item.unit}> RSF</Text>
              </View>
            </View>
          )}
          {!!rent?.term && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Term</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>{rent?.term}</Text>
                <Text style={styles.detail.item.unit}> months</Text>
              </View>
            </View>
          )}
          {!!rent?.free_rent && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Free Rent</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>{rent?.free_rent}</Text>
                <Text style={styles.detail.item.unit}> months</Text>
              </View>
            </View>
          )}
          {!!rent?.escalation && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Escalation</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  {rent?.escalation}%
                </Text>
              </View>
            </View>
          )}
          {!!rent?.escalation_date && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>
                Escalation Start Date
              </Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  {getFormatedDate(rent?.escalation_date)}
                </Text>
              </View>
            </View>
          )}
          {!!rent?.operating_expenses && (
            <View style={styles.detail.item.view}>
              <Text style={styles.detail.item.label}>Operating Expense</Text>
              <View style={styles.detail.item.valueView}>
                <Text style={styles.detail.item.value}>
                  ${getDecimalFormating(rent?.operating_expenses, true)}
                </Text>
                <Text style={styles.detail.item.unit}>RSF/Year</Text>
              </View>
            </View>
          )}
          {/* {!!rent?.operating_expenses && (
        <View style={styles.detail.item.view}>
          <Text style={styles.detail.item.label}>TI allawance</Text>
          <View style={styles.detail.item.valueView}>
            <Text style={styles.detail.item.value}>$19.00</Text>
            <Text style={styles.detail.item.unit}>RSF</Text>
          </View>
        </View>)} */}
        </View>

        <View style={styles.section.view}>
          <View style={styles.section.list.view}>
            <View style={styles.section.list.row}>
              <Text
                style={{
                  ...styles.section.list.item,
                  width: "25%",
                }}
              >
                Date
              </Text>
              <Text
                style={{
                  ...styles.section.list.item,
                  width: "15%",
                }}
              >
                Months
              </Text>
              <Text
                style={{
                  ...styles.section.list.item,
                  width: "20%",
                  textAlign: "right",
                }}
              >
                $/RSF/Year
              </Text>
              <Text
                style={{
                  ...styles.section.list.item,
                  width: "20%",
                  textAlign: "right",
                }}
              >
                Monthly Cost
              </Text>
              <Text
                style={{
                  ...styles.section.list.item,
                  width: "20%",
                  textAlign: "right",
                }}
              >
                Annual Cost
              </Text>
            </View>
            {rent?.grid?.map((grid: any, idx: number) => {
              if (!grid) return;
              return (
                <View
                  style={{
                    ...styles.section.list.row,
                    backgroundColor: idx % 2 === 0 ? "#F6F9FA" : "white",
                  }}
                  key={idx}
                >
                  {(!!grid?.["Period Start"] || !!grid?.["Period End"]) && (
                    <Text style={{ ...styles.section.list.item, width: "25%" }}>
                      {`${getFormatedDate(
                        grid?.["Period Start"],
                      )}-${getFormatedDate(grid?.["Period End"])}`}
                    </Text>
                  )}
                  {(!!grid?.["Month Start"] || !!grid?.["Month End"]) && (
                    <Text style={{ ...styles.section.list.item, width: "15%" }}>
                      {`${grid?.["Month Start"] < 10 ? `0${grid?.["Month Start"]}` : grid?.["Month Start"]}-${grid?.["Month End"] < 10 ? `0${grid?.["Month End"]}` : grid?.["Month End"]}`}
                    </Text>
                  )}
                  <Text
                    style={{
                      ...styles.section.list.item,
                      width: "20%",
                      textAlign: "right",
                    }}
                  >
                    {!!grid?.["Rent PSF"]
                      ? `$${getDecimalFormating(grid?.["Rent PSF"], true)}`
                      : "$0.00"}
                  </Text>
                  <Text
                    style={{
                      ...styles.section.list.item,
                      width: "20%",
                      textAlign: "right",
                    }}
                  >
                    {!!grid?.["Monthly Cost"]
                      ? `$${getDecimalFormating(grid?.["Monthly Cost"], true)}`
                      : "$0.00"}
                  </Text>
                  <Text
                    style={{
                      ...styles.section.list.item,
                      width: "20%",
                      textAlign: "right",
                    }}
                  >
                    {!!grid?.["Annual Cost"]
                      ? `$${getDecimalFormating(grid?.["Annual Cost"], true)}`
                      : "$0.00"}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    </PageLayoutV2>
  );
};

export default PageRentExpenseV2;
