const styles: any = {
  view: {
    height: "100%",
    width: "100%",
    paddingLeft: 54,
    paddingRight: 54,
  },
  header: {
    view: {
      marginBottom: 30,
      color: "#01151C",
    },
    valueView: {
      display: "flex",
      flexDirection: "row",
    },
    title: {
      fontSize: 24,
      marginBottom: 4,
      lineHeight: 1.33,
    },
    label: {
      fontSize: 16,
    },
  },

  content: {
    view: {
      paddingBottom: 20,
    },
    img: {
      width: "100%",
      objectFit: "contain",
    },
  },
};

export default styles;
