import { GlobalContext } from "context";
import { useContext } from "react";
import { KeyDateProps } from "types";
import { getFormatedDate } from "utils";
import { renderToString } from "react-dom/server";

export interface StyledKeyDateValueProps {
  keyDate: any;
  valueKey: keyof KeyDateProps | "type" | "date";
  detail?: any;
  index?: number;
}

const StyledKeyDateValue: React.FC<StyledKeyDateValueProps> = ({
  keyDate,
  valueKey,
  detail,
  index,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta, showTooltip, hideTooltip } = state;

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "type":
            if (
              keyDate?.id === "commencement_date" ||
              keyDate?.id === "expiration_date" ||
              keyDate?.id === "execution_date"
            )
              return <>{keyDate?.label}</>;
            if (!!keyDate?.type && keyDate?.type !== "Custom")
              return (
                <>{meta?.leasecloseoutdates?.type?.[keyDate?.type]?.value}</>
              );
            return <>{keyDate?.custom_type}</>;

          case "date":
            if (
              keyDate?.id === "commencement_date" ||
              keyDate?.id === "expiration_date" ||
              keyDate?.id === "execution_date"
            )
              return <>{getFormatedDate(detail?.[`${keyDate?.id}`])}</>;
            if (!keyDate?.value_max)
              return <>{getFormatedDate(keyDate?.value_min)}</>;
            return (
              <>{`${getFormatedDate(keyDate?.value_min)} - ${getFormatedDate(
                keyDate?.value_max,
              )}`}</>
            );

          case "description":
            if (
              keyDate?.id === "commencement_date" ||
              keyDate?.id === "expiration_date" ||
              keyDate?.id === "execution_date"
            )
              return (
                <p
                  className="whitespace-pre-wrap line-clamp-3"
                  id={`${keyDate?.id}_description`}
                  onMouseOver={() => showTooltip(`${keyDate?.id}_description`)}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-html={renderToString(
                    <p className="whitespace-pre-wrap md:max-w-[500px] sm:max-w-8/12">
                      {detail?.[`${keyDate?.id}_description`]}
                    </p>,
                  )}
                >
                  {detail?.[`${keyDate?.id}_description`]}
                </p>
              );
            if (keyDate?.hasOwnProperty(valueKey)) {
              return (
                <p
                  className="whitespace-pre-wrap line-clamp-3"
                  id={`key_description_${index}`}
                  onMouseOver={() => showTooltip(`key_description_${index}`)}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-html={renderToString(
                    <p className="whitespace-pre-wrap md:max-w-[500px] sm:max-w-8/12">
                      {keyDate?.[valueKey] || ""}
                    </p>,
                  )}
                >
                  {keyDate?.[valueKey] || ""}
                </p>
              );
            }
            return <>-</>;

          default:
            if (keyDate?.hasOwnProperty(valueKey)) {
              return (
                <p
                  className="whitespace-normal line-clamp-2"
                  title={keyDate?.[valueKey] || ""}
                >
                  {keyDate?.[valueKey] || ""}
                </p>
              );
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledKeyDateValue;
