import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getRentAPI = async (id: number) => {
  const res = await axios.get(`${API_PREFIX_URL}/closeout/calculator/${id}/`);
  return res;
};

export const postRentAPI = async (payload: APIProps) => {
  const res = await axios.post(
    `${API_PREFIX_URL}/closeout/calculator/`,
    payload,
  );
  return res;
};

export const putRentAPI = async ({ id, payload }: any) => {
  const res = await axios.put(
    `${API_PREFIX_URL}/closeout/calculator/${id}/`,
    payload,
  );
  return res;
};
