// Convert decimal format (1000 => 1,000, 2023001 => 2,023,001)
export const getDecimalFormating = (
  num: number | undefined | string,
  isDecimal = false,
) => {
  if (!num || isNaN(Number(num))) {
    return undefined;
  }
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: isDecimal ? 2 : 0,
  }).format(Number(num));
};

export const getDecimalFormatingForInput = (
  num: number | undefined | string,
  isDecimal = false,
) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: isDecimal ? 2 : 0,
  }).format(Number(num));
};

// Split the Array with count
// For ex: [1, 2, 3, 4, 5] with 3
// Result: [[1, 2, 3], [4, 5, null]]
export const sliceIntoChunks = (arr: any[] = [], chunkSize: number = 1) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    let chunk = arr.slice(i, i + chunkSize);
    let emptyArr = [];
    if (chunk.length < chunkSize) {
      emptyArr = [...Array(chunkSize - chunk.length)];
      chunk = [...chunk, ...emptyArr];
    }

    res.push(chunk);
  }
  return res;
};

// Get the ordinal
// for ex: 1, 2, 3
// 1st, 2nd, 3rd
export const ordinalSuffixOf = (num: string | number = "") => {
  if (!Number(num)) return num;
  const newNum = Number(num);
  const j = newNum % 10,
    k = newNum % 100;
  if (j === 1 && k !== 11) {
    return newNum + "st";
  }
  if (j === 2 && k !== 11) {
    return newNum + "nd";
  }
  if (j === 3 && k !== 11) {
    return newNum + "rd";
  }
  return newNum + "th";
};

export const groupBy = (array: any, property: string) => {
  return array.reduce((acc: any, obj: any) => {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};
