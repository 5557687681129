import { Fragment, useState } from "react";
import cn from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import Header from "./ModalHeader";
import Body from "./ModalBody";
import Footer from "./ModalFooter";
import { CalculatorModal } from "ui-molecules/Modals";

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  children: any;
  size?:
    | "large"
    | "default"
    | "small"
    | "medium"
    | "extra"
    | "normal"
    | "xlarge";
  calcRef?: any;
}

function Modal<T extends object>({
  isOpen,
  setIsOpen,
  children,
  calcRef,
  size = "default",
}: ModalProps) {
  const [opened, setOpened] = useState(false);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={(e) => {
          if (opened) return;
          setIsOpen(e);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-30">
          <div
            className={cn(
              "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0",
              {
                "h-full": size === "extra",
              },
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  "relative transform overflow-hidden rounded bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full",
                  {
                    "sm:max-w-md": size === "default",
                    "sm:max-w-lg": size === "normal",
                    "sm:max-w-2xl": size === "medium",
                    "sm:max-w-4xl": size === "large",
                    "sm:max-w-[93%] h-[93%]": size === "extra",
                    "sm:max-w-6xl": size === "xlarge",
                    "sm:max-w-xs": size === "small",
                  },
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        <CalculatorModal ref={calcRef} setOpened={setOpened} />
      </Dialog>
    </Transition.Root>
  );
}

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
