const styles: any = {
  page: {
    backgroundColor: "white",
    color: "white",
    display: "flex",
    position: "relative",
    width: 612,
    height: 792,
    flexDirection: "column",
  },
  bg: {
    view: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    img: {
      width: "100%",
      height: "100%",
    },
  },
  header: {
    view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      paddingTop: 36,
      paddingLeft: 54,
      paddingRight: 54,
      height: 75,
      paddingBottom: 20,
    },
    logoImg: {
      height: 18,
      objectFit: "contain",
    },
    title: {
      color: "#01151C",
      fontSize: 10,
    },
  },
  content: {
    height: 645,
    width: "100%",
  },
  footer: {
    view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      width: "100%",
      paddingLeft: 54,
      paddingRight: 54,
      height: 72,
      paddingBottom: 24,
    },
    description: {
      fontSize: 6,
      color: "#667579",
      width: 460,
    },

    pageNumber: {
      view: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-end",
      },
      text: {
        color: "#01151C",
        fontSize: 8,
      },
    },
  },
};

export default styles;
